import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { successNotification, errorNotification, warningNotification } from '../../notification';
import { FormFieldDrop, FormFieldGeneric } from '../../formField';
import FormRow from '../../formRow';
import Popup from '../layout';
import * as relationshipService from '../../../services/relationship';
import { useForms } from '../../../hooks';

const initialForm = {
  Nome: ''
};

const PopUpRelationshipSource = ({
  visible,
  setVisible,
  reloadRelationshipSources,
  isEdit,
  entity
}) => {
  const [form, updateForm, setForm] = useForms(initialForm);

  const createRelationshipSource = async (body) => {
    const response = await relationshipService.createRelationshipSource(body);
    const { data } = response;
    if (response.status === 200) {
      body = {
        ...body,
        Id: data,
        Editavel: true
      };
      successNotification('Tipo de proposta criado com sucesso!');
      await reloadRelationshipSources(body);
    } else if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
    else errorNotification('Houve um erro ao tentar atualizar um tipo de proposta.');
  };

  const editRelationshipSource = async (body) => {
    const response = await relationshipService.updateRelationshipSource(body, body.Id);
    if (response.status === 200) {
      body = {
        Id: body.Id,
        Editavel: true,
        ...body
      };
      successNotification('Tipo de proposta atualizado com sucesso!');
      await reloadRelationshipSources(body);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar atualizar um tipo de proposta.');
    }
  };

  const excludeRelationshipSource = async () => {
    const response = await relationshipService.excludeRelationshipSource(entity.Id);
    if (response.status === 200) {
      successNotification('Tipo de proposta excluído com sucesso!');
      await reloadRelationshipSources(null);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar excluir um tipo de proposta.');
    }
  };

  const submitHandle = () => {
    const body = {
      Id: entity?.Id,
      ...form
    };

    return isEdit ? editRelationshipSource(body) : createRelationshipSource(body);
  };

  useEffect(() => {
    setForm(entity || initialForm);
  }, [entity, visible]);

  return (
    <Popup
      visible={visible}
      setVisible={setVisible}
      title="Origem"
      confirmText="Salvar"
      cancelText="Cancelar"
      haveExclude={isEdit}
      onConfirm={submitHandle}
      onDelete={excludeRelationshipSource}
    >
      <FormRow>
        <FormFieldGeneric
          titleLabel="Nome"
          classNameWrapper="fill-100-field-tooltip"
          name="Nome"
          onChangeValue={updateForm}
          valueInput={form.Nome}
          required
        />
      </FormRow>
    </Popup>
  );
};

PopUpRelationshipSource.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reloadRelationshipSources: PropTypes.func.isRequired
};

export default PopUpRelationshipSource;
