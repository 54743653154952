import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormFieldDrop, FormFieldGeneric } from '../../formField';
import FormRow from '../../formRow';
import Popup from '../layout';
import { create, getAll } from '../../../services/centerCost';
import { useForms } from '../../../hooks';

const emptyForm = {
  Nome: '',
  Descricao: '',

  centerCost: {}
};

const PopUpCenterCust = ({ visible, setVisible, whenCreateNewCenterCost }) => {
  const [form, updateForm, , updateProperty] = useForms(emptyForm);
  const [centerCosts, setCenterCosts] = useState([]);

  const save = async () => {
    await create({
      Nome: form.Nome,
      Descricao: form.Descricao,
      IdPai: form.centerCost.Id
    });

    return whenCreateNewCenterCost();
  };

  useEffect(() => {
    const fetchCenterCosts = async () => {
      const { data } = await getAll();
      setCenterCosts(data);
    };

    fetchCenterCosts();
  }, []);

  return (
    <Popup
      title="Criar Centro de Custo"
      visible={visible}
      setVisible={setVisible}
      cancelText="Cancelar"
      confirmText="Salvar"
      onConfirm={save}
    >
      <FormRow withoutMarginTop>
        <FormFieldDrop
          titleLabel="Subcentro de custo de:"
          defaultValueSelect="Selecione..."
          className="fill-100-field"
          name="centerCost"
          onChangeValue={updateForm}
          value={form.centerCost}
          infosSelect={centerCosts}
          textField="Nome"
          dataItemKey="Id"
          information
          required
          msg="Todo centro de custo deverá possuir um pai"
        />
      </FormRow>

      <FormRow>
        <FormFieldGeneric
          titleLabel="Nome"
          required
          classNameWrapper="fill-100-field-tooltip"
          name="Nome"
          onChangeValue={updateForm}
          valueInput={form.Nome}
        />
      </FormRow>

      <FormRow>
        <FormFieldGeneric
          titleLabel="Descrição"
          classNameWrapper="fill-100-field-tooltip"
          name="Descricao"
          onChangeValue={updateForm}
          valueInput={form.Descricao}
        />
      </FormRow>
    </Popup>
  );
};

PopUpCenterCust.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  whenCreateNewCenterCost: PropTypes.func.isRequired
};

export default PopUpCenterCust;
