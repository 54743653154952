import PropTypes from 'prop-types';
import { useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import LabelField from '../../formField/label';
import GridInline from '../../grid';
import DropDownCell from '../../grid/dropDownCell';
import { TotalCell, TotalTextCell } from '../../grid/totalCell';
import { OrangeButton } from '../../buttonsComponent';
import FormRow from '../../formRow';
import Popup from '../layout';
import NumberCell from '../../grid/numberCell';

const initialClassification = {
  Id: 1,
  PlanoDeContas: null,
  CentroDeCustos: null,
  Valor: 0
};

const PopUpDivideClassification = ({
  addClassification,
  visible,
  setVisible,
  planAccounts,
  centerCosts,
  classification
}) => {
  const [subClassifications, setSubClassifications] = useState([initialClassification]);

  const addDivideClassification = () => {
    const id =
      subClassifications.length > 0 ? subClassifications[subClassifications.length - 1].Id + 1 : 1;
    const newSubClassifications = {
      Id: id,
      PlanoDeContas: null,
      CentroDeCustos: null,
      Valor: 0
    };
    setSubClassifications([...subClassifications, newSubClassifications]);
  };

  const confirmDivideClassification = () => {
    const errors = [];
    const sum = subClassifications.reduce((partialSum, item) => partialSum + item.Valor, 0);
    if (classification.Valor !== sum) {
      errors.push('A soma das divisões deve corresponder ao valor total.');
    }
    if (errors.length > 0) {
      return errors;
    }
    const newSubClassifications = subClassifications.map((item) => ({
      ...item,
      Descricao: classification.Descricao
    }));
    addClassification(newSubClassifications);
    setSubClassifications([]);
    return errors;
  };

  return (
    <Popup
      title="Dividir Classificação"
      width={600}
      visible={visible}
      setVisible={setVisible}
      confirmText="Salvar"
      cancelText="Cancelar"
      onConfirm={confirmDivideClassification}
    >
      <FormRow>
        <LabelField
          titleLabel="Descrição"
          classNameWrapper="fill-100-field"
          valueLabel={classification.Descricao}
        />
      </FormRow>
      <FormRow>
        <GenericField
          titleLabel="Valor"
          enableReais
          classNameWrapper="fill-100-field"
          valueInput={classification.Valor}
          iconEnabled={INTERNAL_ICON.REAIS_ICON}
          required
          disabled
        />
      </FormRow>
      <FormRow withShrink>
        <GridInline data={subClassifications} setData={setSubClassifications} deleteColumn={true}>
          <GridColumn
            field="PlanoDeContas"
            title="Plano de Contas"
            editable={true}
            cell={(props) => DropDownCell(props, planAccounts, 'Id', 'Nome')}
          />
          <GridColumn
            field="CentroDeCustos"
            title="Centro de Custo"
            editable={true}
            cell={(props) => DropDownCell(props, centerCosts, 'Id', 'Nome')}
            footerCell={(props) => TotalTextCell(props)}
          />
          <GridColumn
            field="Valor"
            title="Valor"
            editor="numeric"
            cell={NumberCell}
            editable={true}
            footerCell={(props) => TotalCell(props, subClassifications, 'Valor')}
          />
        </GridInline>
      </FormRow>
      <FormRow justifyEnd space10>
        <OrangeButton onClick={async () => addDivideClassification()}>
          Adicionar Divisão
        </OrangeButton>
      </FormRow>
    </Popup>
  );
};

PopUpDivideClassification.propTypes = {
  addClassification: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpDivideClassification.defaultProps = {};

export default PopUpDivideClassification;
