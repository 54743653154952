/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { useForms } from '../../../hooks';
import { AuthContext } from '../../../auth/AuthContext';
import { OrangeButton, RedButton, WhiteButton } from '../../../components/buttonsComponent';
import { FormFieldDrop, FormFieldGeneric } from '../../../components/formField';
import FormRow from '../../../components/formRow';
import PopUpCertificateForms from '../../../components/popUps/popUpCertificateForms';
import { recoverWalletByJwt } from '../../../services/wallet';
import './style.css';
import {
  errorNotification,
  successNotification,
  defaultErrorNotification,
  warningNotification
} from '../../../components/notification';
import LoadScreen from '../../load';
import { deleteCertificate } from '../../../services/businessWallet';
import * as businessWalletService from '../../../services/businessWallet';

const Certificate = () => {
  const [popUpCertificateFormsVisible, setPopUpCertificateFormsVisible] = useState(false);
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const [importedCertificate, setImportedCertificate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState({
    nome: '',
    file: null
  });
  const initialForm = {
    Id: null,
    PassNotaFiscal: null
  };
  const [form, updateFormValue, setForm, updateFormProperty] = useForms(initialForm);

  const removeCertificateWallet = async () => {
    setLoading(true);
    const response = await deleteCertificate();
    if (response.status !== 200) {
      errorNotification(response.status);
    } else {
      setImportedCertificate(false);
      successNotification('Certificado excluido com sucesso');
    }
    setLoading(false);
  };

  const history = useHistory();
  const redirectToHomeList = () => history.push('/home');

  useEffect(async () => {
    setLoading(true);

    let { Id, PassNotaFiscal } = initialForm;

    if (walletSelected.CarteiraBusiness) {
      Id = walletSelected.CarteiraBusiness.Id;
      PassNotaFiscal = walletSelected.CarteiraBusiness.PassNotaFiscal;
      setImportedCertificate(walletSelected.CarteiraBusiness.ImportouArquivoDeCertificado);
    } else {
      warningNotification('Não existe um carteira Next Business a carteira do Next Finance.');
    }

    setForm({
      Id,
      PassNotaFiscal
    });

    setLoading(false);
  }, []);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    const userBodyForm = {
      Id: form.Id,
      PassNotaFiscal: form.PassNotaFiscal
    };

    const updateWalletPromise = await businessWalletService.update(form.Id, userBodyForm);

    const [updateWalletResponse] = await Promise.all([updateWalletPromise]);

    if (updateWalletResponse.status === 200) {
      const walletData = await recoverWalletByJwt();
      setWalletSelected(walletData);
      successNotification('Carteira atualizada com sucesso!');
    } else {
      defaultErrorNotification();
    }

    setLoading(false);
  };

  if (loading) return <LoadScreen />;
  return (
    <div id="settings-page">
      <h1>Informações Fiscais</h1>
      <form onSubmit={submitForm}>
        <FormRow withShrink>
          <Label>
            <p>
              Para emitir Notas Fiscais Eletrônicas, você precisa utilizar um certificado do tipo
              A1.{' '}
              <a
                className="link-settings"
                href="https://www.paivapiovesan.com/post/certificado-a1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Saiba mais
              </a>
            </p>
          </Label>
        </FormRow>
        <FormRow withShrink>
          <FormFieldGeneric
            titleLabel="Senha do Certificado A1"
            msg="Preencha este campo com sua chave privada de certificação A1."
            valueInput={form.PassNotaFiscal}
            onChangeValue={updateFormValue}
            name="PassNotaFiscal"
            type="password"
            enableInfo
          />
          <div className="side-button">
            {importedCertificate ? (
              <RedButton onClick={() => removeCertificateWallet()}>Remover Certificado</RedButton>
            ) : (
              <OrangeButton onClick={() => setPopUpCertificateFormsVisible(true)}>
                Importar
              </OrangeButton>
            )}
          </div>
        </FormRow>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>
      <PopUpCertificateForms
        visible={popUpCertificateFormsVisible}
        setVisible={setPopUpCertificateFormsVisible}
        addCertificate={(cert) => {
          setCertificate(cert);
        }}
        setImported={setImportedCertificate}
      />
    </div>
  );
};

export default Certificate;
