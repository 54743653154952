import { createContext, useState } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [walletSelected, setWalletSelected] = useState(false);
  const [userJwt, setUserJwt] = useState(null);
  const [walletJwt, setWalletJwt] = useState(null);
  const [redirect, setRedirect] = useState(null);

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      walletSelected,
      setWalletSelected,
      userJwt,
      setUserJwt,
      walletJwt,
      setWalletJwt,
      redirect,
      setRedirect
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
