import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';

const PopUpRecord = ({ addRecord, visible, setVisible, selectedRecord = null }) => {
  const [record, setRecord] = useState(null);
  const [date, setDate] = useState(null);
  const [description, setDescription] = useState(null);

  const confirmAddRecord = () => {
    const item = {
      Descricao: description
    };
    addRecord(item);
    setDescription(null);
  };

  useEffect(async () => {
    if (visible) {
      if (selectedRecord) {
        setRecord(selectedRecord);
        setDate(selectedRecord.DataCriacao);
        setDescription(selectedRecord.Descricao);
      } else {
        setRecord(null);
        setDate(null);
        setDescription(null);
      }
    }
  }, [selectedRecord, visible]);

  return (
    <Popup
      title={selectedRecord ? 'Atualizar Interação' : 'Adicionar Interação'}
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText={selectedRecord ? 'Atualizar' : 'Adicionar'}
      cancelText="Cancelar"
      onConfirm={confirmAddRecord}
    >
      <FormRow>
        <GenericField
          titleLabel="Descrição"
          classNameWrapper="fill-100-field"
          isTextArea
          rows={6}
          valueInput={description}
          onChangeValue={(val) => {
            setDescription(val.value);
          }}
          required
        />
      </FormRow>
    </Popup>
  );
};

PopUpRecord.propTypes = {
  addRecord: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpRecord.defaultProps = {};

export default PopUpRecord;
