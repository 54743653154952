import React, { useEffect } from 'react';
import Load from '../load';
import { useQuery } from '../../hooks';
import { TOKENS, setTokenOnStorage } from '../../auth/Tokens';

export default function LoginScreen() {
  const query = useQuery();

  const convertUrlQueryTokens = () => {
    const tokenUser = query.get('t-usuario');
    const refreshTokenUser = query.get('rt-usuario');

    const tokenWallet = query.get('t-carteira');
    const refreshTokenWallet = query.get('rt-carteira');

    const redirect = query.get('redirect');

    if (tokenUser) setTokenOnStorage(TOKENS.USER.TOKEN, tokenUser);
    if (refreshTokenUser) setTokenOnStorage(TOKENS.USER.REFRESH_TOKEN, refreshTokenUser);

    if (tokenWallet) setTokenOnStorage(TOKENS.WALLET.TOKEN, tokenWallet);
    if (refreshTokenWallet) setTokenOnStorage(TOKENS.WALLET.REFRESH_TOKEN, refreshTokenWallet);

    if (redirect) setTokenOnStorage(TOKENS.REDIRECT, redirect);
    else localStorage.removeItem(TOKENS.REDIRECT);
  };

  useEffect(() => {
    convertUrlQueryTokens();
  }, []);

  return <Load />;
}
