import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getReceivingLocalAndReceivable = async () => {
    const { data } = await axiosWalletRequest('Conta/NEXTBusinessListarContasCorrentesEContasCaixasEContaAReceber', METHOD.GET);
    return data;
};
export const getReceivingLocal = async () => {
    const { data } = await axiosWalletRequest('Conta/NEXTBusinessListarContasCorrentesEContasCaixas', METHOD.GET);
    return data;
};

export const getPaymentLocal = async () => {
    const { data } = await axiosWalletRequest('Conta/NEXTBusinessListarContasCorrentesECaixasECartoesCredito', METHOD.GET);
    return data;
};

export const getReceivablePayableAccount = async () => {
    const { data } = await axiosWalletRequest('Conta/NEXTBusinessListarContasAPagarAReceberEmCobranca', METHOD.GET);
    return data;
};

export const getDefaultReceivable = async () => {
    const { data } = await axiosWalletRequest('Conta/NEXTBusinessListarContasAReceber', METHOD.GET);
    return data;
};

export const getById = (id) => axiosWalletRequest(`Conta/${id}`, METHOD.GET);