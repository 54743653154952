import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDate from '../../../components/formField/date';
import FormFieldDrop from '../../../components/formField/drop';
import LabelField from '../../../components/formField/label';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as personService from '../../../services/person';
import * as relationshipService from '../../../services/relationship';
import LoadScreen from '../../load';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../../components/notification';
import PopUpError from '../../../components/popUps/popUpError';
import PopupRelationshipType from '../../../components/popUps/popUpRelationshipType';
import PopupRelationshipStatus from '../../../components/popUps/popUpRelationshipStatus';
import PopupRelationshipSource from '../../../components/popUps/popUpRelationshipSource';
import PopUpConfirm from '../../../components/popUps/popUpConfirm';
import { DateNow } from '../../../utils/date';
import Client from '../../../components/client';
import Record from '../../../components/record';
import Contracts from '../../contracts';
import PopUpRecord from '../../../components/popUps/popUpRecord';
import { AuthContext } from '../../../auth/AuthContext';
import './styles.css';

const initialForm = {
  Data: DateNow(),
  Numero: '',
  Contato: null,
  Descricao: null
};

const initialRelationshipType = {
  Nome: '',
  Editavel: false
};

const initialRelationshipStatus = {
  Nome: '',
  Editavel: false
};

const initialRelationshipSource = {
  Nome: '',
  Editavel: false
};

const RelationshipRegistration = () => {
  let { id } = useParams();
  const [priority, setPriority] = useState(null);
  const [priorities, setPriorities] = useState([]);
  const [relationshipType, setRelationshipType] = useState(initialRelationshipType);
  const [relationshipTypes, setRelationshipTypes] = useState([]);
  const [relationshipStatus, setRelationshipStatus] = useState(initialRelationshipStatus);
  const [relationshipStatuses, setRelationshipStatuses] = useState([]);
  const [relationshipSource, setRelationshipSource] = useState(initialRelationshipSource);
  const [relationshipSources, setRelationshipSources] = useState([]);
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [cpfCnpj, setCpfCpnj] = useState('');
  const [address, setAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [client, setClient] = useState(null);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [contact, setContact] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [loadingRelationshipTypes, setLoadingRelationshipTypes] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingRecord, setLoadingRecord] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { userJwt, walletJwt } = useContext(AuthContext);

  const [popUpsVisibilities, setPopUpsVisibilities] = useState({
    addRelationshipType: false,
    editRelationshipType: false,
    addRelationshipStatus: false,
    editRelationshipStatus: false,
    addRelationshipSource: false,
    editRelationshipSource: false,
    addInteration: false,
    deleteInteration: false
  });

  const setPopUpVisibility = (propName, value) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      [propName]: value
    });
  };
  const updateClientDetails = async (client, address, contact) => {
    if (contact) setForm({ ...form, Email: contact.Email });
  };

  const setClientAddress = async (client, idAddress, contact) => {
    if (client.TipoPessoa === 0) {
      setCpfCpnj(client.CPF);
      setAddress(client.Endereco);
      setContact({ Nome: contact });
      setContacts(null);
    } else {
      let addresses = [];
      if (client.EnderecoPrincipal != null) addresses.push(client.EnderecoPrincipal);
      addresses = addresses.concat(client.OutrosEnderecos);
      addresses = addresses.map((item) => ({
        ...item,
        EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
          .filter(Boolean)
          .join(', ')
      }));
      let newAddress = addresses.filter((x) => x.Id === idAddress);
      newAddress = newAddress.length > 0 ? newAddress[0] : null;
      if (newAddress != null) {
        setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
        setAddress(newAddress);
      } else if (addresses.length === 1) {
        setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
        setAddress(addresses[0]);
      }
      setAddresses(addresses);
      const contacts = [];
      if (client.Contatos?.length > 0) {
        client.Contatos.forEach((item) => {
          contacts.push(item);
        });
      }
      if (addresses?.length > 0) {
        addresses.forEach((item1) => {
          if (item1.Contatos?.length > 0) {
            item1.Contatos.forEach((item2) => {
              contacts.push(item2);
            });
          }
        });
      }
      contacts.sort();
      if (!contacts) {
        setContact({ Nome: contact });
        setContacts(null);
      } else if (contacts.length > 0) {
        const contato = contacts.find((x) => x.Id === contact);
        setContact(contato);
        contacts.sort((a, b) => {
          if (a.Nome < b.Nome) {
            return -1;
          }
          if (a.Nome > b.Nome) {
            return 1;
          }
          return 0;
        });
        setContacts(contacts);
      }
    }
  };

  const loadPriorities = async () => {
    setLoadingRelationshipTypes(true);
    const { data } = await relationshipService.getPriorities();
    setPriorities(data);
    if (data.length > 0) setPriority(data[0]);
    setLoadingRelationshipTypes(false);
    return data;
  };

  const loadRelationshipTypes = async (relationshipType) => {
    setLoadingRelationshipTypes(true);
    const { data } = await relationshipService.getRelationshipTypes();
    setRelationshipTypes(data);
    setRelationshipType(relationshipType);
    setLoadingRelationshipTypes(false);
    return data;
  };

  const loadRelationshipStatuses = async (relationshipStatus) => {
    setLoadingRelationshipTypes(true);
    const { data } = await relationshipService.getRelationshipStatuses();
    setRelationshipStatuses(data);
    setRelationshipStatus(relationshipStatus);
    setLoadingRelationshipTypes(false);
    return data;
  };

  const loadRelationshipSources = async (relationshipSource) => {
    setLoadingRelationshipTypes(true);
    const { data } = await relationshipService.getRelationshipSources();
    setRelationshipSources(data);
    setRelationshipSource(relationshipSource);
    setLoadingRelationshipTypes(false);
    return data;
  };

  const loadInterations = async (id) => {
    const response = await relationshipService.getById(id);
    const relationship = response.data;
    if (relationship.RelacionamentoInteracao.length > 0) {
      const records = relationship.RelacionamentoInteracao.map((item) => ({
        ...item,
        DataCriacao: new Date(item.DataCriacao),
        DataModificacao: new Date(item.DataModificacao)
      }));
      setRecords(records);
    } else {
      setRecords([]);
    }
  };

  useEffect(async () => {
    setLoadingRelationshipTypes(true);
    setLoadingClient(true);
    if (id && id !== '0') {
      let response = await relationshipService.getById(id);
      const relationship = response.data;
      // ############## Load Relationship Types ##############
      setForm({
        Data: new Date(relationship.Data),
        Numero: relationship.Numero,
        Descricao: relationship.Descricao,
        UsuarioNome: relationship.UsuarioNome
      });
      if (relationship.RelacionamentoInteracao.length > 0) {
        const records = relationship.RelacionamentoInteracao.map((item) => {
          const dataCriacaoLocal = new Date(`${item.DataCriacao}Z`);
          const dataModificacaoLocal = new Date(`${item.DataModificacao}Z`);
          return {
            ...item,
            DataCriacao: dataCriacaoLocal,
            DataModificacao: dataModificacaoLocal
          };
        });
        setRecords(records);
      }
      const priorities = await loadPriorities();
      const itemPriority = priorities.find((element) => element.Id === relationship.PrioridadeId);
      setPriority(itemPriority);

      const relationshipTypes = await loadRelationshipTypes();
      let itemRelationshipType = relationshipTypes.find(
        (element) => element.Id === relationship.TipoRelacionamentoId
      );
      itemRelationshipType = {
        ...itemRelationshipType,
        Editavel: true
      };
      setRelationshipType(itemRelationshipType);

      const relationshipStatuses = await loadRelationshipStatuses();
      let itemRelationshipStatus = relationshipStatuses.find(
        (element) => element.Id === relationship.SituacaoRelacionamentoId
      );
      itemRelationshipStatus = {
        ...itemRelationshipStatus,
        Editavel: true
      };
      setRelationshipStatus(itemRelationshipStatus);

      const relationshipSources = await loadRelationshipSources();
      let itemRelationshipSource = relationshipSources.find(
        (element) => element.Id === relationship.TipoRelacionamentoId
      );
      itemRelationshipSource = {
        ...itemRelationshipSource,
        Editavel: true
      };
      setRelationshipSource(itemRelationshipSource);
      setLoadingRelationshipTypes(false);

      // ############## Load Client ##############
      response = await personService.getById(relationship.PessoaId);
      const client = response.data;
      setClient(client);
      await setClientAddress(client, relationship.EnderecoId, relationship.Contato);
      setLoadingClient(false);
    } else {
      // ############## Load Relationship Types ##############
      const priorities = await loadPriorities();
      const relationshipTypes = await loadRelationshipTypes();
      const relationshipStatuses = await loadRelationshipStatuses();
      const relationshipSources = await loadRelationshipSources();
      const relationshipNumber = await relationshipService.getRelationshipNumber();
      setForm({
        ...form,
        Numero: relationshipNumber
      });
      setLoadingRelationshipTypes(false);

      // ############## Load Client ##############
      setContact(null);
      setLoadingClient(false);
    }
  }, []);

  const redirectToRelationships = (id) => {
    if (id) history.push(`/relationships/${id}`);
    else history.push(`/relationships`);
  };

  const validate = async () => {
    if (loading || loadingType || loadingClient || loadingRelationshipTypes || loadingRecord) {
      warningNotification('Aguarde o carregamento dos dados.');
      return false;
    }
    return true;
  };

  const submitFormValidated = async (button) => {
    setLoading(true);

    const bodyForm = {
      ...form,
      PessoaId: client.Id,
      PrioridadeId: priority.Id,
      TipoRelacionamentoId: relationshipType?.Id,
      SituacaoRelacionamentoId: relationshipStatus?.Id,
      OrigemRelacionamentoId: relationshipSource?.Id,
      EnderecoId: address?.Id,
      Descricao: form.Descricao,
      Contato: contact?.Id ? contact?.Id : contact?.Nome
    };
    let response = null;
    if (id && id !== '0') {
      response = await relationshipService.update(id, bodyForm);
    } else {
      response = await relationshipService.create(bodyForm);
      const { data } = response;
      id = data;
    }
    if (response.status === 200) {
      successNotification('Registro criado/atualizado com sucesso!');
      if (button !== 'interation') redirectToRelationships(id);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
      else errorNotification(data);
    }
    setLoading(false);
    return id;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      submitFormValidated();
    }
  };

  const onItemDeleteRecord = async (record) => {
    setPopUpVisibility('deleteInteration', true);
    setSelectedRecord(record);
  };

  const onItemEditRecord = (record) => {
    setPopUpVisibility('addInteration', true);
    setSelectedRecord(record);
  };

  const deleteInteration = async () => {
    setLoadingRecord(true);
    const response = await relationshipService.excludeRelationshipInteration(selectedRecord.Id);
    if (response.status === 200) {
      await loadInterations(id);
      successNotification('Interação excluída com sucesso!');
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar excluir uma interação.');
    }
    setLoadingRecord(false);
  };

  const addInteration = async (record) => {
    setLoadingRecord(true);
    let newId = id;
    if (!newId || newId === '0')
      if (await validate()) {
        newId = await submitFormValidated('interation');
      }

    const bodyForm = {
      Descricao: record.Descricao,
      RelacionamentoId: newId
    };
    let response = null;
    if (selectedRecord) {
      response = await relationshipService.updateRelationshipInteration(
        bodyForm,
        selectedRecord.Id
      );
      if (response.status === 200) {
        setSelectedRecord(null);
        await loadInterations(newId);
        successNotification('Interação atualizada com sucesso!');
      } else {
        const { data } = response;
        if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
        else errorNotification(data);
      }
    } else {
      response = await relationshipService.createRelationshipInteration(bodyForm);
      if (response.status === 200) {
        history.push(`/relationships/relationshipRegistration/${newId}`);
        await loadInterations(newId);
        successNotification('Interação criada com sucesso!');
      } else {
        const { data } = response;
        if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
        else errorNotification(data);
      }
    }
    setLoadingRecord(false);
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      {id && id !== '0' ? <h1>Atualizar Registro</h1> : <h1>Novo Registro</h1>}
      <form className="form-general-relationship" onSubmit={submitForm}>
        {loadingType ? (
          <LoadScreen />
        ) : (
          <>
            <ToggleInvoice title="Tipo de Registro" startExpanded>
              {loadingRelationshipTypes ? (
                <LoadScreen />
              ) : (
                <>
                  <FormRow withShrink>
                    <FormFieldDrop
                      titleLabel="Tipo de Registro"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const relationshipType = val.value;
                        if (relationshipType == null) setRelationshipType(initialForm);
                        else {
                          relationshipType.Editavel = true;
                          setRelationshipType(relationshipType);
                        }
                      }}
                      value={relationshipType}
                      className="fill-50-field"
                      infosSelect={relationshipTypes}
                      dataItemKey="Id"
                      textField="Nome"
                      enablePencil={relationshipType?.Editavel}
                      enableIcon
                      onClickIcon={() => setPopUpVisibility('addRelationshipType', true)}
                      onClickPencil={() => setPopUpVisibility('editRelationshipType', true)}
                      required
                    />
                    <FormFieldDate
                      titleLabel="Data de Criação"
                      value={form.Data}
                      valueOnChange={(val) => updateFormValue('Data', val)}
                      className="fill-25-field"
                      required
                      disabled
                    />

                    <GenericField
                      titleLabel="Número"
                      enableInfo
                      onChangeValue={updateProperty}
                      msg="Número sequencial das propostas"
                      valueInput={form.Numero}
                      classNameWrapper="fill-25-field"
                      required
                      name="Numero"
                      typeNumber
                      disabled
                    />
                  </FormRow>
                  <FormRow withShrink>
                    <FormFieldDrop
                      titleLabel="Origem"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const relationshipSource = val.value;
                        if (relationshipSource == null) setRelationshipSource(initialForm);
                        else {
                          relationshipSource.Editavel = true;
                          setRelationshipSource(relationshipSource);
                        }
                      }}
                      value={relationshipSource}
                      className="fill-25-field"
                      infosSelect={relationshipSources}
                      dataItemKey="Id"
                      textField="Nome"
                      enablePencil={relationshipSource?.Editavel}
                      enableIcon
                      onClickIcon={() => setPopUpVisibility('addRelationshipSource', true)}
                      onClickPencil={() => setPopUpVisibility('editRelationshipSource', true)}
                      required
                    />
                    <FormFieldDrop
                      titleLabel="Situação"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const relationshipStatus = val.value;
                        if (relationshipStatus == null) setRelationshipStatus(initialForm);
                        else {
                          relationshipStatus.Editavel = true;
                          setRelationshipStatus(relationshipStatus);
                        }
                      }}
                      value={relationshipStatus}
                      className="fill-25-field"
                      infosSelect={relationshipStatuses}
                      dataItemKey="Id"
                      textField="Nome"
                      enablePencil={relationshipStatus?.Editavel}
                      enableIcon
                      onClickIcon={() => setPopUpVisibility('addRelationshipStatus', true)}
                      onClickPencil={() => setPopUpVisibility('editRelationshipStatus', true)}
                      required
                    />
                    <FormFieldDrop
                      titleLabel="Prioridade"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        setPriority(val.value);
                      }}
                      value={priority}
                      className="fill-25-field"
                      infosSelect={priorities}
                      dataItemKey="Id"
                      textField="Nome"
                      required
                    />
                    <LabelField
                      titleLabel="Criado Por"
                      classNameWrapper="fill-25-field"
                      valueLabel={form.UsuarioNome}
                    />
                  </FormRow>
                </>
              )}
            </ToggleInvoice>
          </>
        )}
        <ToggleInvoice title="Cliente" startExpanded>
          {loadingClient ? (
            <LoadScreen />
          ) : (
            <>
              <Client
                client={client}
                setClient={setClient}
                cpfCnpj={cpfCnpj}
                setCpfCpnj={setCpfCpnj}
                address={address}
                setAddress={setAddress}
                addresses={addresses}
                setAddresses={setAddresses}
                contact={contact}
                setContact={setContact}
                contacts={contacts}
                setContacts={setContacts}
                afterClientSelection={updateClientDetails}
              />
              {address?.RetemISS && (
                <FormRow>
                  <h3 style={{ color: 'red', fontWeight: 'bold' }}>Cliente retém ISS</h3>
                </FormRow>
              )}
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Histórico de Relacionamento" startExpanded>
          <Contracts
            head={false}
            byClient={true}
            clientId={client?.Id}
            addressId={address?.Id}
            haveCheckbox={false}
            maxHeight={300}
            havePreview={true}
            haveOnClick={false}
            seekControl={false}
          />
          <FormRow space10>
            <OrangeButton
              onClick={() => {
                if (userJwt && walletJwt) {
                  const clientId = client?.Id;
                  const addressId = address?.Id;
                  let redirect = '/invoices?byClient=true';
                  if (clientId) redirect = `${redirect}&clientId=${client?.Id}`;
                  if (addressId) redirect = `${redirect}&addressId=${address?.Id}`;
                  redirect = encodeURIComponent(redirect);
                  const url = `${window.location.origin}/login?t-usuario=${userJwt}&t-carteira=${walletJwt}&redirect=${redirect}`;
                  window.open(url, '_blank');
                } else {
                  errorNotification('Usuário não autenticado!');
                }
              }}
            >
              Consultar Histórico de Compras
            </OrangeButton>
            <OrangeButton
              onClick={() => {
                if (userJwt && walletJwt) {
                  const clientId = client?.Id;
                  const addressId = address?.Id;
                  let redirect = '/attendances?byClient=true';
                  if (clientId) redirect = `${redirect}&clientId=${client?.Id}`;
                  if (addressId) redirect = `${redirect}&addressId=${address?.Id}`;
                  redirect = encodeURIComponent(redirect);
                  const url = `${window.location.origin}/login?t-usuario=${userJwt}&t-carteira=${walletJwt}&redirect=${redirect}`;
                  window.open(url, '_blank');
                } else {
                  errorNotification('Usuário não autenticado!');
                }
              }}
            >
              Consultar Histórico de Propostas
            </OrangeButton>
            <OrangeButton
              onClick={() => {
                if (userJwt && walletJwt) {
                  const clientId = client?.Id;
                  const addressId = address?.Id;
                  let redirect = '/relationships?byClient=true';
                  if (clientId) redirect = `${redirect}&clientId=${client?.Id}`;
                  if (addressId) redirect = `${redirect}&addressId=${address?.Id}`;
                  redirect = encodeURIComponent(redirect);
                  const url = `${window.location.origin}/login?t-usuario=${userJwt}&t-carteira=${walletJwt}&redirect=${redirect}`;
                  window.open(url, '_blank');
                } else {
                  errorNotification('Usuário não autenticado!');
                }
              }}
            >
              Consultar Histórico de Atendimento
            </OrangeButton>
          </FormRow>
        </ToggleInvoice>
        <ToggleInvoice title="Atendimento" startExpanded>
          <FormRow>
            <GenericField
              titleLabel="Descrição do Registro"
              classNameWrapper="fill-100-field"
              isTextArea
              rows={3}
              valueInput={form.Descricao}
              onChangeValue={(val) => {
                updateFormValue('Descricao', val.value);
              }}
              required
            />
          </FormRow>
          {loadingRecord ? (
            <LoadScreen />
          ) : (
            <FormRow>
              <Record
                records={records}
                setRecords={setRecords}
                editColumn={true}
                onItemEdit={onItemEditRecord}
                deleteColumn={true}
                onItemDelete={onItemDeleteRecord}
              />
            </FormRow>
          )}
          <FormRow justifyEnd space10>
            <OrangeButton onClick={() => setPopUpVisibility('addInteration', true)}>
              Adicionar Interação
            </OrangeButton>
          </FormRow>
        </ToggleInvoice>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit" name="salvar">
            Salvar
          </OrangeButton>
          <WhiteButton onClick={() => redirectToRelationships(id)}>Cancelar</WhiteButton>
        </FormRow>
      </form>
      <PopupRelationshipType
        visible={popUpsVisibilities.addRelationshipType}
        setVisible={(val) => setPopUpVisibility('addRelationshipType', val)}
        reloadRelationshipTypes={loadRelationshipTypes}
      />
      <PopupRelationshipType
        visible={popUpsVisibilities.editRelationshipType}
        setVisible={(val) => setPopUpVisibility('editRelationshipType', val)}
        reloadRelationshipTypes={loadRelationshipTypes}
        entity={relationshipType}
        isEdit
      />
      <PopupRelationshipStatus
        visible={popUpsVisibilities.addRelationshipStatus}
        setVisible={(val) => setPopUpVisibility('addRelationshipStatus', val)}
        reloadRelationshipStatuses={loadRelationshipStatuses}
      />
      <PopupRelationshipStatus
        visible={popUpsVisibilities.editRelationshipStatus}
        setVisible={(val) => setPopUpVisibility('editRelationshipStatus', val)}
        reloadRelationshipStatuses={loadRelationshipStatuses}
        entity={relationshipStatus}
        isEdit
      />
      <PopupRelationshipSource
        visible={popUpsVisibilities.addRelationshipSource}
        setVisible={(val) => setPopUpVisibility('addRelationshipSource', val)}
        reloadRelationshipSources={loadRelationshipSources}
      />
      <PopupRelationshipSource
        visible={popUpsVisibilities.editRelationshipSource}
        setVisible={(val) => setPopUpVisibility('editRelationshipSource', val)}
        reloadRelationshipSources={loadRelationshipSources}
        entity={relationshipSource}
        isEdit
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpRecord
        visible={popUpsVisibilities.addInteration}
        setVisible={(val) => setPopUpVisibility('addInteration', val)}
        addRecord={addInteration}
        selectedRecord={selectedRecord}
      />
      <PopUpConfirm
        onConfirm={deleteInteration}
        visible={popUpsVisibilities.deleteInteration}
        setVisible={(val) => setPopUpVisibility('deleteInteration', val)}
        title="Confirmação"
        message="Você tem certeza que deseja excluir a interação selecionada?"
      />
    </>
  );
};

export default RelationshipRegistration;
