import React, { useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import FormRow from '../formRow';
import GridInline from '../grid';
import PopUpConfirm from '../popUps/popUpConfirm';
import './styles.css';

const Record = ({ records, setRecords, deleteColumn, onItemDelete, editColumn, onItemEdit }) => {
  const [loading, setLoading] = useState(false);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const confirmDelete = (record) => {
    if (selectedRecord) onItemDelete(selectedRecord);
  };

  const canDelete = (record) => {
    // setPopUpConfirmDelete(true);
    setSelectedRecord(record);
    return true;
  };

  return (
    <div>
      <>
        <FormRow withShrink>
          <GridInline
            data={records}
            setData={setRecords}
            deleteColumn={deleteColumn}
            editColumn={editColumn}
            onItemEdit={onItemEdit}
            canDelete={canDelete}
            onItemDelete={onItemDelete}
            confirmDelete={true}
          >
            <GridColumn
              field="DataCriacao"
              title="Data de Criação"
              editable={false}
              format="{0:dd/MM/yyyy HH:mm:ss}"
              width={170}
            />
            <GridColumn
              field="DataModificacao"
              title="Data de Modificação"
              editable={false}
              format="{0:dd/MM/yyyy HH:mm:ss}"
              width={170}
            />
            <GridColumn field="Descricao" title="Descrição" editable={false} />
            <GridColumn field="UsuarioNome" title="Usuário" editable={false} />
          </GridInline>
        </FormRow>
        <PopUpConfirm
          onConfirm={confirmDelete}
          visible={popUpConfirmDelete}
          setVisible={setPopUpConfirmDelete}
          title="Confirmação"
          message="Você tem certeza que deseja excluir a interação selecionada?"
        />
      </>
    </div>
  );
};

export default Record;
