import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HelpIcon from '../../assets/icone-ajuda.svg';
import CadastroIcon from '../../assets/icone-cadastros.svg';
import ConfiguracoesIcon from '../../assets/icone-configuracoes.svg';
import BeginIcon from '../../assets/icone-inicio.svg';
import NotasFiscais from '../../assets/icone-notas-fiscais.svg';
import LogoNB from '../../assets/Logo-NEXT-Business-negativo.svg';
import { AuthContext } from '../../auth/AuthContext';
import { errorNotification } from '../notification';
import './styles.css';

const SideBar = ({ isOpen }) => {
  const history = useHistory();
  const { walletSelected, userJwt, walletJwt } = useContext(AuthContext);

  const handleSelect = (e) => {
    history.push(e.target.props.url);
  };

  let simplesNacional = false;
  if (walletSelected?.CarteiraBusiness?.SimplesNacional || walletSelected?.CarteiraBusiness?.MEI)
    simplesNacional = true;

  const hasSectionPermission = (value) => {
    let permissions = [];
    if (walletSelected?.ListaPermissoes) permissions = walletSelected.ListaPermissoes;
    if (permissions.includes('1')) return true;
    for (let i = 0; i < value?.length; i++) {
      if (permissions.includes(value[i])) return true;
    }
    return false;
  };

  const hasPermission = (value) => {
    let permissions = [];
    if (walletSelected?.ListaPermissoes) permissions = walletSelected.ListaPermissoes;
    if (permissions.includes('1')) return true;
    return permissions.includes(value);
  };

  const logoClick = () => {
    if (userJwt && walletJwt) {
      const url = `${window.location.origin}/login?t-usuario=${userJwt}&t-carteira=${walletJwt}`;
      window.open(url, '_blank');
    } else {
      errorNotification('Usuário não autenticado!');
    }
  };

  return (
    <div className="side-bar-wrapper">
      <div className={`side-bar-menu ${!isOpen ? 'hidden' : ''}`} />
      <aside className={`side-bar-menu ${!isOpen ? 'hidden' : ''}`}>
        <img src={LogoNB} alt="Next Business" onClick={logoClick} style={{ cursor: 'pointer' }} />
        <PanelBar onSelect={handleSelect} expandMode="single">
          {hasPermission('41') && <PanelBarItem title="Início" url="/" imageUrl={BeginIcon} />}
          <PanelBarItem title="Central de Relac. (βeta)" imageUrl={NotasFiscais}>
            <PanelBarItem title="Registros" url="/relationships" />
          </PanelBarItem>
          {hasSectionPermission(['28', '29', '30']) && (
            <PanelBarItem title="Receitas & Operações" imageUrl={NotasFiscais}>
              {hasPermission('28') && <PanelBarItem title="Propostas" url="/attendances" />}
              {hasPermission('29') && <PanelBarItem title="Contratos" url="/contracts" />}
              {hasPermission('30') && <PanelBarItem title="Documentos Emitidos" url="/invoices" />}
            </PanelBarItem>
          )}
          {hasSectionPermission(['31', '32']) && (
            <PanelBarItem title="Compras & Despesas" imageUrl={NotasFiscais}>
              {hasPermission('31') && <PanelBarItem title="Documentos Recebidos" url="/expenses" />}
              {hasPermission('32') && (
                <PanelBarItem title="Recursos Humanos" url="/humanResources" />
              )}
            </PanelBarItem>
          )}
          {hasSectionPermission(['33', '34', '35', '42']) && (
            <PanelBarItem title="Cadastro" imageUrl={CadastroIcon}>
              {hasPermission('33') && <PanelBarItem title="Serviços" url="/services" />}
              {hasPermission('42') && <PanelBarItem title="Produtos" url="/products" />}
              {hasPermission('34') && !simplesNacional && (
                <PanelBarItem title="Classes de Impostos" url="/taxClasses" />
              )}
              {hasPermission('35') && (
                <PanelBarItem title="Padrões de Propostas" url="/proposals" />
              )}
            </PanelBarItem>
          )}
          {hasSectionPermission(['43']) && (
            <PanelBarItem title="Análises e Relatórios" imageUrl={CadastroIcon}>
              {hasPermission('43') && <PanelBarItem title="Compras" url="/shopping" />}
            </PanelBarItem>
          )}
          {hasSectionPermission(['36', '37', '38', '39', '40']) && (
            <PanelBarItem title="Configurações" imageUrl={ConfiguracoesIcon}>
              {hasPermission('36') && <PanelBarItem title="Empresa" url="/settings/company" />}
              {hasPermission('37') && <PanelBarItem title="Documentos" url="/settings/documents" />}
              {hasPermission('38') && (
                <PanelBarItem title="Certificado Digital" url="/settings/certificate" />
              )}
              {hasPermission('39') && (
                <PanelBarItem title="Classificação Padrão" url="/settings/classification" />
              )}
              {hasPermission('40') && (
                <PanelBarItem title="Vendas por Cartão" url="/settings/card" />
              )}
              {/* <PanelBarItem title="Test" url="/settings/test" /> */}
            </PanelBarItem>
          )}
          <PanelBarItem title="Ajuda" url="/help" imageUrl={HelpIcon} />
        </PanelBar>
      </aside>
    </div>
  );
};

SideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default SideBar;
