import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import { CalendarWithButtons, DateFilter } from '../../components/dateComponents';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../load';
import * as relationshipService from '../../services/relationship';
import { successNotification, warningNotification } from '../../components/notification';
import { FirstDayOfMonth, LastDayOfMonth, DateNow } from '../../utils/date';
import PopUpViewer from '../../components/popUps/popUpViewer';
import PopUpSettings from '../../components/popUps/popUpSettings';
import { useQuery } from '../../hooks';

const Relationships = () => {
  const [reload, setReload] = useState(0);
  const { id } = useParams();
  const query = useQuery();
  const byClient = query.get('byClient');
  const clientId = query.get('clientId');
  const addressId = query.get('addressId');
  const editId = window.localStorage.getItem('EDIT_ID');
  const history = useHistory();
  const [relationships, setRelationships] = useState([]);
  const [relationshipsSelectedsIds, setRelationshipsSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpConfirmSale, setPopUpConfirmSale] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [popUpConfirmContract, setPopUpConfirmContract] = useState(false);
  const [popUpConfirmPDF, setPopUpConfirmPDF] = useState(false);
  const [popUpViewer, setPopUpViewer] = useState(false);
  const [dataViewer, setDataViewer] = useState(null);
  const [popUpSettings, setPopUpSettings] = useState(false);
  const filterDateLocal1 = window.localStorage.getItem('RELATIONSHIP_FILTER_DATE1');
  const filterDateLocal2 = window.localStorage.getItem('RELATIONSHIP_FILTER_DATE2');
  const dateNow = DateNow();
  let filterDate = { date1: FirstDayOfMonth(dateNow), date2: LastDayOfMonth(dateNow) };
  if (filterDateLocal1 && id)
    filterDate = { date1: new Date(filterDateLocal1), date2: new Date(filterDateLocal2) };
  const [date, setDate] = useState(filterDate);
  const filterTypeLocal = window.localStorage.getItem('RELATIONSHIP_FILTER_TYPE');
  const [filterType, setFilterType] = useState(filterTypeLocal || 'month');

  window.localStorage.setItem('RELATIONSHIP_FILTER_DATE1', date.date1);
  window.localStorage.setItem('RELATIONSHIP_FILTER_DATE2', date.date2);
  window.localStorage.setItem('RELATIONSHIP_FILTER_TYPE', filterType);

  const columns = [
    {
      field: 'TipoRelacionamentoNome',
      title: 'Tipo',
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'SituacaoRelacionamentoNome',
      title: 'Situação',
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'Data',
      title: 'Data',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      width: 110,
      columnMenu: true
    },
    {
      field: 'Numero',
      title: 'Núm.',
      width: 60,
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 300,
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'Contato',
      title: 'Contato',
      columnMenu: true
    },
    {
      field: 'Celular',
      title: 'Celular',
      columnMenu: true,
      cell: (props) => {
        let td = null;
        if (props.dataItem.Celular) {
          const celularTratado = props.dataItem.Celular.replace(/[^0-9]/g, '');
          td = (
            <td>
              <a
                className="link"
                href={`https://api.whatsapp.com/send?phone=${celularTratado}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.dataItem.Celular}
              </a>
            </td>
          );
        } else {
          td = <td>{props.dataItem.name}</td>;
        }
        return td;
      }
    },
    {
      field: 'Descricao',
      title: 'Descrição',
      columnMenu: true
    },
    {
      field: 'OrigemRelacionamentoNome',
      title: 'Origem',
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'PrioridadeNome',
      title: 'Prioridade',
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'UsuarioNome',
      title: 'Atendido Por',
      columnMenu: true,
      columnMenuCheckbox: true
    }
  ];

  const seekScroll = async (data) => {
    if (editId != null) {
      const index = data.findIndex((item) => item.Id === editId);
      setRowIndex(index);
    } else {
      setRowIndex(data.length - 1);
    }
  };

  const fetchRelationships = async () => {
    const dateInterval = {
      DataInicio: date.date1,
      DataFim: date.date2
    };
    const clientAddress = {
      IdPessoa: clientId,
      IdEndereco: addressId
    };
    let response = null;
    if (byClient) response = await relationshipService.getByClientId(clientAddress);
    else response = await relationshipService.getByDate(dateInterval);
    let { data } = response;
    data = data.map((item) => {
      const data = new Date(item.Data);
      const dataInicio = new Date(item.DataInicio);
      const dataFim = new Date(item.DataFim);
      data.setHours(0, 0, 0, 0);
      dataInicio.setHours(0, 0, 0, 0);
      dataFim.setHours(0, 0, 0, 0);
      return {
        ...item,
        Data: data,
        DataInicio: dataInicio,
        DataFim: dataFim
      };
    });
    setRelationships(data);
    let flag = true;
    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].ParcelaPeriodo !== data[i + 1].ParcelaPeriodo) {
        flag = false;
        break;
      }
    }
    seekScroll(data);
  };

  const excludeRelationships = async () => {
    setLoading(true);
    const excludeRelationshipsRequests = relationshipsSelectedsIds.map((relationshipSelected) =>
      relationshipService.exclude(relationshipSelected)
    );

    const excludeRelationshipsResponses = await Promise.all(excludeRelationshipsRequests);

    const isAllRequestsRight = excludeRelationshipsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setRelationshipsSelectedsIds([]);
    setLoading(false);
    setPopUpConfirmDelete(false);
    if (isAllRequestsRight) successNotification('Registro(s) excluído(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais registros:'];
      excludeRelationshipsResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Registro');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchRelationships();
  };

  useEffect(async () => {
    setLoading(true);
    await fetchRelationships();
    setLoading(false);
  }, [date, reload]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="relationship-list">
        <h1>Registro</h1>
        <div className="buttons-relationship-list">
          {!byClient && filterType === 'month' && (
            <CalendarWithButtons
              date={date.date1}
              setDate={(val) => {
                const date1 = FirstDayOfMonth(val);
                const date2 = LastDayOfMonth(val);
                setDate({ date1, date2 });
                window.localStorage.setItem('RELATIONSHIP_FILTER_DATE1', date1);
                window.localStorage.setItem('RELATIONSHIP_FILTER_DATE2', date2);
              }}
              service={relationshipService}
            />
          )}
          {!byClient && filterType === 'day' && (
            <DateFilter
              date1={date.date1}
              date2={date.date2}
              setDate={(date1, date2) => {
                setDate({ date1, date2 });
                window.localStorage.setItem('RELATIONSHIP_FILTER_DATE1', date1);
                window.localStorage.setItem('RELATIONSHIP_FILTER_DATE2', date2);
              }}
            />
          )}
          <div className="config-components-popup-button">
            <FloatingActionButton
              icon={INTERNAL_BUTTON.BUTTON_REFRESH}
              onClick={() => {
                setReload((prev) => prev + 1);
              }}
            />
          </div>

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            url="/relationships/relationshipRegistration/0"
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (relationshipsSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhum registro selecionado');
                },
                text: 'Excluir Registro'
              },
              {
                id: INTERNAL_ITEMS.CONFIGURAR,
                onClick: () => setPopUpSettings(true),
                text: 'Configurar Tela'
              }
            ]}
          />
        </div>
        <Table
          name="Relationships"
          data={relationships}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setRelationshipsSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/relationships/relationshipRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeRelationships}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirmação"
        message="Você tem certeza que deseja excluir o(s) registros(s) selecionado(s)?"
      />
      <PopUpViewer
        visible={popUpViewer}
        setVisible={setPopUpViewer}
        title="Pré-visualização"
        data={dataViewer}
        type="application/pdf"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpSettings
        onConfirm={(val) => {
          setFilterType(val);
          window.localStorage.setItem('RELATIONSHIP_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Preferências"
        filter={filterType}
      />
    </>
  );
};

export default Relationships;
