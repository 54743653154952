import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FormFieldDrop from '../../formField/drop';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';
import { useForms } from '../../../hooks';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';

const initialForm = {
  Descricao: ''
};

const PopUpAddClassification = ({
  addClassification,
  visible,
  setVisible,
  planAccounts,
  centerCosts
}) => {
  const [form, updateProperty, setForm] = useForms(initialForm);
  const [planAccount, setPlanAccount] = useState({});
  const [centerCost, setCenterCost] = useState({});
  const [value, setValue] = useState(0);

  const confirmAddClassification = () => {
    const classification = {
      Descricao: form.Descricao,
      PlanoDeContas: planAccount,
      CentroDeCustos: centerCost,
      Valor: value
    };
    addClassification(classification);
    setForm(initialForm);
    setPlanAccount({});
    setCenterCost({});
    setValue(0);
  };

  return (
    <Popup
      title="Adicionar Classificação"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirmAddClassification}
    >
      <FormRow>
        <GenericField
          titleLabel="Descrição"
          onChangeValue={updateProperty}
          valueInput={form.Descricao}
          classNameWrapper="fill-100-field"
          name="Descricao"
        />
      </FormRow>
      <FormRow withShrink>
        <FormFieldDrop
          titleLabel="Plano de Contas"
          defaultValueSelect="Selecione..."
          onChangeValue={(val) => {
            setPlanAccount(val.value);
          }}
          value={planAccount}
          className="fill-100-field"
          infosSelect={planAccounts}
          dataItemKey="Id"
          textField="Nome"
        />
      </FormRow>
      <FormRow withShrink>
        <FormFieldDrop
          titleLabel="Centro de Custo"
          defaultValueSelect="Selecione..."
          onChangeValue={(val) => {
            setCenterCost(val.value);
          }}
          value={centerCost}
          className="fill-100-field"
          infosSelect={centerCosts}
          dataItemKey="Id"
          textField="Nome"
        />
      </FormRow>
      <FormRow>
        <GenericField
          titleLabel="Valor"
          enableReais
          classNameInput="fill-30-field"
          valueInput={value}
          setValue={setValue}
          iconEnabled={INTERNAL_ICON.REAIS_ICON}
          required
        />
      </FormRow>
    </Popup>
  );
};

PopUpAddClassification.propTypes = {
  addClassification: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpAddClassification.defaultProps = {};

export default PopUpAddClassification;
