export const TOKENS = {
  USER: {
    TOKEN: '@next-business/user-token',
    REFRESH_TOKEN: '@next-business/user-refresh-token'
  },
  WALLET: {
    TOKEN: '@next-business/wallet-token',
    REFRESH_TOKEN: '@next-business/wallet-refresh-token'
  },
  REDIRECT: '@next-business/redirect-page'
}

export const setTokenOnStorage = (token, value) => localStorage.setItem(token, value);
export const getTokenFromStorage = (token) => localStorage.getItem(token);

export default TOKENS;
