import React, { useContext, useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDate from '../../../components/formField/date';
import FormFieldDrop from '../../../components/formField/drop';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as saleService from '../../../services/sale';
import * as personService from '../../../services/person';
import * as transactionService from '../../../services/transaction';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';
import * as accountService from '../../../services/account';
import * as addressService from '../../../services/address';
import * as cardService from '../../../services/card';
import * as contractService from '../../../services/contract';
import * as taxClassService from '../../../services/taxClass';
import * as documentService from '../../../services/document';
import LoadScreen from '../../load';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../../components/notification';
import PopupAddService from '../../../components/popUps/popUpAddService';
import PopUpError from '../../../components/popUps/popUpError';
import PopupContractType from '../../../components/popUps/popUpContractType';
import PopUpConfirm from '../../../components/popUps/popUpConfirm';
import GridInline from '../../../components/grid';
import { TotalCell, TotalValueCell, TotalTextCell } from '../../../components/grid/totalCell';
import { AuthContext } from '../../../auth/AuthContext';
import DropDownCell from '../../../components/grid/dropDownCell';
import DateCell from '../../../components/grid/dateCell';
import { paymentStatus, issuedStatus } from '../../../utils/status';
import {
  calculateNewDate,
  compareMonthYear,
  calculateFinalDate,
  times,
  frequencies,
  DateNow,
  CheckSameDate,
  addDays
} from '../../../utils/date';
import './styles.css';
import DateCellMonth from '../../../components/grid/dateCellMonth';
import LabelField from '../../../components/formField/label';
import Client from '../../../components/client';
import NumberCell from '../../../components/grid/numberCell';
import { INTERNAL_BUTTON } from '../../../components/button';
import PopUpFiles from '../../../components/popUps/popUpFiles';

const initialForm = {
  Data: DateNow(),
  Numero: '',
  Situacao: 'Ativo',
  DataInicio: null,
  DataFim: null,
  Data1aParcela: null,
  Validade: null,
  ValidadePeriodo: 'Mes(es)',
  Reajuste: null,
  ReajustePeriodo: 'Ano(s)',
  Parcela: null,
  ParcelaPeriodo: 'Mensal',
  FormaPagamento: null,
  InformacaoAdicional: null
};

const initialContractType = {
  Nome: '',
  Editavel: false
};

const ContractRegistration = () => {
  let { id } = useParams();
  const [contractType, setContractType] = useState(initialContractType);
  const [contractTypes, setContractTypes] = useState([]);
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [cpfCnpj, setCpfCpnj] = useState('');
  const [address, setAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [document, setDocument] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [transactionNature, setTransactionNature] = useState({});
  const [transactionNatures, setTransactionNatures] = useState([]);
  const [client, setClient] = useState(null);
  const [contractServices, setContractServices] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [planAccounts, setPlanAccounts] = useState([]);
  const [centerCosts, setCenterCosts] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [paymentForm, setPaymentForm] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [otherAccounts, setOtherAccounts] = useState([]);
  const [account, setAccount] = useState({});
  const [totalService, setTotalService] = useState(0);
  const [totalAdditionalService, setTotalAdditionalService] = useState(0);
  const { walletSelected } = useContext(AuthContext);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [contractPayments, setContractPayments] = useState([]);
  const statusTypes = ['Ativo', 'Suspenso', 'Encerrado'];
  const redirectToContracts = (id) => {
    if (id) history.push(`/contracts/${id}`);
    else history.push(`/contracts`);
  };
  const [contact, setContact] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [taxClasses, setTaxClasses] = useState([]);
  const [taxClass, setTaxClass] = useState({});
  const [tributeCode, setTributeCode] = useState('-');
  const [serviceList, setServiceList] = useState('-');
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [popUpConfirmPayment, setPopUpConfirmPayment] = useState(false);
  const [messagePayment, setMessagePayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [loadingParcel, setLoadingParcel] = useState(false);
  const [loadingContractTypes, setLoadingContractTypes] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingTaxs, setLoadingTaxs] = useState(false);
  const [cardTaxes, setCardTaxes] = useState([]);
  const [card, setCard] = useState(false);
  const [cardTax, setCardTax] = useState(null);
  const [popUpFile, setPopUpFile] = useState(false);
  const [files, setFiles] = useState([]);

  const [popUpsVisibilities, setPopUpsVisibilities] = useState({
    addService: false,
    addContractType: false,
    editContractType: false,
    addAdditionalService: false
  });

  const calculateAdditionalValue = (date) => {
    const items = additionalServices.filter((element) =>
      compareMonthYear(date, element.DataReferencia)
    );
    const value = items.reduce((acc, current) => acc + current.ValorTotal, 0);
    return value;
  };

  const calculateTotalService = () => {
    let total = contractServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    total = Math.round(total * 100) / 100;
    setTotalService(total);
    return total;
  };

  const updatePayments = (paymentForm, account, days = 0) => {
    setLoadingParcel(true);
    const value = contractServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    const newContractPayments = contractPayments.map((obj) => {
      if (!obj.Emitido) {
        const additionalValue = calculateAdditionalValue(obj.DataVencimento);
        const total = value + additionalValue;
        return {
          ...obj,
          Valor: total,
          DataTransacao: addDays(obj.DataVencimento, days),
          FormaPagamento: paymentForm,
          ContaPagamento: account
        };
      }
      return {
        ...obj
      };
    });
    setContractPayments(newContractPayments);
    setLoadingParcel(false);
    return true;
  };

  const onItemChangeContractService = (item) => {
    item.ValorTotal = item.Quantidade * item.Valor - item.Desconto;
    calculateTotalService();
    updatePayments(paymentForm, account);
    return item;
  };

  const canDeleteContractService = (item) => {
    if (item?.Emitido) {
      warningNotification('Não é possível excluir um lançamento associado a um documento.');
      return false;
    }
    return true;
  };

  const loadServiceDetails = (service) => {
    const taxClass = taxClasses.find((element) => element.Id === service.ClasseDeImpostos_Id);
    if (taxClass !== null) setTaxClass(taxClass);
    setTributeCode(service.CodigoTributacao);
    setServiceList(service.ListaServicos);
  };

  const onItemDeleteContractService = () => {
    calculateTotalService();
    updatePayments(paymentForm, account);
  };

  const addService = (service) => {
    const lastId =
      contractServices.length > 0 ? contractServices[contractServices.length - 1].Id : 0;
    const newItems = contractServices;
    const itemPlanAccount = planAccounts.find((element) => element.Id === service.PlanoDeContas_Id);
    const itemCenterCost = centerCosts.find((element) => element.Id === service.CentroDeCustos_Id);
    newItems.push({
      Id: lastId + 1,
      ServicoId: service.Id,
      Quantidade: service.Quantidade,
      Valor: service.ValorServico,
      ServicoCodigo: service.Codigo,
      ServicoDescricao: service.Descricao,
      Desconto: service.Desconto,
      ValorTotal: service.ValorTotal,
      CodigoTributacao: service.CodigoTributacao,
      ListaServicos: service.ListaServicos,
      PlanoDeContas_Id: service.PlanoDeContas_Id,
      CentroDeCustos_Id: service.CentroDeCustos_Id,
      ServicoUnidadeMedida: service.UnidadeMedida_Nome,
      PlanoDeContas: itemPlanAccount,
      CentroDeCustos: itemCenterCost
    });
    calculateTotalService();
    setContractServices(newItems);
    loadServiceDetails(service);
    updatePayments(paymentForm, account);
  };

  const calculateTotalAdditionalService = () => {
    let total = additionalServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    total = Math.round(total * 100) / 100;
    setTotalAdditionalService(total);
    return total;
  };

  const onItemChangeAdditionalService = (item) => {
    item.ValorTotal = item.Quantidade * item.Valor - item.Desconto;
    calculateTotalAdditionalService();
    updatePayments(paymentForm, account);
    return item;
  };

  const addValueAdditionalPayments = (additionalService) => {
    setLoadingParcel(true);
    const newContractPayments = contractPayments.map((obj) => {
      const sameDate = CheckSameDate(obj.DataVencimento, additionalService.DataReferencia);
      return {
        ...obj,
        Valor: sameDate ? obj.Valor + additionalService.Valor : obj.Valor
      };
    });
    setContractPayments(newContractPayments);
    setLoadingParcel(false);
    return true;
  };

  const onItemDeleteAdditionalService = (service) => {
    calculateTotalAdditionalService();
    updatePayments(paymentForm, account);
  };

  const addAdditionalService = (service) => {
    const lastId =
      additionalServices.length > 0 ? additionalServices[additionalServices.length - 1].Id : 0;
    const newItems = additionalServices;
    const itemPlanAccount = planAccounts.find((element) => element.Id === service.PlanoDeContas_Id);
    const itemCenterCost = centerCosts.find((element) => element.Id === service.CentroDeCustos_Id);
    newItems.push({
      Id: lastId + 1,
      ServicoId: service.Id,
      Quantidade: service.Quantidade,
      Valor: service.ValorServico,
      ServicoCodigo: service.Codigo,
      ServicoDescricao: service.Descricao,
      Desconto: service.Desconto,
      ValorTotal: service.ValorTotal,
      CodigoTributacao: service.CodigoTributacao,
      ListaServicos: service.ListaServicos,
      PlanoDeContas_Id: service.PlanoDeContas_Id,
      CentroDeCustos_Id: service.CentroDeCustos_Id,
      Adicional: true,
      DataReferencia: service.DataReferencia,
      ServicoUnidadeMedida: service.UnidadeMedida_Nome,
      PlanoDeContas: itemPlanAccount,
      CentroDeCustos: itemCenterCost
    });
    calculateTotalAdditionalService();
    setAdditionalServices(newItems);
    updatePayments(paymentForm, account);
  };

  const setPopUpVisibility = (propName, value) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      [propName]: value
    });
  };

  const setClientAddress = async (client, idAddress, contact) => {
    if (client.TipoPessoa === 0) {
      setCpfCpnj(client.CPF);
      setAddress(client.Endereco);
      setContact({ Nome: contact });
      setContacts(null);
    } else {
      let addresses = [];
      if (client.EnderecoPrincipal != null) addresses.push(client.EnderecoPrincipal);
      addresses = addresses.concat(client.OutrosEnderecos);
      addresses = addresses.map((item) => ({
        ...item,
        EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
          .filter(Boolean)
          .join(', ')
      }));
      let newAddress = addresses.filter((x) => x.Id === idAddress);
      newAddress = newAddress.length > 0 ? newAddress[0] : null;
      if (newAddress != null) {
        setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
        setAddress(newAddress);
      } else if (addresses.length === 1) {
        setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
        setAddress(addresses[0]);
      }
      setAddresses(addresses);
      const contacts = [];
      if (client.Contatos?.length > 0) {
        client.Contatos.forEach((item) => {
          contacts.push(item);
        });
      }
      if (addresses?.length > 0) {
        addresses.forEach((item1) => {
          if (item1.Contatos?.length > 0) {
            item1.Contatos.forEach((item2) => {
              contacts.push(item2);
            });
          }
        });
      }
      contacts.sort();
      if (!contacts) {
        setContact({ Nome: contact });
        setContacts(null);
      } else if (contacts.length > 0) {
        const contato = contacts.find((x) => x.Id === contact);
        setContact(contato);
        contacts.sort((a, b) => {
          if (a.Nome < b.Nome) {
            return -1;
          }
          if (a.Nome > b.Nome) {
            return 1;
          }
          return 0;
        });
        setContacts(contacts);
      }
    }
  };

  const loadCities = async () => {
    const { data } = await addressService.getCitiesInvoice();
    setCities(data);
    return data;
  };

  const loadTransactionNatures = async () => {
    const { data } = await saleService.getTransactionNatures();
    setTransactionNatures(data);
    return data;
  };

  const loadDocuments = async () => {
    const { data } = await documentService.getAll();
    setDocuments(data);
    return data;
  };

  const loadPaymentForms = async () => {
    let { data } = await transactionService.getPaymentForms();
    data = data['000'];
    data = data.filter((item) => item.Id !== '2' && item.Id !== '3');
    data.sort((a, b) => {
      if (a.Descricao < b.Descricao) {
        return -1;
      }
      if (a.Descricao > b.Descricao) {
        return 1;
      }
      return 0;
    });
    setPaymentForms(data);
    return data;
  };

  const loadAccounts = async () => {
    const data = await accountService.getReceivingLocal();
    setAccounts(data);
    return data;
  };

  const loadOtherAccounts = async () => {
    const data = await accountService.getReceivablePayableAccount();
    setOtherAccounts(data);
    return data;
  };

  const loadPlanAccounts = async () => {
    const data = await planAccountService.getAllExpensesRevenues();
    setPlanAccounts(data);
    return data;
  };

  const loadCenterCosts = async () => {
    const { data } = await centerCostService.getAll();
    setCenterCosts(data);
    return data;
  };

  const loadContractTypes = async (contractType) => {
    setLoadingType(true);
    const { data } = await contractService.getContractTypes();
    setContractTypes(data);
    setContractType(contractType);
    setLoadingType(false);
    return data;
  };

  const loadContractPayments = async (
    contract,
    paymentForms,
    accounts,
    otherAccounts,
    cardTaxes
  ) => {
    let contractPayments = contract.ContratoLancamento;
    let i = 0;
    contractPayments = contractPayments.map((x) => {
      i++;
      const IdFormaPagamento = `${x.FormaDePagamento}`;
      const itemPaymentForm = paymentForms.find((element) => element.Id === IdFormaPagamento);
      const itemAccount = accounts.find((element) => element.Id === x.IdLocalDePagamento);
      let account = accounts.find((element) => element.Id === x.IdConta);
      if (!account) account = otherAccounts.find((element) => element.Id === x.IdConta);
      let paid = '';
      let paymentDate = null;
      let receiveAccount = null;
      let paidValue = null;
      if (account.NomeTipoConta === 'Conta a Receber') {
        paid = 'A RECEBER';
      } else if (account.NomeTipoConta === 'Contas em Cobrança') {
        paid = 'A COBRAR';
      } else {
        paid = 'OK';
        paymentDate = new Date(x.Data);
        receiveAccount = account;
        paidValue = x.Valor;
      }
      return {
        Id: i,
        Quitada: paid,
        DataTransacao: new Date(x.Data),
        DataVencimento: new Date(x.DataVencimento),
        TipoDocumento: null,
        NumeroDocumento: x.NumeroDocumento,
        DataEmissao: x.IdVenda ? new Date(x.DataEmissao) : null,
        DataPagamento: paymentDate,
        Emitido: !!x.IdVenda,
        Valor: x.Valor,
        FormaPagamento: itemPaymentForm,
        ContaPagamento: itemAccount,
        NossoNumero: x.NossoNumero,
        LocalRecebimento: receiveAccount,
        ValorPago: paidValue
      };
    });
    contractPayments.sort((a, b) => new Date(a.DataVencimento) - new Date(b.DataVencimento));
    return contractPayments;
  };

  const loadCardTaxes = async (id) => {
    const { data } = await cardService.getById(id);
    setCardTaxes(data);
    return data;
  };

  const addPayments = (period, parcel, paymentForm, account, date) => {
    if (!period) {
      warningNotification('Periodicidade das parcelas inválida.');
      return false;
    }
    if (!parcel || parcel <= 0 || parcel > 1000) {
      warningNotification('Número de parcelas inválido.');
      return false;
    }
    if (!date) {
      warningNotification('A data da 1ª Parcela não pode ser nula.');
      return false;
    }
    setLoadingParcel(true);
    let newContractPayments = [];
    const value = contractServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    let newDate = new Date(date);
    let lastId = contractPayments.length > 0 ? contractPayments[contractPayments.length - 1].Id : 0;
    for (let i = 0; i < parcel; i++) {
      const additionalValue = calculateAdditionalValue(newDate);
      newContractPayments.push({
        Id: lastId + 1,
        DataTransacao: new Date(newDate),
        DataVencimento: new Date(newDate),
        Valor: value + additionalValue,
        FormaPagamento: paymentForm,
        ContaPagamento: account
      });
      newDate = calculateNewDate(newDate, period);
      lastId++;
    }
    newContractPayments = contractPayments.concat(newContractPayments);
    newContractPayments.sort((a, b) => new Date(a.DataVencimento) - new Date(b.DataVencimento));
    setContractPayments(newContractPayments);
    setLoadingParcel(false);
    return true;
  };

  const generateTransaction = () => {
    const transactions = [];
    contractPayments.forEach((item) => {
      if (!item.Emitido) {
        const transaction = {
          Data: item.DataTransacao,
          DataEmissao: form.Data,
          DataVencimento: item.DataVencimento,
          Descricao: `Contrato ${form.Numero}`,
          Valor: item.Valor,
          FormaDePagamento: item.FormaPagamento?.Id,
          IdLocalDePagamento: item.ContaPagamento?.Id,
          IdCartaoTaxa: item.CartaoTaxa?.Id
        };
        transactions.push(transaction);
      }
    });
    return transactions;
  };

  const loadTaxClasses = async () => {
    const { data } = await taxClassService.getAll();
    setTaxClasses(data);
    return data;
  };

  const confirmPayment = async () => {
    addPayments(form.ParcelaPeriodo, form.Parcela, paymentForm, account, form.Data1aParcela);
  };

  const createAttachs = async (id) => {
    const attachRequests = files.map((file) => contractService.createFile(id, file.getRawFile()));
    const attachResponses = await Promise.all(attachRequests);

    const isAllRequestsRight = attachResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (!isAllRequestsRight) {
      const erro = ['Houve um erro ao tentar anexar uma ou mais arquivos:'];
      attachResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      return erro;
    }
    return null;
  };

  const calculateCardDays = (paymentForm, cardTax) => {
    let days = 0;
    if (paymentForm && cardTax) {
      if (paymentForm.Descricao === 'Cartão de Crédito') days = cardTax.DiaCredito;
      else if (paymentForm.Descricao === 'Cartão de Débito') days = cardTax.DiaDebito;
    }
    return days;
  };

  useEffect(async () => {
    setLoadingContractTypes(true);
    setLoadingClient(true);
    setLoadingServices(true);
    setLoadingTaxs(true);
    setLoadingPayments(true);
    setLoadingDocuments(true);
    let simplesNacional = false;
    if (walletSelected?.CarteiraBusiness?.SimplesNacional || walletSelected?.CarteiraBusiness?.MEI)
      simplesNacional = true;
    setSimplesNacional(simplesNacional);
    if (id != null && id !== '0') {
      let response = await contractService.getById(id);
      const contract = response.data;
      // ############## Load Contract Types ##############
      setForm({
        Data: new Date(contract.Data),
        Numero: contract.Numero,
        Situacao: contract.Situacao,
        DataInicio: contract.DataInicio ? new Date(contract.DataInicio) : null,
        DataFim: contract.DataFim ? new Date(contract.DataFim) : null,
        Data1aParcela: contract.Data1aParcela ? new Date(contract.Data1aParcela) : null,
        Validade: contract.Validade,
        ValidadePeriodo: contract.ValidadePeriodo,
        Reajuste: contract.Reajuste,
        ReajustePeriodo: contract.ReajustePeriodo,
        Parcela: contract.Parcela,
        ParcelaPeriodo: contract.ParcelaPeriodo,
        FormaPagamento: contract.FormaPagamento,
        InformacaoAdicional: contract.InformacaoAdicional
      });
      const contractTypes = await loadContractTypes();
      let itemContractType = contractTypes.find(
        (element) => element.Id === contract.TipoContratoId
      );
      itemContractType = {
        ...itemContractType,
        Editavel: true
      };
      setContractType(itemContractType);
      setLoadingContractTypes(false);

      // ############## Load Client ##############
      response = await personService.getById(contract.PessoaId);
      const client = response.data;
      setClient(client);
      await setClientAddress(client, contract.EnderecoId, contract.Contato);
      setLoadingClient(false);

      // ############## Load Services ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();

      let apiContractService = contract.ContratoServico.filter((obj) => !obj.Adicional);
      let apiAdditionalService = contract.ContratoServico.filter((obj) => obj.Adicional);
      let idService = 1;
      apiContractService = apiContractService.map((item) => {
        const itemPlanAccount = planAccounts.find(
          (element) => element.Id === item.PlanoDeContas_Id
        );
        const itemCenterCost = centerCosts.find((element) => element.Id === item.CentroDeCustos_Id);
        return {
          ...item,
          Id: idService++,
          PlanoDeContas: itemPlanAccount,
          CentroDeCustos: itemCenterCost,
          ServicoUnidadeMedida: item.Servico.UnidadeMedida_Nome
        };
      });
      idService = 1;
      apiAdditionalService = apiAdditionalService.map((item) => {
        const itemPlanAccount = planAccounts.find(
          (element) => element.Id === item.PlanoDeContas_Id
        );
        const itemCenterCost = centerCosts.find((element) => element.Id === item.CentroDeCustos_Id);
        return {
          ...item,
          Id: idService++,
          DataReferencia: new Date(item.DataReferencia),
          PlanoDeContas: itemPlanAccount,
          CentroDeCustos: itemCenterCost,
          ServicoUnidadeMedida: item.Servico.UnidadeMedida_Nome
        };
      });
      setContractServices(apiContractService);
      setAdditionalServices(apiAdditionalService);
      const totalService = apiContractService.reduce((acc, current) => acc + current.ValorTotal, 0);
      setTotalService(totalService);
      const totalAdditionalService = apiAdditionalService.reduce(
        (acc, current) => acc + current.ValorTotal,
        0
      );
      setTotalAdditionalService(totalAdditionalService);
      setLoadingServices(false);

      // ############## Load Taxs ##############
      const taxClasses = await loadTaxClasses();
      const taxClass = taxClasses.find((element) => element.Id === contract.ClasseDeImpostosId);
      setTaxClass(taxClass);
      setLoadingTaxs(false);

      // ############## Load Payments ##############
      const paymentForms = await loadPaymentForms();
      const accounts = await loadAccounts();
      const otherAccounts = await loadOtherAccounts();
      const cardTaxes = await loadCardTaxes(walletSelected.CarteiraBusiness.Id);
      const contractPayments = await loadContractPayments(
        contract,
        paymentForms,
        accounts,
        otherAccounts,
        cardTaxes
      );

      setContractPayments(contractPayments);
      const itemAccount = accounts.find((element) => element.Id === contract.ContaId);
      setAccount(itemAccount);
      const itemPaymentForm = paymentForms.find(
        (element) => element.Id === contract.FormaPagamento
      );
      setPaymentForm(itemPaymentForm);
      const cardTax = cardTaxes.find((element) => element.Id === contract.CartaoTaxaId);
      setCardTax(cardTax);
      const card =
        itemPaymentForm?.Descricao === 'Cartão de Crédito' ||
        itemPaymentForm?.Descricao === 'Cartão de Débito';
      setCard(card);
      setLoadingPayments(false);

      // ############## Load Documents ##############
      const documents = await loadDocuments();
      const cities = await loadCities();
      const transactionNatures = await loadTransactionNatures();
      const itemDocument = documents.find((element) => element.Id === contract.DocumentoId);
      setDocument(itemDocument);
      if (itemDocument?.TipoDocumentoId !== 2) {
        const itemNature = transactionNatures.find(
          (element) => element.Id === contract.NaturezaOperacaoId
        );
        setTransactionNature(itemNature);
        const itemCity = cities.find((element) => element.idMunicipio === contract.MunicipioId);
        setCity(itemCity);
      }
      if (contract.ContratoServico?.length > 0) {
        setTributeCode(contract.ContratoServico[0].Servico.CodigoTributacao);
        setServiceList(contract.ContratoServico[0].Servico.ListaServicos);
      }
      setLoadingDocuments(false);
    } else {
      // ############## Load Contract Types ##############
      const contractNumber = await contractService.getContractNumber();
      const contractTypes = await loadContractTypes();
      setLoadingContractTypes(false);

      // ############## Load Client ##############
      setContact(null);
      setLoadingClient(false);

      // ############## Load Services ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      setLoadingServices(false);

      // ############## Load Taxs ##############
      const taxClasses = await loadTaxClasses();
      setLoadingTaxs(false);

      // ############## Load Payments ##############
      const paymentForms = await loadPaymentForms();
      const accounts = await loadAccounts();
      const otherAccounts = await loadOtherAccounts();
      const cardTaxes = await loadCardTaxes(walletSelected.CarteiraBusiness.Id);
      setLoadingPayments(false);

      // ############## Load Documents ##############
      const documents = await loadDocuments();
      const transactionNatures = await loadTransactionNatures();
      setDocument(documents[0]);
      if (documents[0] && documents[0].TipoDocumentoId !== 2) {
        const { data } = await addressService.getCityById(documents[0].MunicipioId);
        setCities([data]);
        setCity(data);
        setTransactionNature(transactionNatures[0]);
      }

      setForm({
        ...form,
        Numero: contractNumber
      });
      setLoadingDocuments(false);
    }
  }, []);

  const validate = async () => {
    if (
      loading ||
      loadingDiv ||
      loadingType ||
      loadingClient ||
      loadingServices ||
      loadingParcel ||
      loadingContractTypes ||
      loadingPayments ||
      loadingDocuments ||
      loadingTaxs
    ) {
      warningNotification('Aguarde o carregamento dos dados.');
      return false;
    }
    const total = contractServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    if (total <= 0) {
      warningNotification('O valor total do serviço deve ser maior que zero.');
      return false;
    }
    if (contractServices.length === 0) {
      warningNotification('Pelo menos um servíço deve ser adicionado.');
      return false;
    }
    return true;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      setLoading(true);
      const newContractServices = contractServices.map((item) => ({
        ...item,
        PlanoDeContas_Id: item.PlanoDeContas?.Id,
        CentroDeCustos_Id: item.CentroDeCustos?.Id
      }));
      const newAdditionalServices = additionalServices.map((item) => ({
        ...item,
        PlanoDeContas_Id: item.PlanoDeContas?.Id,
        CentroDeCustos_Id: item.CentroDeCustos?.Id
      }));
      let sum = newContractServices.reduce((partialSum, item) => partialSum + item.ValorTotal, 0);
      sum = Math.round(sum * 100) / 100;
      const bodyForm = {
        ...form,
        FormaPagamento: paymentForm?.Id,
        ContaId: account?.Id,
        Valor: sum,
        PessoaId: client.Id,
        TipoContratoId: contractType?.Id,
        DocumentoId: document?.Id,
        NaturezaOperacaoId: transactionNature?.Id,
        MunicipioId: city?.idMunicipio,
        EnderecoId: address?.Id,
        ContratoServico: newContractServices.concat(newAdditionalServices),
        ContratoLancamento: generateTransaction(),
        Contato: contact?.Id ? contact?.Id : contact?.Nome,
        ClasseDeImpostosId: taxClass?.Id,
        CartaoTaxaId: cardTax?.Id
      };
      const button = e.nativeEvent?.submitter?.name;
      let response = null;
      if (id && id !== '0') {
        response = await contractService.update(id, bodyForm);
      } else {
        response = await contractService.create(bodyForm);
        const { data } = response;
        id = data;
      }
      if (response.status === 200) {
        const erro = await createAttachs(id);
        if (erro) errorNotification(erro);
        else successNotification('Contrato criado/atualizado com sucesso!');
        redirectToContracts(id);
      } else {
        const { data } = response;
        if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
        else errorNotification(data);
      }
      setLoading(false);
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <div id="contract-registration">
        {id != null && id !== '0' ? <h1>Atualizar Contrato</h1> : <h1>Novo Contrato</h1>}
        <div className="buttons-contract-registration">
          <div className="config-components-popup-button">
            <FloatingActionButton
              icon={INTERNAL_BUTTON.BUTTON_ATTACH}
              onClick={() => {
                setPopUpFile(true);
              }}
            />
          </div>
        </div>
        <form className="form-general-invoice" onSubmit={submitForm}>
          {loadingType ? (
            <LoadScreen />
          ) : (
            <>
              <ToggleInvoice title="Tipo de Contrato" startExpanded>
                {loadingContractTypes ? (
                  <LoadScreen />
                ) : (
                  <>
                    <FormRow withShrink>
                      <FormFieldDrop
                        titleLabel="Tipo de Contrato"
                        defaultValueSelect="Selecione..."
                        onChangeValue={(val) => {
                          const contractType = val.value;
                          if (contractType == null) setContractType(initialForm);
                          else {
                            contractType.Editavel = true;
                            setContractType(contractType);
                          }
                        }}
                        value={contractType}
                        className="fill-100-field"
                        infosSelect={contractTypes}
                        dataItemKey="Id"
                        textField="Nome"
                        enablePencil={contractType?.Editavel}
                        enableIcon
                        onClickIcon={() => setPopUpVisibility('addContractType', true)}
                        onClickPencil={() => setPopUpVisibility('editContractType', true)}
                        required
                      />
                    </FormRow>
                    <FormRow withShrink>
                      <FormFieldDate
                        titleLabel="Data de Criação"
                        value={form.Data}
                        valueOnChange={(val) => updateFormValue('Data', val)}
                        className="fill-33-field"
                        required
                        disabled
                      />
                      <FormFieldDrop
                        titleLabel="Situação"
                        defaultValueSelect="Selecione..."
                        onChangeValue={(val) => {
                          updateProperty(val);
                          const {
                            target: { name, value }
                          } = val;
                        }}
                        value={form.Situacao}
                        name="Situacao"
                        className="fill-33-field"
                        infosSelect={statusTypes}
                      />
                      <GenericField
                        titleLabel="Número"
                        enableInfo
                        onChangeValue={updateProperty}
                        msg="Número sequencial dos contratos"
                        valueInput={form.Numero}
                        classNameWrapper="fill-33-field"
                        required
                        name="Numero"
                        typeNumber
                        disabled
                      />
                    </FormRow>
                  </>
                )}
              </ToggleInvoice>
            </>
          )}
          <ToggleInvoice title="Cliente" startExpanded>
            {loadingClient ? (
              <LoadScreen />
            ) : (
              <>
                <Client
                  client={client}
                  setClient={setClient}
                  cpfCnpj={cpfCnpj}
                  setCpfCpnj={setCpfCpnj}
                  address={address}
                  setAddress={setAddress}
                  addresses={addresses}
                  setAddresses={setAddresses}
                  contact={contact}
                  setContact={setContact}
                  contacts={contacts}
                  setContacts={setContacts}
                />
                {address?.RetemISS && (
                  <FormRow>
                    <h3 style={{ color: 'red', fontWeight: 'bold' }}>Cliente retém ISS</h3>
                  </FormRow>
                )}
              </>
            )}
          </ToggleInvoice>
          <ToggleInvoice title="Serviços Contratados" startExpanded>
            {loadingServices ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow>
                  <GridInline
                    data={contractServices}
                    setData={setContractServices}
                    onItemChange={onItemChangeContractService}
                    deleteColumn={true}
                    onItemDelete={onItemDeleteContractService}
                  >
                    <GridColumn field="ServicoCodigo" title="Código" editable={false} />
                    <GridColumn
                      field="ServicoDescricao"
                      title="Descrição"
                      width={200}
                      editable={false}
                    />
                    <GridColumn
                      field="PlanoDeContas"
                      title="Plano de Contas"
                      width={200}
                      editable={true}
                      cell={(props) => DropDownCell(props, planAccounts, 'Id', 'Nome')}
                    />
                    <GridColumn
                      field="CentroDeCustos"
                      title="Centro de Custo"
                      editable={true}
                      width={200}
                      cell={(props) => DropDownCell(props, centerCosts, 'Id', 'Nome')}
                      footerCell={(props) => TotalTextCell(props)}
                    />
                    <GridColumn
                      field="Quantidade"
                      title="Quantidade"
                      editable={true}
                      editor="numeric"
                    />
                    <GridColumn
                      field="ServicoUnidadeMedida"
                      title="Unid. Med."
                      editable={false}
                      cell={(props) => (
                        <td style={{ textAlign: 'right' }}>
                          {props.dataItem.ServicoUnidadeMedida}
                        </td>
                      )}
                    />
                    <GridColumn
                      field="Valor"
                      title="Valor"
                      editable={true}
                      editor="numeric"
                      cell={NumberCell}
                    />
                    <GridColumn
                      field="Desconto"
                      title="Desconto (valor)"
                      editable={true}
                      editor="numeric"
                      cell={NumberCell}
                    />
                    <GridColumn
                      field="ValorTotal"
                      title="Total"
                      editable={false}
                      editor="numeric"
                      format="{0:n2}"
                      footerCell={(props) => TotalValueCell(props, totalService)}
                    />
                  </GridInline>
                </FormRow>
                <FormRow justifyEnd space10>
                  <OrangeButton onClick={() => setPopUpVisibility('addService', true)}>
                    Adicionar Serviço
                  </OrangeButton>
                </FormRow>
              </>
            )}
          </ToggleInvoice>
          <ToggleInvoice title="Serviços Adicionais">
            {loadingServices ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow>
                  <GridInline
                    data={additionalServices}
                    setData={setAdditionalServices}
                    onItemChange={onItemChangeAdditionalService}
                    deleteColumn={true}
                    onItemDelete={onItemDeleteAdditionalService}
                  >
                    <GridColumn
                      field="DataReferencia"
                      title="Referência"
                      editable={true}
                      cell={DateCellMonth}
                      footerCell={(props) => TotalTextCell(props)}
                    />
                    <GridColumn field="ServicoCodigo" title="Código" editable={false} />
                    <GridColumn
                      field="ServicoDescricao"
                      title="Descrição"
                      width={200}
                      editable={false}
                    />
                    <GridColumn
                      field="PlanoDeContas"
                      title="Plano de Contas"
                      width={200}
                      editable={true}
                      cell={(props) => DropDownCell(props, planAccounts, 'Id', 'Nome')}
                    />
                    <GridColumn
                      field="CentroDeCustos"
                      title="Centro de Custo"
                      editable={true}
                      width={200}
                      cell={(props) => DropDownCell(props, centerCosts, 'Id', 'Nome')}
                      footerCell={(props) => TotalTextCell(props)}
                    />
                    <GridColumn
                      field="Quantidade"
                      title="Quantidade"
                      editable={true}
                      editor="numeric"
                    />
                    <GridColumn
                      field="ServicoUnidadeMedida"
                      title="Unidade de Medida"
                      editable={false}
                      cell={(props) => (
                        <td style={{ textAlign: 'right' }}>
                          {props.dataItem.ServicoUnidadeMedida}
                        </td>
                      )}
                    />
                    <GridColumn
                      field="Valor"
                      title="Valor"
                      editable={true}
                      editor="numeric"
                      cell={NumberCell}
                    />
                    <GridColumn
                      field="Desconto"
                      title="Desconto (valor)"
                      editable={true}
                      editor="numeric"
                      cell={NumberCell}
                    />
                    <GridColumn
                      field="ValorTotal"
                      title="Total"
                      editable={false}
                      editor="numeric"
                      format="{0:n2}"
                      footerCell={(props) => TotalValueCell(props, totalAdditionalService)}
                    />
                  </GridInline>
                </FormRow>
                <FormRow justifyEnd space10>
                  <OrangeButton onClick={() => setPopUpVisibility('addAdditionalService', true)}>
                    Adicionar Serviço
                  </OrangeButton>
                </FormRow>
              </>
            )}
          </ToggleInvoice>
          {(!simplesNacional || loadingTaxs) && (
            <ToggleInvoice title="Classe de Impostos" startExpanded>
              <FormRow>
                <FormFieldDrop
                  titleLabel="Classe de Impostos"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(event) => {
                    setTaxClass(event.value);
                  }}
                  value={taxClass}
                  className="fill-40-field"
                  infosSelect={taxClasses}
                  dataItemKey="Id"
                  textField="Nome"
                  name="TaxClass"
                  required
                />
                <LabelField
                  titleLabel="Código de Tributação do Município"
                  classNameWrapper="fill-40-field"
                  valueLabel={tributeCode}
                />
                <LabelField
                  titleLabel="Lista de Serviços LC116/3"
                  classNameWrapper="fill-40-field"
                  valueLabel={serviceList}
                />
              </FormRow>
            </ToggleInvoice>
          )}
          <ToggleInvoice title="Recebimentos" startExpanded>
            {loadingPayments ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow withShrink>
                  <FormFieldDate
                    titleLabel="Data Início do Contrato"
                    value={form.DataInicio}
                    valueOnChange={(val) => {
                      const finalDate = calculateFinalDate(
                        val,
                        form.Validade,
                        form.ValidadePeriodo
                      );
                      setForm({
                        ...form,
                        DataInicio: val,
                        DataFim: finalDate
                      });
                    }}
                    className="fill-25-field"
                    required
                  />
                  <div className="fill-25-field">
                    <FormRow withoutMarginTop>
                      <GenericField
                        titleLabel="Validade"
                        onChangeValue={(val) => {
                          const {
                            target: { name, valueAsNumber }
                          } = val;
                          const finalDate = calculateFinalDate(
                            form.DataInicio,
                            valueAsNumber,
                            form.ValidadePeriodo
                          );
                          setForm({
                            ...form,
                            DataFim: finalDate,
                            Validade: valueAsNumber
                          });
                        }}
                        valueInput={form.Validade}
                        classNameWrapper="fill-30-field"
                        name="Validade"
                        typeNumber
                        required
                      />
                      <FormFieldDrop
                        titleLabel="Periodicidade de Validade"
                        defaultValueSelect="Selecione..."
                        onChangeValue={(val) => {
                          const finalDate = calculateFinalDate(
                            form.DataInicio,
                            form.Validade,
                            val.value
                          );
                          setForm({
                            ...form,
                            DataFim: finalDate,
                            ValidadePeriodo: val.value
                          });
                        }}
                        value={form.ValidadePeriodo}
                        name="ValidadePeriodo"
                        className="fill-60-field"
                        infosSelect={times}
                        required
                      />
                    </FormRow>
                  </div>
                  <FormFieldDate
                    titleLabel="Data Fim Contrato"
                    value={form.DataFim}
                    valueOnChange={(val) => updateFormValue('DataFim', val)}
                    className="fill-25-field"
                    required
                    disabled
                  />
                  <div className="fill-25-field">
                    <FormRow withoutMarginTop>
                      <GenericField
                        titleLabel="Reajuste"
                        onChangeValue={(val) => {
                          updateProperty(val);
                        }}
                        valueInput={form.Reajuste}
                        classNameWrapper="fill-30-field"
                        name="Reajuste"
                        typeNumber
                        required
                      />
                      <FormFieldDrop
                        titleLabel="Periodicidade de Reajuste"
                        defaultValueSelect="Selecione..."
                        onChangeValue={(val) => {
                          updateProperty(val);
                        }}
                        value={form.ReajustePeriodo}
                        name="ReajustePeriodo"
                        className="fill-60-field"
                        infosSelect={times}
                        required
                      />
                    </FormRow>
                  </div>
                </FormRow>
                <FormRow withShrink>
                  <FormFieldDate
                    titleLabel="Data da 1ª Parcela"
                    value={form.Data1aParcela}
                    valueOnChange={(val) => {
                      updateFormValue('Data1aParcela', val);
                    }}
                    className="fill-25-field"
                    required
                  />
                  <GenericField
                    titleLabel="Nº de Parcelas"
                    onChangeValue={(val) => {
                      updateProperty(val);
                    }}
                    valueInput={form.Parcela}
                    classNameWrapper="fill-25-field"
                    name="Parcela"
                    typeNumber
                    required
                  />
                  <FormFieldDrop
                    titleLabel="Periodicidade de Parcelas"
                    defaultValueSelect="Selecione..."
                    onChangeValue={(val) => {
                      updateProperty(val);
                    }}
                    value={form.ParcelaPeriodo}
                    name="ParcelaPeriodo"
                    className="fill-25-field"
                    infosSelect={frequencies}
                    required
                  />
                  <span className="fill-25-field">
                    <OrangeButton
                      onClick={() => {
                        const total = contractServices.reduce(
                          (acc, current) => acc + current.ValorTotal,
                          0
                        );
                        if (total > 0) {
                          if (contractServices.length > 0) {
                            if (form.Data1aParcela && form.Parcela) {
                              let message = `Você deseja criar ${form.Parcela} parcela(s) com as seguintes datas: `;
                              let newDate = new Date(form.Data1aParcela);
                              for (let i = 0; i < form.Parcela; i++) {
                                message += '\n';
                                message += newDate.toLocaleDateString();
                                newDate = calculateNewDate(newDate, form.ParcelaPeriodo);
                              }
                              setMessagePayment(message);
                              setPopUpConfirmPayment(true);
                            } else {
                              warningNotification(
                                'A data da 1a parcela ou o número de parcelas não pode ser nulo.'
                              );
                            }
                          } else {
                            warningNotification('Pelo menos um servíço deve ser adicionado.');
                          }
                        } else {
                          warningNotification('O valor total do serviço deve ser mairo que zero.');
                        }
                      }}
                    >
                      Criar Parcelas
                    </OrangeButton>
                  </span>
                </FormRow>
                {loadingParcel ? (
                  <LoadScreen />
                ) : (
                  <>
                    <FormRow withShrink>
                      <GridInline
                        data={contractPayments}
                        setData={setContractPayments}
                        deleteColumn={true}
                        canDelete={canDeleteContractService}
                      >
                        <GridColumn
                          field="Quitada"
                          title="Quitada"
                          cell={(props) => {
                            let td = <td>{props.dataItem.Quitada}</td>;
                            if (props.dataItem.Quitada === 'A COBRAR')
                              td = <td style={{ color: 'red' }}>{props.dataItem.Quitada}</td>;
                            return td;
                          }}
                        />
                        <GridColumn
                          field="DataTransacao"
                          title="Data de Recebimento"
                          editable={false}
                          format="{0:dd/MM/yyyy}"
                          footerCell={(props) => TotalTextCell(props)}
                        />
                        <GridColumn
                          field="DataVencimento"
                          title="Data de Vencimento"
                          editable={true}
                          cell={DateCell}
                          footerCell={(props) => TotalTextCell(props)}
                        />
                        <GridColumn
                          field="Valor"
                          title="Valor (R$)"
                          editor="numeric"
                          cell={NumberCell}
                          editable={true}
                          footerCell={(props) => TotalCell(props, contractPayments, 'Valor')}
                        />
                        <GridColumn
                          field="NumeroDocumento"
                          title="Nº do Documento"
                          editable={false}
                        />
                        <GridColumn
                          field="DataEmissao"
                          title="Data de Emissão"
                          format="{0:dd/MM/yyyy}"
                          editable={false}
                        />
                        <GridColumn
                          field="FormaPagamento"
                          title="Forma de Cobrança"
                          editable={true}
                          cell={(props) => DropDownCell(props, paymentForms, 'Id', 'Descricao')}
                        />
                        <GridColumn
                          field="ContaPagamento"
                          title="Local de Cobrança"
                          editable={true}
                          cell={(props) => DropDownCell(props, accounts, 'Id', 'Nome')}
                        />
                        <GridColumn field="NossoNumero" title="Nosso Número" editable={false} />
                        <GridColumn
                          field="DataPagamento"
                          title="Data de Pagamento"
                          format="{0:dd/MM/yyyy}"
                          editable={false}
                        />
                        <GridColumn
                          field="ValorPago"
                          title="Valor Pago (R$)"
                          editor="numeric"
                          cell={NumberCell}
                          editable={false}
                        />
                        <GridColumn
                          field="LocalRecebimento"
                          title="Local de Pagamento"
                          editable={false}
                          cell={(props) => DropDownCell(props, accounts, 'Id', 'Nome')}
                        />
                      </GridInline>
                    </FormRow>
                  </>
                )}
              </>
            )}
          </ToggleInvoice>
          <ToggleInvoice title="Documentos" startExpanded>
            {loadingDocuments ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Tipo de Documento"
                    defaultValueSelect="Selecione..."
                    onChangeValue={async (val) => {
                      if (val.value) {
                        setDocument(val.value);
                        if (val.value.TipoDocumentoId !== 2) {
                          const { data } = await addressService.getCityById(val.value.MunicipioId);
                          setCities([data]);
                          setCity(data);
                          setTransactionNature(transactionNatures[0]);
                        } else {
                          setCities([]);
                          setCity(null);
                          setTransactionNature(null);
                        }
                      } else {
                        setCities([]);
                        setCity(null);
                        setTransactionNature(null);
                      }
                    }}
                    value={document}
                    className="fill-40-field"
                    infosSelect={documents}
                    dataItemKey="Id"
                    textField="Nome"
                    required
                  />
                  {document?.TipoDocumentoId !== 2 && (
                    <>
                      <FormFieldDrop
                        titleLabel="Cidade"
                        defaultValueSelect="Selecione..."
                        onChangeValue={async (val) => {
                          setCity(val.value);
                        }}
                        value={city}
                        className="fill-40-field"
                        infosSelect={cities}
                        dataItemKey="idMunicipio"
                        textField="nomeMunicipio"
                        required
                      />
                      <FormFieldDrop
                        titleLabel="Natureza da Operação"
                        defaultValueSelect="Selecione..."
                        onChangeValue={(val) => {
                          setTransactionNature(val.value);
                        }}
                        value={transactionNature}
                        className="fill-40-field"
                        infosSelect={transactionNatures}
                        dataItemKey="Id"
                        textField="Nome"
                        required
                      />
                    </>
                  )}
                </FormRow>
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Forma de Recebimento"
                    defaultValueSelect="Selecione..."
                    onChangeValue={(val) => {
                      setPaymentForm(val.value);
                      updatePayments(val.value, account);
                      const card =
                        val.value.Descricao === 'Cartão de Crédito' ||
                        val.value.Descricao === 'Cartão de Débito';
                      setCard(card);
                      setCardTax(null);
                    }}
                    value={paymentForm}
                    className="fill-50-field"
                    infosSelect={paymentForms}
                    dataItemKey="Id"
                    textField="Descricao"
                    name="FormaPagamento"
                  />
                  {card && (
                    <FormFieldDrop
                      titleLabel="Cartão"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        setCardTax(val.value);
                        const days = calculateCardDays(paymentForm, val.value);
                        updatePayments(paymentForm, account, days);
                      }}
                      value={cardTax}
                      className="fill-50-field"
                      infosSelect={cardTaxes}
                      dataItemKey="Operadora"
                      textField="Operadora"
                    />
                  )}
                  {!card && (
                    <FormFieldDrop
                      titleLabel="Local de Recebimento"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        setAccount(val.value);
                        updatePayments(paymentForm, val.value);
                      }}
                      value={account}
                      className="fill-50-field"
                      infosSelect={accounts}
                      dataItemKey="Id"
                      textField="Nome"
                    />
                  )}
                </FormRow>
              </>
            )}
          </ToggleInvoice>
          <ToggleInvoice title="Informações Adicionais">
            <FormRow>
              <GenericField
                titleLabel="Informações sobre o contrato"
                classNameWrapper="fill-100-field"
                isTextArea
                rows={10}
                valueInput={form.InformacaoAdicional}
                onChangeValue={(val) => {
                  updateFormValue('InformacaoAdicional', val.value);
                }}
              />
            </FormRow>
          </ToggleInvoice>
          <FormRow justifyEnd space15 marginTop40>
            <OrangeButton type="submit" name="salvar">
              Salvar
            </OrangeButton>
            <WhiteButton onClick={() => redirectToContracts(id)}>Cancelar</WhiteButton>
          </FormRow>
        </form>
        <PopupAddService
          visible={popUpsVisibilities.addService}
          setVisible={(val) => setPopUpVisibility('addService', val)}
          addService={(service) => addService(service)}
        />
        <PopupAddService
          visible={popUpsVisibilities.addAdditionalService}
          setVisible={(val) => setPopUpVisibility('addAdditionalService', val)}
          addService={(service) => addAdditionalService(service)}
          isAdditional={true}
        />
        <PopupContractType
          visible={popUpsVisibilities.addContractType}
          setVisible={(val) => setPopUpVisibility('addContractType', val)}
          reloadContractTypes={loadContractTypes}
        />
        <PopupContractType
          visible={popUpsVisibilities.editContractType}
          setVisible={(val) => setPopUpVisibility('editContractType', val)}
          reloadContractTypes={loadContractTypes}
          entity={contractType}
          isEdit
        />
        <PopUpError
          visible={popUpError}
          setVisible={setPopUpError}
          title={popUpErrorTitle}
          message={popUpErrorMessage}
        />
        <PopUpConfirm
          onConfirm={confirmPayment}
          visible={popUpConfirmPayment}
          setVisible={setPopUpConfirmPayment}
          title="Confirmação"
          message={messagePayment}
        />
        <PopUpFiles
          visible={popUpFile}
          setVisible={setPopUpFile}
          title="Anexos"
          id={id && id !== '0' ? id : null}
          setExternalFiles={setFiles}
          service={contractService}
        />
      </div>
    </>
  );
};

export default ContractRegistration;
