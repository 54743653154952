import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { successNotification, errorNotification, warningNotification } from '../../notification';
import { FormFieldDrop, FormFieldGeneric } from '../../formField';
import FormRow from '../../formRow';
import Popup from '../layout';
import * as serviceService from '../../../services/service';
import { useForms } from '../../../hooks';

const initialForm = {
  Nome: ''
};

const PopUpMeasureUnit = ({ visible, setVisible, reloadMeasureUnits, isEdit, entity }) => {
  const [form, updateForm, setForm] = useForms(initialForm);

  const createMeasureUnit = async (body) => {
    const response = await serviceService.createMeasureUnit(body);
    const { data } = response;
    if (response.status === 200) {
      body = {
        ...body,
        Id: data,
        Editavel: true
      };
      successNotification('Unidade de medida criada com sucesso!');
      await reloadMeasureUnits(body);
    } else if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
    else errorNotification('Houve um erro ao tentar atualizar uma unidade de medida.');
  };

  const editMeasureUnit = async (body) => {
    const response = await serviceService.updateMeasureUnit(body, body.Id);
    if (response.status === 200) {
      body = {
        Id: body.Id,
        Editavel: true,
        ...body
      };
      successNotification('Unidade de medida atualizada com sucesso!');
      await reloadMeasureUnits(body);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar atualizar uma unidade de medida.');
    }
  };

  const excludeMeasureUnit = async () => {
    const response = await serviceService.excludeMeasureUnit(entity.Id);
    if (response.status === 200) {
      successNotification('Unidade de medida excluída com sucesso!');
      await reloadMeasureUnits(null);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar excluir uma unidade de medida.');
    }
  };

  const submitHandle = () => {
    const body = {
      Id: entity?.Id,
      ...form
    };

    return isEdit ? editMeasureUnit(body) : createMeasureUnit(body);
  };

  useEffect(() => {
    setForm(entity || initialForm);
  }, [entity, visible]);

  return (
    <Popup
      visible={visible}
      setVisible={setVisible}
      title="Unidade de Medida"
      confirmText="Salvar"
      cancelText="Cancelar"
      haveExclude={isEdit}
      onConfirm={submitHandle}
      onDelete={excludeMeasureUnit}
    >
      <FormRow>
        <FormFieldGeneric
          titleLabel="Nome"
          classNameWrapper="fill-100-field-tooltip"
          name="Nome"
          onChangeValue={updateForm}
          valueInput={form.Nome}
          required
        />
      </FormRow>
    </Popup>
  );
};

PopUpMeasureUnit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reloadMeasureUnits: PropTypes.func.isRequired
};

export default PopUpMeasureUnit;
