/* eslint no-restricted-syntax: 0 */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import { CalendarWithButtons, DateFilter } from '../../components/dateComponents';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpEmail from '../../components/popUps/popUpEmail';
import PopUpCancelInvoice from '../../components/popUps/popUpCancelInvoice';
import PopUpError from '../../components/popUps/popUpError';
import PopUpViewer from '../../components/popUps/popUpViewer';
import './styles.css';
import LoadScreen from '../load';
import * as saleService from '../../services/sale';
import * as invoiceService from '../../services/invoice';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../components/notification';
import { FirstDayOfMonth, LastDayOfMonth, DateNow } from '../../utils/date';
import { saveByteArray, urlBase64NewTab } from '../../utils/download';
import { invoiceStatus, invoiceStatusText } from '../../utils/status';
import PDFIcon from '../../assets/icone-pdf.svg';
import XMLIcon from '../../assets/icone-xml.svg';
import BillIcon from '../../assets/icone-bill.svg';
import PopUpSettings from '../../components/popUps/popUpSettings';
import { useQuery } from '../../hooks';

const Invoices = () => {
  const [reload, setReload] = useState(0);
  const { id } = useParams();
  const query = useQuery();
  const byClient = query.get('byClient');
  const clientId = query.get('clientId');
  const addressId = query.get('addressId');
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [invoicesSelectedsIds, setInvoicesSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpConfirmCancel, setPopUpConfirmCancel] = useState(false);
  const [popUpConfirmTransmit, setPopUpConfirmTransmit] = useState(false);
  const [popUpConfirmSend, setPopUpConfirmSend] = useState(false);
  const [popUpConfirmSendBill, setPopUpConfirmSendBill] = useState(false);
  const [popUpConfirmSendPix, setPopUpConfirmSendPix] = useState(false);
  const [popUpConfirmPrint, setPopUpConfirmPrint] = useState(false);
  const [popUpConfirmBill, setPopUpConfirmBill] = useState(false);
  const [popUpExportExcel, setPopUpExportExcel] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [popUpConfirmPix, setPopUpConfirmPix] = useState(false);
  const [popUpViewer, setPopUpViewer] = useState(false);
  const [popUpSettings, setPopUpSettings] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [dataViewer, setDataViewer] = useState(null);
  const filterDateLocal1 = window.localStorage.getItem('INVOICE_FILTER_DATE1');
  const filterDateLocal2 = window.localStorage.getItem('INVOICE_FILTER_DATE2');
  const dateNow = DateNow();
  let filterDate = { date1: FirstDayOfMonth(dateNow), date2: LastDayOfMonth(dateNow) };
  if (filterDateLocal1 && id)
    filterDate = { date1: new Date(filterDateLocal1), date2: new Date(filterDateLocal2) };
  const [date, setDate] = useState(filterDate);
  const filterTypeLocal = window.localStorage.getItem('INVOICE_FILTER_TYPE');
  const [filterType, setFilterType] = useState(filterTypeLocal || 'month');
  const [otherEmail, setOtherEmail] = useState(null);

  window.localStorage.setItem('INVOICE_FILTER_DATE1', date.date1);
  window.localStorage.setItem('INVOICE_FILTER_DATE2', date.date2);
  window.localStorage.setItem('INVOICE_FILTER_TYPE', filterType);

  const printInvoice = async (id, tipo) => {
    const printInvoicesRequests =
      tipo === 'xml' ? [invoiceService.recoveryXML(id)] : [invoiceService.recoveryPDF(id)];
    const printInvoicesResponses = await Promise.all(printInvoicesRequests);
    const isAllRequestsRight = printInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );
    printInvoicesResponses.forEach((currentResponse) => {
      if (currentResponse.status === 200) {
        const base64 = currentResponse.data;
        if (tipo === 'xml') saveByteArray('NFSe.xml', base64, tipo);
        else {
          const url = urlBase64NewTab(base64, tipo);
          // setDataViewer(url);
          // setPopUpViewer(true);
        }
      }
    });
    if (!isAllRequestsRight) {
      const erro = ['Houve um erro ao tentar gerar um ou mais documentos:'];
      printInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  const printBill = async (id, tipo) => {
    const printBillRequest = saleService.printBill(id);
    const [response] = await Promise.all([printBillRequest]);
    if (response.status === 200) {
      const { data } = response;
      const url = urlBase64NewTab(data, tipo);
      // setDataViewer(url);
      // setPopUpViewer(true);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar imprimir o boleto.');
    }
  };

  const printPix = async (id, tipo) => {
    const printBillRequest = saleService.printPix(id);
    const [response] = await Promise.all([printBillRequest]);
    if (response.status === 200) {
      const { data } = response;
      const url = urlBase64NewTab(data, tipo);
      // setDataViewer(url);
      // setPopUpViewer(true);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar imprimir o PIX.');
    }
  };

  const columns = [
    {
      field: 'Faturado',
      title: 'Sit.',
      width: 50,
      cell: (props) => {
        const td = invoiceStatus(props.dataItem.Faturado);
        return td;
      },
      columnMenuCheckbox: true
    },
    {
      field: 'Email',
      title: 'Email',
      width: 60,
      cell: (props) => {
        let td = <td style={{ textAlign: 'center' }} />;
        if (props.dataItem.Email) {
          let emailDate = new Date(`${props.dataItem.DataEmail}Z`);
          emailDate = `${emailDate.toLocaleDateString()} ${emailDate.toLocaleTimeString()}`;
          const emailData = `${emailDate} - ${props.dataItem.Email}`;
          td = (
            <td style={{ textAlign: 'center' }}>
              <span title={emailData} className="k-icon k-icon-lg k-i-email " />
            </td>
          );
        }
        return td;
      }
    },
    {
      field: 'AtendimentoNumero',
      title: 'Origem',
      width: 60,
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'TipoDocumentoNome',
      title: 'Tipo',
      width: 80,
      cell: (props) => <td style={{ textAlign: 'center' }}>{props.dataItem.TipoDocumentoNome}</td>,
      columnMenuCheckbox: true
    },
    {
      field: 'Id',
      title: 'Anexos',
      width: 90,
      cell: (props) => {
        let td = null;
        let pdf = null;
        let bill = null;
        if (
          props.dataItem.Faturado &&
          props.dataItem.Faturado !== 'Não emitida' &&
          props.dataItem.Documento?.TipoDocumentoId !== 2 &&
          props.dataItem.Documento?.TipoDocumentoId !== 4
        ) {
          pdf = (
            <>
              <button
                type="button"
                className="general-button"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  printInvoice(props.dataItem.Id, 'pdf');
                }}
              >
                <img src={PDFIcon} width={12} alt="PDF" />
              </button>
              <button
                type="button"
                className="general-button"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  printInvoice(props.dataItem.Id, 'xml');
                }}
              >
                <img src={XMLIcon} width={12} alt="XML" />
              </button>
            </>
          );
        } else if (
          props.dataItem.Faturado &&
          props.dataItem.Faturado !== 'Não emitida' &&
          (props.dataItem.Documento?.TipoDocumentoId === 2 ||
            props.dataItem.Documento?.TipoDocumentoId === 4)
        ) {
          pdf = (
            <button
              type="button"
              className="general-button"
              style={{ marginRight: '5px' }}
              onClick={() => {
                printInvoice(props.dataItem.Id, 'pdf');
              }}
            >
              <img src={PDFIcon} width={12} alt="PDF" />
            </button>
          );
        }
        if (props.dataItem.Boleto === 'S') {
          bill = (
            <button
              type="button"
              className="general-button"
              onClick={() => {
                printBill(props.dataItem.Id, 'PDF');
              }}
            >
              <img src={BillIcon} width={12} title="Boleto" alt="Boleto" />
            </button>
          );
        } else if (props.dataItem.Boleto === 'P') {
          bill = (
            <button
              type="button"
              className="general-button"
              onClick={() => {
                printPix(props.dataItem.Id, 'PDF');
              }}
            >
              <img src={BillIcon} width={12} title="PIX" alt="PIX" />
            </button>
          );
        }
        td = (
          <td style={{ textAlign: 'center' }}>
            {pdf}
            {bill}
          </td>
        );
        return td;
      }
    },
    {
      field: 'Data',
      title: 'Data de Emissão',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'DataVencimento',
      title: 'Data de Venc.',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'NumeroSufixo',
      title: 'Número',
      width: 80,
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'NumeroPrefeitura',
      title: 'Núm. na Prefeitura',
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 300,
      columnMenuCheckbox: true,
      countCell: true
    },
    {
      field: 'Valor',
      title: 'Valor Bru. (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      totalCell: true,
      width: 120,
      columnMenu: true
    },
    {
      field: 'ValorLiquido',
      title: 'Valor Líq. (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      totalCell: true,
      width: 120,
      columnMenu: true
    }
  ];

  const seekScroll = async (data) => {
    if (id != null && id !== '0') {
      const index = data.findIndex((item) => item.Id === id);
      if (index >= 0) {
        setRowIndex(index);
        return;
      }
    }
    if (data.length > 0) setRowIndex(data.length - 1);
    else setRowIndex(0);
  };

  const fetchInvoices = async () => {
    setLoading(true);
    const dateInterval = {
      DataInicio: date.date1,
      DataFim: date.date2
    };
    const clientAddress = {
      IdPessoa: clientId,
      IdEndereco: addressId
    };
    let response = null;
    if (byClient) response = await saleService.getByClientId(clientAddress);
    else response = await saleService.getByDate(dateInterval);
    let { data } = response;

    data = data.map((item) => {
      const description = item.VendaServico[0].ServicoDescricao;
      const number = item.NotaFiscal?.Numero;
      const due =
        item.TipoPagamento === 'Sem parcelamento' ? new Date(item.DataVencimento) : 'Parcelado';
      return {
        ...item,
        Data: new Date(item.Data),
        NumeroPrefeitura: number,
        DataVencimento: due,
        Valor: item.Faturado === 'C' ? null : item.Valor,
        ValorLiquido: item.Faturado === 'C' ? null : item.ValorLiquido,
        AtendimentoNumero: item.AtendimentoNumero > 0 ? item.AtendimentoNumero : null,
        Faturado: invoiceStatusText(item.Faturado)
      };
    });
    setInvoices(data);
    seekScroll(data);
    setInvoicesSelectedsIds([]);
    setLoading(false);
  };

  const excludeInvoices = async () => {
    setLoading(true);
    const excludeInvoicesRequests = invoicesSelectedsIds.map((invoiceSelected) =>
      saleService.exclude(invoiceSelected)
    );

    const excludeInvoicesResponses = await Promise.all(excludeInvoicesRequests);

    const isAllRequestsRight = excludeInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);

    if (isAllRequestsRight) successNotification('Documento(s) excluído(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais documentos:'];
      excludeInvoicesResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const printInvoices = async () => {
    // setLoading(true);
    const printInvoicesRequests = invoicesSelectedsIds.map((invoiceSelected) =>
      invoiceService.exportPDF(invoiceSelected)
    );

    const printInvoicesResponses = await Promise.all(printInvoicesRequests);

    const isAllRequestsRight = printInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    printInvoicesResponses.forEach((currentResponse) => {
      if (currentResponse.status === 200) {
        const base64 = currentResponse.data;
        const url = urlBase64NewTab(base64);
        // setDataViewer(url);
        // setPopUpViewer(true);
      }
    });

    // setLoading(false);
    if (isAllRequestsRight) successNotification('Documento(s) gerado(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar gerar um ou mais documentos:'];
      printInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const transmitInvoices = async () => {
    setLoading(true);

    const selectedInvoices = invoicesSelectedsIds.map((invoiceSelected) =>
      invoices.find((x) => x.Id === invoiceSelected)
    );

    selectedInvoices.sort((a, b) => {
      if (a.Numero < b.Numero) return -1;
      if (a.Numero > b.Numero) return 1;
      return 0;
    });

    const transmitInvoicesResponses = [];

    for await (const item of selectedInvoices) {
      const response = await invoiceService.transmit(item.Id);
      transmitInvoicesResponses.push(response);
    }

    const isAllRequestsRight = transmitInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    if (isAllRequestsRight) successNotification('Documento(s) transmitido(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar transmitir um ou mais documentos:'];
      transmitInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Transmitir Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const cancelInvoices = async (reason) => {
    setLoading(true);
    const cancelInvoicesRequests = invoicesSelectedsIds.map((invoiceSelected) =>
      invoiceService.cancel(invoiceSelected, reason)
    );

    const cancelInvoicesResponses = await Promise.all(cancelInvoicesRequests);

    const isAllRequestsRight = cancelInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    if (isAllRequestsRight) successNotification('Documento(s) cancelada(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar cancelar um ou mais documentos:'];
      cancelInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Cancelar Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const sendInvoices = async (createBills) => {
    setLoading(true);

    const sendInvoicesResponses = [];

    for await (const invoiceSelected of invoicesSelectedsIds) {
      const response = await invoiceService.send(invoiceSelected, createBills, otherEmail);
      sendInvoicesResponses.push(response);
    }

    const isAllRequestsRight = sendInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    if (isAllRequestsRight) {
      sendInvoicesResponses.forEach((element) => {
        const { data } = element;
        successNotification(`Documento enviado com sucesso para: ${data}!`);
      });
    } else {
      const erro = ['Houve um erro ao tentar enviar um ou mais documentos:'];
      sendInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Enviar Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const createBills = async () => {
    setLoading(true);

    const createBillsResponses = [];

    for await (const invoiceSelected of invoicesSelectedsIds) {
      const response = await saleService.generateBill(invoiceSelected);
      createBillsResponses.push(response);
    }

    const isAllRequestsRight = createBillsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    if (isAllRequestsRight) successNotification('Boleto(s) gerado(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar gerar boleto para uma ou mais documentos:'];
      createBillsResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar Boleto');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const createPixs = async () => {
    setLoading(true);

    const createPixsResponses = [];

    for await (const invoiceSelected of invoicesSelectedsIds) {
      const response = await saleService.generatePix(invoiceSelected);
      createPixsResponses.push(response);
    }

    const isAllRequestsRight = createPixsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    if (isAllRequestsRight) successNotification('PIX(s) gerado(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar gerar PIX para uma ou mais documentos:'];
      createPixsResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar PIX');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchInvoices();
  };

  const sendInvoicesWithBill = async () => {
    if (invoicesSelectedsIds.length === 1) sendInvoices(true, otherEmail);
    else if (invoicesSelectedsIds.length > 1)
      warningNotification('Somente um documento deve ser selecionado');
    else warningNotification('Nenhum documento selecionado');
  };

  const sendInvoicesWithoutBill = async () => {
    sendInvoices(false, otherEmail);
  };

  const sendBill = async () => {
    let bill = false;
    let pix = false;
    const getByIdRequests = invoicesSelectedsIds.map((invoiceSelected) =>
      saleService.getById(invoiceSelected)
    );
    const getByIdResponses = await Promise.all(getByIdRequests);
    getByIdResponses.forEach((element) => {
      if (element.status === 200) {
        const { data } = element;
        if (data.Boleto === 'N' && (data.FormaPagamento === '1' || data.FormaPagamento === '49'))
          bill = true;
        else if (data.Boleto === 'N' && data.FormaPagamento === '46') pix = true;
      }
    });
    if (bill) setPopUpConfirmSendBill(true);
    else if (pix) setPopUpConfirmSendPix(true);
    else sendInvoices(false);
  };

  const exportExcel = async () => {
    const dateInterval = {
      DataInicio: FirstDayOfMonth(date.date1),
      DataFim: LastDayOfMonth(date.date2)
    };
    const exportExcelResponse = await saleService.exportExcel(dateInterval);
    if (exportExcelResponse.status === 200) {
      const base64 = exportExcelResponse.data;
      const url = saveByteArray('Faturamento.xlsx', base64, 'xlsx');
    } else {
      let erro = '';
      const { data } = exportExcelResponse;
      if (data.MensagemUsuario) erro = data.MensagemUsuario;
      else erro = data;
      setPopUpErrorTitle('Erro ao Exportar para Excel');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  useEffect(async () => {
    setLoading(true);
    await fetchInvoices();
    setLoading(false);
  }, [date, reload]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="invoice-list">
        <h1>Documentos Emitidos</h1>

        <div className="buttons-invoice-list">
          {!byClient && filterType === 'month' && (
            <CalendarWithButtons
              date={date.date1}
              setDate={(val) => {
                const date1 = FirstDayOfMonth(val);
                const date2 = LastDayOfMonth(val);
                setDate({ date1, date2 });
                window.localStorage.setItem('INVOICE_FILTER_DATE1', date1);
                window.localStorage.setItem('INVOICE_FILTER_DATE2', date2);
              }}
              service={saleService}
            />
          )}
          {!byClient && filterType === 'day' && (
            <DateFilter
              date1={date.date1}
              date2={date.date2}
              setDate={(date1, date2) => {
                setDate({ date1, date2 });
                window.localStorage.setItem('INVOICE_FILTER_DATE1', date1);
                window.localStorage.setItem('INVOICE_FILTER_DATE2', date2);
              }}
            />
          )}
          <div className="config-components-popup-button">
            <FloatingActionButton
              icon={INTERNAL_BUTTON.BUTTON_REFRESH}
              onClick={() => {
                setReload((prev) => prev + 1);
              }}
            />
          </div>

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            url="/invoices/invoiceRegistration/0"
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (invoicesSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Excluir documento'
              },
              {
                id: INTERNAL_ITEMS.EXPORTAR_EXCEL,
                onClick: () => {
                  setPopUpExportExcel(true);
                },
                text: 'Exportar Excel'
              },
              {
                id: INTERNAL_ITEMS.CANCELAR,
                onClick: () => {
                  if (invoicesSelectedsIds.length > 0) setPopUpConfirmCancel(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Cancelar documento'
              },
              {
                id: INTERNAL_ITEMS.BOLETA,
                onClick: () => {
                  if (invoicesSelectedsIds.length >= 1) setPopUpConfirmBill(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Gerar boleto'
              },
              {
                id: INTERNAL_ITEMS.PIX,
                onClick: () => {
                  if (invoicesSelectedsIds.length >= 1) setPopUpConfirmPix(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Gerar PIX'
              },
              {
                id: INTERNAL_ITEMS.EMAIL,
                onClick: () => {
                  if (invoicesSelectedsIds.length > 0) setPopUpConfirmSend(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Enviar e-mail'
              },
              {
                id: INTERNAL_ITEMS.TRANSMITIR,
                onClick: () => {
                  if (invoicesSelectedsIds.length > 0) setPopUpConfirmTransmit(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Gerar documento/Transmitir'
              },
              {
                id: INTERNAL_ITEMS.IMPRIMIR,
                onClick: () => {
                  if (invoicesSelectedsIds.length === 1) printInvoices();
                  else if (invoicesSelectedsIds.length > 1)
                    warningNotification('Somente um documento deve ser selecionado');
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Imprimir documento'
              },
              {
                id: INTERNAL_ITEMS.CONFIGURAR,
                onClick: () => setPopUpSettings(true),
                text: 'Configurar tela'
              }
            ]}
          />
        </div>
        <Table
          name="Invoices"
          data={invoices}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setInvoicesSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/invoices/invoiceRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
        />
      </div>
      <PopUpConfirm
        onConfirm={transmitInvoices}
        visible={popUpConfirmTransmit}
        setVisible={setPopUpConfirmTransmit}
        title="Confirmação"
        message="Você tem certeza que deseja gerar o documento/transmitir? Após a transmissão, os dados do documento não podem ser alterados."
      />
      <PopUpConfirm
        onConfirm={printInvoices}
        visible={popUpConfirmPrint}
        setVisible={setPopUpConfirmPrint}
        title="Confirmação"
        message="Você tem certeza que deseja imprimir o(s) documentos(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={excludeInvoices}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirmação"
        message="Você tem certeza que deseja excluir o(s) documentos(s) selecionado(s)?"
      />
      <PopUpCancelInvoice
        onConfirm={cancelInvoices}
        visible={popUpConfirmCancel}
        setVisible={setPopUpConfirmCancel}
        title="Confirmação"
        message="Você tem certeza que deseja cancelar o(s) documento(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={sendInvoicesWithBill}
        onCancel={sendInvoicesWithoutBill}
        visible={popUpConfirmSendBill}
        setVisible={setPopUpConfirmSendBill}
        title="Confirmação"
        message="Deseja gerar os boleto(s) bancário(s)?"
      />
      <PopUpConfirm
        onConfirm={sendInvoicesWithBill}
        onCancel={sendInvoicesWithoutBill}
        visible={popUpConfirmSendPix}
        setVisible={setPopUpConfirmSendPix}
        title="Confirmação"
        message="Deseja gerar os PIX(s)?"
      />
      <PopUpEmail
        onConfirm={sendBill}
        visible={popUpConfirmSend}
        setVisible={setPopUpConfirmSend}
        title="Confirmação"
        email={otherEmail}
        setEmail={setOtherEmail}
        message="Você tem certeza que deseja enviar por email o(s) documentos(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={createBills}
        visible={popUpConfirmBill}
        setVisible={setPopUpConfirmBill}
        title="Confirmação"
        message="Você tem certeza que deseja gerar o(s) boletos(s) para o(s) documentos(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={createPixs}
        visible={popUpConfirmPix}
        setVisible={setPopUpConfirmPix}
        title="Confirmação"
        message="Você tem certeza que deseja gerar o(s) PIX(s) para o(s) documentos(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={exportExcel}
        visible={popUpExportExcel}
        setVisible={setPopUpExportExcel}
        title="Confirmação"
        message="Você tem certeza que deseja exportar os documentos listados?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpViewer
        visible={popUpViewer}
        setVisible={setPopUpViewer}
        title="Pré-visualização"
        data={dataViewer}
        type="application/pdf"
      />
      <PopUpSettings
        onConfirm={(val) => {
          setFilterType(val);
          window.localStorage.setItem('INVOICE_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Preferências"
        filter={filterType}
      />
    </>
  );
};

export default Invoices;
