export const PayRollItems = [
    {
        "Code": "1000",
        "Description": "Salário, vencimento, soldo"
    },
    {
        "Code": "1001",
        "Description": "Subsídio"
    },
    {
        "Code": "1002",
        "Description": "Descanso semanal remunerado - DSR"
    },
    {
        "Code": "1003",
        "Description": "Horas extraordinárias"
    },
    {
        "Code": "1004",
        "Description": "Horas extraordinárias - Indenização de banco de horas"
    },
    {
        "Code": "1005",
        "Description": "Direito de arena"
    },
    {
        "Code": "1006",
        "Description": "Intervalos intra e inter jornadas não concedidos"
    },
    {
        "Code": "1007",
        "Description": "Luvas e premiações"
    },
    {
        "Code": "1009",
        "Description": "Salário-família - Complemento"
    },
    {
        "Code": "1010",
        "Description": "Salário in natura - Pagos em bens ou serviços"
    },
    {
        "Code": "1011",
        "Description": "Sobreaviso e prontidão"
    },
    {
        "Code": "1020",
        "Description": "Férias"
    },
    {
        "Code": "1021",
        "Description": "Férias - Abono ou gratificação de férias superior a 20 dias"
    },
    {
        "Code": "1022",
        "Description": "Férias - Abono ou gratificação de férias não excedente a 20 dias"
    },
    {
        "Code": "1023",
        "Description": "Férias - Abono pecuniário"
    },
    {
        "Code": "1024",
        "Description": "Férias - Dobro na vigência do contrato"
    },
    {
        "Code": "1040",
        "Description": "Licença-prêmio"
    },
    {
        "Code": "1041",
        "Description": "Licença-prêmio indenizada"
    },
    {
        "Code": "1050",
        "Description": "Remuneração de dias de afastamento"
    },
    {
        "Code": "1080",
        "Description": "Stock option"
    },
    {
        "Code": "1099",
        "Description": "Outras verbas salariais"
    },
    {
        "Code": "1201",
        "Description": "Adicional de função / cargo confiança"
    },
    {
        "Code": "1202",
        "Description": "Adicional de insalubridade"
    },
    {
        "Code": "1203",
        "Description": "Adicional de periculosidade"
    },
    {
        "Code": "1204",
        "Description": "Adicional de transferência"
    },
    {
        "Code": "1205",
        "Description": "Adicional noturno"
    },
    {
        "Code": "1206",
        "Description": "Adicional por tempo de serviço"
    },
    {
        "Code": "1207",
        "Description": "Comissões, porcentagens, produção"
    },
    {
        "Code": "1208",
        "Description": "Gueltas ou gorjetas - Repassadas por fornecedores ou clientes"
    },
    {
        "Code": "1209",
        "Description": "Gueltas ou gorjetas - Repassadas pelo empregador"
    },
    {
        "Code": "1210",
        "Description": "Gratificação por acordo ou convenção coletiva"
    },
    {
        "Code": "1211",
        "Description": "Gratificações"
    },
    {
        "Code": "1212",
        "Description": "Gratificações ou outras verbas de natureza permanente"
    },
    {
        "Code": "1213",
        "Description": "Gratificações ou outras verbas de natureza transitória"
    },
    {
        "Code": "1214",
        "Description": "Adicional de penosidade"
    },
    {
        "Code": "1215",
        "Description": "Adicional de unidocência"
    },
    {
        "Code": "1216",
        "Description": "Adicional de localidade"
    },
    {
        "Code": "1217",
        "Description": "Gratificação de curso/concurso"
    },
    {
        "Code": "1225",
        "Description": "Quebra de caixa"
    },
    {
        "Code": "1230",
        "Description": "Remuneração do dirigente sindical"
    },
    {
        "Code": "1299",
        "Description": "Outros adicionais"
    },
    {
        "Code": "1300",
        "Description": "PLR - Participação em Lucros ou Resultados"
    },
    {
        "Code": "1350",
        "Description": "Bolsa de estudo - Estagiário"
    },
    {
        "Code": "1351",
        "Description": "Bolsa de estudo - Médico residente"
    },
    {
        "Code": "1352",
        "Description": "Bolsa de estudo ou pesquisa"
    },
    {
        "Code": "1401",
        "Description": "Abono"
    },
    {
        "Code": "1402",
        "Description": "Abono PIS/PASEP"
    },
    {
        "Code": "1403",
        "Description": "Abono legal"
    },
    {
        "Code": "1404",
        "Description": "Auxílio babá"
    },
    {
        "Code": "1405",
        "Description": "Assistência médica"
    },
    {
        "Code": "1406",
        "Description": "Auxílio-creche"
    },
    {
        "Code": "1407",
        "Description": "Auxílio-educação"
    },
    {
        "Code": "1409",
        "Description": "Salário-família"
    },
    {
        "Code": "1410",
        "Description": "Auxílio - Locais de difícil acesso"
    },
    {
        "Code": "1411",
        "Description": "Auxílio-natalidade"
    },
    {
        "Code": "1412",
        "Description": "Abono permanência"
    },
    {
        "Code": "1601",
        "Description": "Ajuda de custo - Aeronauta"
    },
    {
        "Code": "1602",
        "Description": "Ajuda de custo de transferência"
    },
    {
        "Code": "1603",
        "Description": "Ajuda de custo"
    },
    {
        "Code": "1604",
        "Description": "Ajuda de custo - Acima de 50% da remuneração mensal"
    },
    {
        "Code": "1619",
        "Description": "Ajuda compensatória - Programa Emergencial de Manutenção do Emprego e da Renda"
    },
    {
        "Code": "1620",
        "Description": "Ressarcimento de despesas pelo uso de veículo próprio"
    },
    {
        "Code": "1621",
        "Description": "Ressarcimento de despesas de viagem, exceto despesas com veículos"
    },
    {
        "Code": "1623",
        "Description": "Ressarcimento de provisão"
    },
    {
        "Code": "1629",
        "Description": "Ressarcimento de outras despesas"
    },
    {
        "Code": "1650",
        "Description": "Diárias de viagem"
    },
    {
        "Code": "1651",
        "Description": "Diárias de viagem - Até 50% do salário"
    },
    {
        "Code": "1652",
        "Description": "Diárias de viagem - Acima de 50% do salário"
    },
    {
        "Code": "1800",
        "Description": "Alimentação concedida em pecúnia"
    },
    {
        "Code": "1801",
        "Description": "Alimentação"
    },
    {
        "Code": "1802",
        "Description": "Etapas (marítimos)"
    },
    {
        "Code": "1805",
        "Description": "Moradia"
    },
    {
        "Code": "1806",
        "Description": "Alimentação em ticket ou cartão, vinculada ao PAT"
    },
    {
        "Code": "1807",
        "Description": "Alimentação em ticket ou cartão, não vinculada ao PAT"
    },
    {
        "Code": "1808",
        "Description": "Cesta básica ou refeição, vinculada ao PAT"
    },
    {
        "Code": "1809",
        "Description": "Cesta básica ou refeição, não vinculada ao PAT"
    },
    {
        "Code": "1810",
        "Description": "Transporte"
    },
    {
        "Code": "1899",
        "Description": "Outros auxílios"
    },
    {
        "Code": "1901",
        "Description": "Juros e/ou atualização monetária"
    },
    {
        "Code": "2501",
        "Description": "Prêmios"
    },
    {
        "Code": "2502",
        "Description": "Liberalidades concedidas em mais de duas parcelas anuais"
    },
    {
        "Code": "2510",
        "Description": "Direitos autorais e intelectuais"
    },
    {
        "Code": "2801",
        "Description": "Quarentena remunerada"
    },
    {
        "Code": "2901",
        "Description": "Empréstimos"
    },
    {
        "Code": "2902",
        "Description": "Vestuário e equipamentos"
    },
    {
        "Code": "2903",
        "Description": "Vestuário e equipamentos"
    },
    {
        "Code": "2920",
        "Description": "Reembolsos diversos"
    },
    {
        "Code": "2930",
        "Description": "Insuficiência de saldo"
    },
    {
        "Code": "2999",
        "Description": "Arredondamentos"
    },
    {
        "Code": "3501",
        "Description": "Remuneração por prestação de serviços"
    },
    {
        "Code": "3505",
        "Description": "Retiradas (pró-labore) de diretores empregados"
    },
    {
        "Code": "3506",
        "Description": "Retiradas (pró-labore) de diretores não empregados"
    },
    {
        "Code": "3508",
        "Description": "Retiradas (pró-labore) de proprietários ou sócios"
    },
    {
        "Code": "3509",
        "Description": "Honorários a conselheiros"
    },
    {
        "Code": "3510",
        "Description": "Gratificação (jeton)"
    },
    {
        "Code": "3511",
        "Description": "Gratificação eleitoral"
    },
    {
        "Code": "3520",
        "Description": "Remuneração de cooperado"
    },
    {
        "Code": "3525",
        "Description": "Côngruas, prebendas e afins"
    },
    {
        "Code": "4010",
        "Description": "Complementação salarial de auxílio-doença"
    },
    {
        "Code": "4011",
        "Description": "Complemento de salário-mínimo - RPPS"
    },
    {
        "Code": "4050",
        "Description": "Salário-maternidade"
    },
    {
        "Code": "4051",
        "Description": "Salário-maternidade - 13° salário"
    },
    {
        "Code": "5001",
        "Description": "13º salário"
    },
    {
        "Code": "5005",
        "Description": "13° salário complementar"
    },
    {
        "Code": "5501",
        "Description": "Adiantamento de salário"
    },
    {
        "Code": "5504",
        "Description": "13º salário - Adiantamento"
    },
    {
        "Code": "5510",
        "Description": "Adiantamento de benefícios previdenciários"
    },
    {
        "Code": "6000",
        "Description": "Saldo de salários na rescisão contratual"
    },
    {
        "Code": "6001",
        "Description": "13º salário relativo ao aviso prévio indenizado"
    },
    {
        "Code": "6002",
        "Description": "13° salário proporcional na rescisão"
    },
    {
        "Code": "6003",
        "Description": "Indenização compensatória do aviso prévio"
    },
    {
        "Code": "6004",
        "Description": "Férias - Dobro na rescisão"
    },
    {
        "Code": "6006",
        "Description": "Férias proporcionais"
    },
    {
        "Code": "6007",
        "Description": "Férias vencidas na rescisão"
    },
    {
        "Code": "6101",
        "Description": "Indenização compensatória - Multa rescisória 20 ou 40% (CF/88)"
    },
    {
        "Code": "6102",
        "Description": "Indenização do art. 9º da Lei 7.238/1984"
    },
    {
        "Code": "6103",
        "Description": "Indenização do art. 14 da Lei 5.889/1973"
    },
    {
        "Code": "6104",
        "Description": "Indenização do art. 479 da CLT"
    },
    {
        "Code": "6105",
        "Description": "Indenização recebida a título de incentivo a demissão"
    },
    {
        "Code": "6106",
        "Description": "Multa do art. 477 da CLT"
    },
    {
        "Code": "6107",
        "Description": "Indenização por quebra de estabilidade"
    },
    {
        "Code": "6108",
        "Description": "Tempo de espera do motorista profissional"
    },
    {
        "Code": "6119",
        "Description": "Indenização rescisória - Programa Emergencial de Manutenção do Emprego e da Renda"
    },
    {
        "Code": "6129",
        "Description": "Outras multas ou indenizações"
    },
    {
        "Code": "6901",
        "Description": "Desconto do aviso prévio"
    },
    {
        "Code": "6904",
        "Description": "Multa prevista no art. 480 da CLT"
    },
    {
        "Code": "7001",
        "Description": "Proventos"
    },
    {
        "Code": "7002",
        "Description": "Proventos - Pensão por morte Civil"
    },
    {
        "Code": "7003",
        "Description": "Proventos - Reserva"
    },
    {
        "Code": "7004",
        "Description": "Proventos - Reforma"
    },
    {
        "Code": "7005",
        "Description": "Pensão Militar"
    },
    {
        "Code": "7006",
        "Description": "Auxílio-reclusão"
    },
    {
        "Code": "7007",
        "Description": "Pensões especiais"
    },
    {
        "Code": "7008",
        "Description": "Complementação de aposentadoria/ pensão"
    },
    {
        "Code": "9200",
        "Description": "Desconto de adiantamentos"
    },
    {
        "Code": "9201",
        "Description": "Contribuição previdenciária"
    },
    {
        "Code": "9202",
        "Description": "Contribuição militar"
    },
    {
        "Code": "9203",
        "Description": "Imposto de Renda Retido na Fonte"
    },
    {
        "Code": "9205",
        "Description": "Provisão de contribuição previdenciária"
    },
    {
        "Code": "9207",
        "Description": "Faltas"
    },
    {
        "Code": "9208",
        "Description": "Atrasos"
    },
    {
        "Code": "9209",
        "Description": "Faltas ou atrasos"
    },
    {
        "Code": "9210",
        "Description": "DSR s/faltas e atrasos"
    },
    {
        "Code": "9211",
        "Description": "DSR sobre faltas"
    },
    {
        "Code": "9212",
        "Description": "DSR sobre atrasos"
    },
    {
        "Code": "9213",
        "Description": "Pensão alimentícia"
    },
    {
        "Code": "9214",
        "Description": "13° salário - Desconto de adiantamento"
    },
    {
        "Code": "9216",
        "Description": "Desconto de vale-transporte"
    },
    {
        "Code": "9217",
        "Description": "Contribuição a Outras Entidades e Fundos"
    },
    {
        "Code": "9218",
        "Description": "Retenções judiciais"
    },
    {
        "Code": "9219",
        "Description": "Desconto de assistência médica ou odontológica"
    },
    {
        "Code": "9220",
        "Description": "Alimentação - Desconto"
    },
    {
        "Code": "9221",
        "Description": "Desconto de férias"
    },
    {
        "Code": "9222",
        "Description": "Desconto de outros impostos e contribuições"
    },
    {
        "Code": "9223",
        "Description": "Previdência complementar - Parte do empregado"
    },
    {
        "Code": "9224",
        "Description": "FAPI - Parte do empregado"
    },
    {
        "Code": "9225",
        "Description": "Previdência complementar - Parte do servidor"
    },
    {
        "Code": "9226",
        "Description": "Desconto de férias - Abono"
    },
    {
        "Code": "9230",
        "Description": "Contribuição sindical laboral"
    },
    {
        "Code": "9231",
        "Description": "Mensalidade sindical ou associativa"
    },
    {
        "Code": "9232",
        "Description": "Contribuição sindical - Assistencial"
    },
    {
        "Code": "9233",
        "Description": "Contribuição sindical - Confederativa"
    },
    {
        "Code": "9240",
        "Description": "Alimentação concedida em pecúnia - Desconto"
    },
    {
        "Code": "9241",
        "Description": "Alimentação em ticket ou cartão, vinculada ao PAT - Desconto"
    },
    {
        "Code": "9242",
        "Description": "Alimentação em ticket ou cartão, não vinculada ao PAT - Desconto"
    },
    {
        "Code": "9243",
        "Description": "Cesta básica ou refeição, vinculada ao PAT - Desconto"
    },
    {
        "Code": "9244",
        "Description": "Cesta básica ou refeição, não vinculada ao PAT - Desconto"
    },
    {
        "Code": "9250",
        "Description": "Seguro de vida - Desconto"
    },
    {
        "Code": "9254",
        "Description": "Empréstimos consignados - Desconto"
    },
    {
        "Code": "9255",
        "Description": "Empréstimos do empregador - Desconto"
    },
    {
        "Code": "9258",
        "Description": "Convênios"
    },
    {
        "Code": "9260",
        "Description": "FIES - Desconto"
    },
    {
        "Code": "9270",
        "Description": "Danos e prejuízos causados pelo trabalhador"
    },
    {
        "Code": "9290",
        "Description": "Desconto de pagamento indevido em meses anteriores"
    },
    {
        "Code": "9291",
        "Description": "Abate-teto"
    },
    {
        "Code": "9292",
        "Description": "Ressarcimento ao erário"
    },
    {
        "Code": "9293",
        "Description": "Honorários advocatícios"
    },
    {
        "Code": "9294",
        "Description": "Redutor EC 41/03"
    },
    {
        "Code": "9299",
        "Description": "Outros descontos"
    },
    {
        "Code": "9901",
        "Description": "Base de cálculo da contribuição previdenciária"
    },
    {
        "Code": "9902",
        "Description": "Total da base de cálculo do FGTS"
    },
    {
        "Code": "9903",
        "Description": "Total da base de cálculo do IRRF"
    },
    {
        "Code": "9904",
        "Description": "Total da base de cálculo do FGTS rescisório"
    },
    {
        "Code": "9905",
        "Description": "Serviço militar"
    },
    {
        "Code": "9906",
        "Description": "Remuneração no exterior"
    },
    {
        "Code": "9907",
        "Description": "Total da contribuição da previdenciária patronal - RPPS"
    },
    {
        "Code": "9908",
        "Description": "FGTS - Depósito"
    },
    {
        "Code": "9910",
        "Description": "Seguros"
    },
    {
        "Code": "9911",
        "Description": "Assistência Médica"
    },
    {
        "Code": "9930",
        "Description": "Salário-maternidade pago pela Previdência Social"
    },
    {
        "Code": "9931",
        "Description": "Salário-maternidade pago pela Previdência Social - 13° salário"
    },
    {
        "Code": "9932",
        "Description": "Auxílio-doença acidentário"
    },
    {
        "Code": "9933",
        "Description": "Auxílio-doença"
    },
    {
        "Code": "9938",
        "Description": "Isenção IRRF - 65 anos"
    },
    {
        "Code": "9939",
        "Description": "Outros valores tributáveis"
    },
    {
        "Code": "9950",
        "Description": "Horas extraordinárias - Banco de horas"
    },
    {
        "Code": "9951",
        "Description": "Horas compensadas - Banco de horas"
    },
    {
        "Code": "9989",
        "Description": "Outros valores informativos"
    }
].sort((a, b) => a.Description - b.Description);