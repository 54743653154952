import { Menu } from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import iconWallet from '../../assets/icone_carteira.png';
import { AuthContext } from '../../auth/AuthContext';
import { signOut } from '../../services/login';
import { getFirstInitial } from '../../utils/string';
import itemMenu from './navBarItem';
import './styles.css';

const NavBar = ({ clickHamburguerMenu, menuOpen }) => {
  const { user, setUser, walletSelected } = useContext(AuthContext);
  const history = useHistory();

  const userActions = {
    LOGOUT: () => signOut(setUser),
    PROFILE: () => history.push('/profile')
  };

  const walletActions = {
    CARTEIRA: () => history.push('/')
  };

  const walletSelectedItem = ({ item }) => {
    const action = walletActions[item.key];

    if (action) action();
  };

  const userSelected = ({ item }) => {
    const action = userActions[item.key];

    if (action) action();
  };

  const itemRenderWallet = ({ item }) => itemMenu(item);

  const itemRenderUser = ({ item }) => itemMenu(item);

  const walletImage = walletSelected.UrlAvatar
    ? walletSelected.UrlAvatar
    : getFirstInitial(walletSelected.NomeCarteira);

  const walletAlt = 'Foto de perfil da carteira';
  let wallets = [];
  if (walletSelected) {
    wallets = [
      {
        text: walletSelected.NomeCarteira,
        image: walletImage,
        key: 'CARTEIRA',
        imageColor: walletSelected.CorPerfil,
        imageAlt: walletAlt,
        fillAll: true,
        items: [
          {
            text: walletSelected.NomeCarteira,
            image: walletImage,
            key: 'CARTEIRA',
            imageColor: walletSelected.CorPerfil,
            imageAlt: walletAlt,
            fillAll: true
          },
          {
            text: 'Gerenciar Carteira',
            image: iconWallet,
            imageColor: 'var(--primary-color)',
            imageAlt: 'Imagem ilustrativa de carteira'
            // TODO action on click
          }
        ]
      }
    ];
  }

  let userItem = [];
  if (user) {
    userItem = [
      {
        text: user.Nome,
        image: user.UrlAvatar ? user.UrlAvatar : getFirstInitial(user.Nome),
        imageColor: user.CorPerfil,
        imageAlt: 'Foto de perfil do usuário',
        fillAll: true,
        items: [
          {
            text: 'Meu perfil',
            key: 'PROFILE'
          },
          {
            text: 'Sair',
            key: 'LOGOUT'
          }
        ]
      }
    ];
  }

  return (
    <div className={`navbar-wrapper ${!menuOpen ? 'navbar-100' : ''}`}>
      <div
        id="controle-expansao-menu"
        onClick={() => clickHamburguerMenu()}
        onKeyPress={() => {
          // empty function
        }}
        role="button"
        tabIndex={0}
      >
        <span />
        <span />
        <span />
      </div>

      <nav>
        <Menu
          items={wallets}
          itemRender={itemRenderWallet}
          onSelect={walletSelectedItem}
          className="menu-item-wrapper"
        />

        <Menu
          items={userItem}
          itemRender={itemRenderUser}
          onSelect={userSelected}
          className="menu-item-wrapper"
        />
      </nav>
    </div>
  );
};

NavBar.propTypes = {
  clickHamburguerMenu: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired
};

export default NavBar;
