import PropTypes from 'prop-types';
import React from 'react';
import Popup from '../layout';

const PopUpConfirm = ({ onConfirm, visible, setVisible, title, message, onCancel }) => {
  const _message = message != null ? message : 'Você tem certeza que deseja realizar a operação?';
  return (
    <Popup
      title={title}
      width={700}
      visible={visible}
      setVisible={setVisible}
      confirmText="Sim"
      cancelText="Não"
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <span style={{ marginTop: '10px' }}>
        <div style={{ whiteSpace: 'pre-wrap' }}>{_message}</div>
      </span>
    </Popup>
  );
};

PopUpConfirm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpConfirm;
