import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Relacionamento/', METHOD.GET);

export const getByDate = (dateInverval) => axiosWalletRequest('Relacionamento/Filtrar', METHOD.POST, dateInverval);

export const getByClientId = (clientAddress) => axiosWalletRequest('Relacionamento/FiltrarPorIdPessoa', METHOD.POST, clientAddress);

export const getById = (id) => axiosWalletRequest(`Relacionamento/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Relacionamento', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Relacionamento/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Relacionamento/${id}`, METHOD.PUT, body);

export const getRelationshipNumber = async () => {
    const { data } = await axiosWalletRequest(`Relacionamento/ObterNumeroRelacionamento`, METHOD.GET);
    return data;
}

export const getLast = async () => {
    const { data } = await axiosWalletRequest(`Relacionamento/ObterUltimoRelacionamentoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const getFirst = async () => {
    const { data } = await axiosWalletRequest(`Relacionamento/ObterPrimeiroRelacionamentoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}
export const getPriorities = async () => await axiosWalletRequest(`Relacionamento/ListarPrioridade`, METHOD.GET);

export const getRelationshipTypes = async () => await axiosWalletRequest(`Relacionamento/ListarTipoRelacionamento`, METHOD.GET);

export const createRelationshipType = async (body) => await axiosWalletRequest('Relacionamento/CriarTipoRelacionamento', METHOD.POST, body);

export const updateRelationshipType = (body, id) => axiosWalletRequest(`Relacionamento/AtualizarTipoRelacionamento/${id}`, METHOD.PUT, body);

export const excludeRelationshipType = (id) => axiosWalletRequest(`Relacionamento/ExcluirTipoRelacionamento/${id}`, METHOD.DELETE);

export const getRelationshipStatuses = async () => await axiosWalletRequest(`Relacionamento/ListarSituacaoRelacionamento`, METHOD.GET);

export const createRelationshipStatus = async (body) => await axiosWalletRequest('Relacionamento/CriarSituacaoRelacionamento', METHOD.POST, body);

export const updateRelationshipStatus = (body, id) => axiosWalletRequest(`Relacionamento/AtualizarSituacaoRelacionamento/${id}`, METHOD.PUT, body);

export const excludeRelationshipStatus = (id) => axiosWalletRequest(`Relacionamento/ExcluirSituacaoRelacionamento/${id}`, METHOD.DELETE);

export const getRelationshipSources = async () => await axiosWalletRequest(`Relacionamento/ListarOrigemRelacionamento`, METHOD.GET);

export const createRelationshipSource = async (body) => await axiosWalletRequest('Relacionamento/CriarOrigemRelacionamento', METHOD.POST, body);

export const updateRelationshipSource = (body, id) => axiosWalletRequest(`Relacionamento/AtualizarOrigemRelacionamento/${id}`, METHOD.PUT, body);

export const excludeRelationshipSource = (id) => axiosWalletRequest(`Relacionamento/ExcluirOrigemRelacionamento/${id}`, METHOD.DELETE);

export const createRelationshipInteration = async (body) => await axiosWalletRequest('Relacionamento/CriarRelacionamentoInteracao', METHOD.POST, body);

export const updateRelationshipInteration = (body, id) => axiosWalletRequest(`Relacionamento/AtualizarRelacionamentoInteracao/${id}`, METHOD.PUT, body);

export const excludeRelationshipInteration = (id) => axiosWalletRequest(`Relacionamento/ExcluirRelacionamentoInteracao/${id}`, METHOD.DELETE);