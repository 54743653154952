import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import FormRow from '../../components/formRow';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import { successNotification, warningNotification } from '../../components/notification';
import * as proposalService from '../../services/proposal';
import LoadScreen from '../load';
import './styles.css';

const Proposals = () => {
  const history = useHistory();
  const [proposals, setProposals] = useState([]);
  const [proposalsSelectedsIds, setProposalsSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkBoxFiltrar, setCheckboxFiltrar] = useState(false);
  const [popUpConfirmVisibility, setPopUpConfirmVisibility] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');

  const columns = [
    {
      field: 'Nome',
      title: 'Nome do Padrão',
      columnMenuCheckbox: true
    },
    {
      field: 'Introducao',
      title: 'Introdução',
      columnMenu: true
    }
  ];

  const loadProposals = async () => {
    setLoading(true);
    const { data } = await proposalService.getAll();
    setProposals(data);
    setLoading(false);
  };

  useEffect(async () => {
    await loadProposals();
  }, []);

  const excludeProposals = async () => {
    const excludeProposalsRequests = proposalsSelectedsIds.map((proposalSelected) =>
      proposalService.exclude(proposalSelected)
    );

    const excludeProposalsResponses = await Promise.all(excludeProposalsRequests);

    const isAllRequestsRight = excludeProposalsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (isAllRequestsRight) successNotification('Proposta(s) excluída(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais propostas:'];
      excludeProposalsResponses.forEach((element) => {
        const { data } = element;
        erro.push(data.MensagemUsuario);
      });
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }

    setProposalsSelectedsIds([]);
    await loadProposals();
  };

  const onChangeCheckbox = () => {
    setCheckboxFiltrar(!checkBoxFiltrar);
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="proposal-list">
        <h1>Padrões de Propostas</h1>
        <FormRow justifyEnd withoutMarginTop space10>
          <div className="config-components-popup-button">
            <FloatingActionButton icon={INTERNAL_BUTTON.BUTTON_FILTER} onClick={onChangeCheckbox} />
          </div>
          <PopUpButton
            url="/proposals/proposalRegistration"
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
          />
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (proposalsSelectedsIds.length > 0) setPopUpConfirmVisibility(true);
                  else warningNotification('Nenhuma proposta selecionada');
                },
                text: 'Excluir propostas'
              }
            ]}
          />
        </FormRow>

        <Table
          name="Proposals"
          data={proposals}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setProposalsSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => history.push(`proposals/proposalRegistration/${Id}`)}
          sortable={true}
          filterable={checkBoxFiltrar}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeProposals}
        visible={popUpConfirmVisibility}
        setVisible={setPopUpConfirmVisibility}
        title="Confirmação"
        message="Você tem certeza que deseja excluir a(s) proposta(s) selecionada(s)?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title="Erro ao excluir uma proposta"
        message={popUpErrorMessage}
      />
    </>
  );
};

export default Proposals;
