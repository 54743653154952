/* eslint prefer-destructuring: 0 */
import React, { useState, useEffect } from 'react';
import FormFieldDrop from '../formField/drop';
import GenericField from '../formField/genericField';
import FormRow from '../formRow';
import * as personService from '../../services/person';
import LoadScreen from '../../view/load';
import './styles.css';

const Client = ({
  client,
  setClient,
  cpfCnpj,
  setCpfCpnj,
  address,
  setAddress,
  addresses,
  setAddresses,
  contact,
  setContact,
  contacts,
  setContacts,
  afterClientSelection,
  disabled
}) => {
  const [phone, setPhone] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [loadingDiv, setLoadingDiv] = useState(false);

  const updateClientDetails = async (client, address, contact) => {
    let newAddress = address;
    let newContact = null;
    if (client != null) {
      setCpfCpnj('');
      setPhone('');
      setCellPhone('');
      setAddress(null);
      if (client.TipoPessoa === 0) {
        setCpfCpnj(client.CPF);
        setAddress(client.Endereco);
        newAddress = client.Endereco;
        setPhone(client.Telefone?.Numero);
        setCellPhone(client.Celular?.Numero);
        newContact = { Nome: null, Email: client.Email };
        setContact(contact);
        setContacts(null);
      } else {
        newContact = { Nome: null, Email: client.Email };
        setContact(contact);
        let addresses = [];
        if (client.EnderecoPrincipal != null) addresses.push(client.EnderecoPrincipal);
        addresses = addresses.concat(client.OutrosEnderecos);
        addresses = addresses.map((item) => ({
          ...item,
          EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
            .filter(Boolean)
            .join(', ')
        }));
        if (newAddress != null) {
          const telefone = newAddress.Telefones?.find((x) => x.Descricao === 'Telefone');
          const celular = newAddress.Telefones?.find((x) => x.Descricao === 'Celular');
          setPhone(telefone?.Numero);
          setCellPhone(celular?.Numero);
          setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
          setAddress(newAddress);
        } else if (addresses.length === 1) {
          const telefone = addresses[0].Telefones?.find((x) => x.Descricao === 'Telefone');
          const celular = addresses[0].Telefones?.find((x) => x.Descricao === 'Celular');
          setPhone(telefone?.Numero);
          setCellPhone(celular?.Numero);
          setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
          setAddress(addresses[0]);
          newAddress = addresses[0];
        }
        setAddresses(addresses);
        const contacts = [];
        if (client.Contatos?.length > 0) {
          client.Contatos.forEach((item) => {
            contacts.push(item);
          });
        }
        if (addresses?.length > 0) {
          addresses.forEach((item1) => {
            if (item1.Contatos?.length > 0) {
              item1.Contatos.forEach((item2) => {
                contacts.push(item2);
              });
            }
          });
        }
        contacts.sort();
        if (!contacts) {
          newContact = { Nome: null, Email: client.Email };
          setContact(newContact);
          setContacts(null);
        } else if (contacts.length > 0) {
          const contato = contacts.find((x) => x.Id === contact?.Id);
          if (contato) {
            setContact(contato);
            newContact = contato;
          }
          contacts.sort((a, b) => {
            if (a.Nome < b.Nome) {
              return -1;
            }
            if (a.Nome > b.Nome) {
              return 1;
            }
            return 0;
          });
          setContacts(contacts);
        }
      }
      return {
        address: newAddress,
        contact: newContact
      };
    }
    setCpfCpnj('');
    setPhone('');
    setCellPhone('');
    setAddress(null);
    setAddresses([]);
    setContact(null);
    setContacts(null);
    return null;
  };

  const loadClient = async (client) => {
    setLoadingDiv(true);
    setClient(client);
    const addressContact = await updateClientDetails(client, null, null);
    if (afterClientSelection)
      afterClientSelection(client, addressContact.address, addressContact.contact);
    setLoadingDiv(false);
  };

  const loadClients = async (filter) => {
    const { data } = await personService.getByName(filter);
    return data;
  };

  useEffect(async () => {
    await updateClientDetails(client, address, contact);
  }, []);

  return (
    <div>
      {loadingDiv ? (
        <LoadScreen />
      ) : (
        <>
          <FormRow withShrink>
            <div className="fill-66-field-margin">
              <FormFieldDrop
                titleLabel="Cliente"
                defaultValueSelect="Procure o cliente..."
                onChangeValue={async (val) => {
                  if (val.value) {
                    await loadClient(val.value);
                  } else {
                    setClient(null);
                    updateClientDetails(null);
                    if (afterClientSelection) afterClientSelection(null, null, null);
                  }
                }}
                value={client}
                className="fill-100-field"
                loadData={loadClients}
                serverFiltering={true}
                dataItemKey="Id"
                textField="Nome"
                required
                disabled={disabled}
              />
            </div>
            <div className="fill-33-field-margin">
              <GenericField
                titleLabel={client?.TipoPessoa !== 0 ? 'CNPJ' : 'CPF'}
                value={cpfCnpj}
                classNameWrapper="fill-100-field"
                disabled
              />
            </div>
          </FormRow>

          {client != null && client.TipoPessoa !== 0 && (
            <FormRow withShrink>
              <FormFieldDrop
                titleLabel="Matriz/Filial"
                defaultValueSelect="Selecione..."
                onChangeValue={(val) => {
                  if (val.value) {
                    updateClientDetails(client, val.value);
                    if (afterClientSelection) afterClientSelection(client, val.value, null);
                  }
                }}
                value={address}
                className="fill-100-field"
                infosSelect={addresses}
                dataItemKey="Id"
                textField="EnderecoCompleto"
                disabled={disabled}
              />
            </FormRow>
          )}

          <FormRow>
            <div className="fill-33-field-margin">
              <FormRow withoutMarginTop>
                <GenericField
                  titleLabel="Rua/Avenida"
                  value={address?.Logradouro}
                  classNameWrapper="fill-70-field"
                  disabled
                />
                <GenericField
                  titleLabel="Numero"
                  value={address?.Numero}
                  classNameWrapper="fill-30-field"
                  disabled
                />
              </FormRow>
            </div>
            <div className="fill-33-field-margin">
              <FormRow withoutMarginTop>
                <GenericField
                  titleLabel="Complemento"
                  value={address?.Complemento}
                  classNameWrapper="fill-30-field"
                  disabled
                />
                <GenericField
                  titleLabel="Bairro"
                  value={address?.Bairro}
                  classNameWrapper="fill-70-field"
                  disabled
                />
              </FormRow>
            </div>
            <div className="fill-33-field-margin">
              <FormRow withoutMarginTop>
                <GenericField
                  titleLabel="Cidade"
                  value={
                    address?.MunicipioNaoCadastrado
                      ? address?.MunicipioNaoCadastrado
                      : address?.Municipio?.nomeMunicipio
                  }
                  classNameWrapper={
                    address?.MunicipioNaoCadastrado ? 'fill-50-field' : 'fill-70-field'
                  }
                  disabled
                />
                <GenericField
                  titleLabel={address?.EstadoNaoCadastrado ? 'Estado' : 'UF'}
                  value={
                    address?.EstadoNaoCadastrado
                      ? address?.EstadoNaoCadastrado
                      : address?.Municipio?.siglaEstado
                  }
                  classNameWrapper={
                    address?.EstadoNaoCadastrado ? 'fill-50-field' : 'fill-30-field'
                  }
                  disabled
                />
              </FormRow>
            </div>
          </FormRow>
          <FormRow>
            <GenericField
              titleLabel="Telefone"
              value={phone}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
            <GenericField
              titleLabel="Celular"
              value={cellPhone}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
            <GenericField
              titleLabel="E-mail"
              value={client?.Email}
              classNameWrapper="fill-33-field-margin"
              disabled
            />
          </FormRow>
          {contacts ? (
            <FormRow>
              <FormFieldDrop
                titleLabel="Contato"
                defaultValueSelect="Selecione..."
                value={contact}
                onChangeValue={(val) => {
                  setContact(val.value);
                  if (afterClientSelection) afterClientSelection(client, address, val.value);
                }}
                className="fill-33-field-margin"
                infosSelect={contacts}
                dataItemKey="Id"
                textField="Nome"
                disabled={disabled}
              />
              <div className="fill-33-field-margin">
                <FormRow withoutMarginTop>
                  <GenericField
                    titleLabel="Telefone"
                    value={contact?.Telefone?.Numero}
                    classNameWrapper="fill-50-field"
                    disabled
                  />
                  <GenericField
                    titleLabel="Celular"
                    value={contact?.Celular?.Numero}
                    classNameWrapper="fill-50-field"
                    disabled
                  />
                </FormRow>
              </div>
              <GenericField
                titleLabel="E-mail"
                value={contact?.Email}
                classNameWrapper="fill-33-field-margin"
                disabled
              />
            </FormRow>
          ) : (
            <FormRow>
              <GenericField
                titleLabel="Contato"
                onChangeValue={(val) => {
                  setContact({ Nome: val.value });
                }}
                value={contact?.Nome}
                classNameWrapper="fill-50-field"
                disabled={disabled}
              />
            </FormRow>
          )}
        </>
      )}
    </div>
  );
};

export default Client;
