import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import { CalendarWithButtons, DateFilter } from '../../components/dateComponents';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../load';
import * as expenseService from '../../services/expense';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../components/notification';
import { FirstDayOfMonth, LastDayOfMonth, DateNow } from '../../utils/date';
import PopUpUploadFile from '../../components/popUps/popUpUploadFile';
import PopUpUploadFileOrLink from '../../components/popUps/popUpUploadFileOrLink';
import PopUpFiles from '../../components/popUps/popUpFiles';
import PopUpInvoice from '../../components/popUps/popUpInvoice';
import PopUpSettings from '../../components/popUps/popUpSettings';
import { saveByteArray } from '../../utils/download';

const Expenses = () => {
  const [reload, setReload] = useState(0);
  const { id } = useParams();
  const history = useHistory();
  const [expenses, setExpenses] = useState([]);
  const [expensesSelectedsIds, setExpensesSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [popUpUploadFile, setPopUpUploadFile] = useState(false);
  const [popUpConfirmTaker, setPopUpConfirmMaker] = useState(false);
  const [file, setFile] = useState(null);
  const [popUpFile, setPopUpFile] = useState(false);
  const [idAttach, setIdAttach] = useState(null);
  const [popUpInvoice, setPopUpInvoice] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [nsu, setNsu] = useState(1);
  const [popUpSettings, setPopUpSettings] = useState(false);
  const filterDateLocal1 = window.localStorage.getItem('EXPENSE_FILTER_DATE1');
  const filterDateLocal2 = window.localStorage.getItem('EXPENSE_FILTER_DATE2');
  const dateNow = DateNow();
  let filterDate = { date1: FirstDayOfMonth(dateNow), date2: LastDayOfMonth(dateNow) };
  if (filterDateLocal1 && id)
    filterDate = { date1: new Date(filterDateLocal1), date2: new Date(filterDateLocal2) };
  const [date, setDate] = useState(filterDate);
  const filterTypeLocal = window.localStorage.getItem('EXPENSE_FILTER_TYPE');
  const [filterType, setFilterType] = useState(filterTypeLocal || 'month');
  const [popUpUploadCoupon, setPopUpUploadCoupon] = useState(false);
  const [popUpExportExcel, setPopUpExportExcel] = useState(false);

  window.localStorage.setItem('EXPENSE_FILTER_DATE1', date.date1);
  window.localStorage.setItem('EXPENSE_FILTER_DATE2', date.date2);
  window.localStorage.setItem('EXPENSE_FILTER_TYPE', filterType);

  const columns = [
    {
      field: 'TipoDespesaNome',
      title: 'Tipo',
      width: 150,
      columnMenuCheckbox: true
    },
    {
      field: 'Id',
      title: 'Anexos',
      width: 90,
      cell: (props) => {
        let td = null;
        const cellClick = {
          onClick: () => {
            setIdAttach(props.dataItem.Id);
            setPopUpFile(true);
          }
        };
        if (props.dataItem.PossuiAnexo) {
          td = (
            <td style={{ textAlign: 'center' }}>
              <button type="button" className="general-button" {...cellClick}>
                <span className="k-icon k-font-icon k-i-attachment" />
              </button>
            </td>
          );
        } else {
          td = <td style={{ textAlign: 'center' }} {...cellClick} />;
        }
        return td;
      }
    },
    {
      field: 'Data',
      title: 'Data de Emissão',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'DataVencimento',
      title: 'Data de Venc.',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'Numero',
      title: 'Núm.',
      width: 80,
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 350,
      columnMenuCheckbox: true
    },
    {
      field: 'Valor',
      title: 'Valor (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      columnMenu: true,
      totalCell: true
    },
    {
      field: 'ValorLiquido',
      title: 'Valor Líquido (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      columnMenu: true,
      totalCell: true
    }
  ];

  const seekScroll = async (data) => {
    if (idAttach && idAttach !== '0') {
      const index = data.findIndex((item) => item.Id === idAttach);
      if (index >= 0) {
        setRowIndex(index);
        return;
      }
    }
    if (data.length > 0) setRowIndex(data.length - 1);
    else setRowIndex(0);
  };

  const fetchExpenses = async () => {
    setLoading(true);
    const dateInterval = {
      DataInicio: date.date1,
      DataFim: date.date2
    };
    const response = await expenseService.getByDate(dateInterval);
    let { data } = response;
    if (response.status === 200) {
      data = data.map((r) => {
        const due = r.TipoPagamento === 1 ? new Date(r.DataVencimento) : 'Parcelado';
        return {
          ...r,
          Data: new Date(r.Data),
          DataVencimento: due
        };
      });
      setExpenses(data);
      seekScroll(data);
      setExpensesSelectedsIds([]);
    } else if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
    else errorNotification('Houve um erro ao tentar carregar os documentos recebidos.');
    setLoading(false);
  };

  const excludeExpenses = async () => {
    setLoading(true);
    const excludeExpensesRequests = expensesSelectedsIds.map((expenseSelected) =>
      expenseService.exclude(expenseSelected)
    );

    const excludeExpensesResponses = await Promise.all(excludeExpensesRequests);

    const isAllRequestsRight = excludeExpensesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    setPopUpConfirmDelete(false);
    if (isAllRequestsRight) successNotification('Documento(s) excluído(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais documentos:'];
      excludeExpensesResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchExpenses();
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const response = await expenseService.uploadFile(file);
    const { data } = response;
    setLoading(false);
    if (response.status === 200) {
      successNotification('Arquivo importado com sucesso!');
      if (data) history.push(`/expenses/expenseRegistration/${data}`);
      else await fetchExpenses();
    } else if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
    else errorNotification(data);
  };

  const validateTake = async (file) => {
    const response = await expenseService.validateTaker(file);
    const { data } = response;
    if (data) uploadFile(file);
    else {
      setPopUpConfirmMaker(true);
      setFile(file);
    }
  };

  const uploadFileConfirm = async () => {
    setLoading(true);
    const response = await expenseService.uploadFile(file);
    const { data } = response;
    if (response.status === 200) {
      successNotification('Arquivo importado com sucesso!');
      if (data) history.push(`/expenses/expenseRegistration/${data}`);
      else await fetchExpenses();
    } else if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
    else errorNotification(data);
    setLoading(false);
  };

  const uploadCoupon = async (file, link) => {
    setLoading(true);
    let response = null;
    if (file) response = await expenseService.importCouponFile(file);
    else response = await expenseService.importCouponLink(link);
    const { data } = response;
    setLoading(false);
    if (response.status === 200) {
      successNotification('Arquivo importado com sucesso!');
      if (data) history.push(`/expenses/expenseRegistration/${data}`);
      else await fetchExpenses();
    } else if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
    else errorNotification(data);
  };

  const loadInvoices = async () => {
    setLoading(true);
    const getInvoiceRequest = await expenseService.getInvoice();
    const [response] = await Promise.all([getInvoiceRequest]);
    if (response.status === 200) {
      const { data } = response;
      const invoices = data.map((item) => ({
        ...item,
        Data: new Date(item.Data)
      }));
      setInvoices(invoices);
      if (invoices.length > 0) setNsu(invoices[0].NSU);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar obter notas fiscais.');
    }
    setLoading(false);
  };

  const afterImportInvoice = async () => {
    await fetchExpenses();
  };

  const exportExcel = async () => {
    const dateInterval = {
      DataInicio: FirstDayOfMonth(date.date1),
      DataFim: LastDayOfMonth(date.date2)
    };
    const exportExcelResponse = await expenseService.exportExcel(dateInterval);
    if (exportExcelResponse.status === 200) {
      const base64 = exportExcelResponse.data;
      const url = saveByteArray('DocumentosRecebidos.xlsx', base64, 'xlsx');
    } else {
      let erro = '';
      const { data } = exportExcelResponse;
      if (data.MensagemUsuario) erro = data.MensagemUsuario;
      else erro = data;
      setPopUpErrorTitle('Erro ao Exportar para Excel');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  useEffect(async () => {
    setLoading(true);
    await fetchExpenses();
    setLoading(false);
  }, [date, reload]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="expense-list">
        <h1>Documentos Recebidos</h1>

        <div className="buttons-expense-list">
          {filterType === 'month' && (
            <CalendarWithButtons
              date={date.date1}
              setDate={(val) => {
                const date1 = FirstDayOfMonth(val);
                const date2 = LastDayOfMonth(val);
                setDate({ date1, date2 });
                window.localStorage.setItem('EXPENSE_FILTER_DATE1', date1);
                window.localStorage.setItem('EXPENSE_FILTER_DATE2', date2);
              }}
              service={expenseService}
            />
          )}
          {filterType === 'day' && (
            <DateFilter
              date1={date.date1}
              date2={date.date2}
              setDate={(date1, date2) => {
                setDate({ date1, date2 });
                window.localStorage.setItem('EXPENSE_FILTER_DATE1', date1);
                window.localStorage.setItem('EXPENSE_FILTER_DATE2', date2);
              }}
            />
          )}
          <div className="config-components-popup-button">
            <FloatingActionButton
              icon={INTERNAL_BUTTON.BUTTON_REFRESH}
              onClick={() => {
                setReload((prev) => prev + 1);
              }}
            />
          </div>
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            internalItems={[
              {
                id: INTERNAL_ITEMS.IMPORTAR_CUPOM,
                onClick: () => {
                  setPopUpUploadCoupon(true);
                },
                text: 'Importar Cupom'
              },
              {
                id: INTERNAL_ITEMS.IMPORTAR,
                onClick: () => {
                  setPopUpUploadFile(true);
                },
                text: 'Importar XML de NFe e NFSe Nacional'
              },
              {
                id: INTERNAL_ITEMS.ADICIONAR,
                onClick: () => {
                  history.push(`/expenses/expenseRegistration/0`);
                },
                text: 'Novo documento em branco'
              }
            ]}
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (expensesSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Excluir Documento'
              },
              {
                id: INTERNAL_ITEMS.CONECTAR,
                onClick: async () => {
                  await loadInvoices();
                  setPopUpInvoice(true);
                },
                text: 'Consultar notas emitidas contra sua empresa'
              },
              {
                id: INTERNAL_ITEMS.EXPORTAR_EXCEL,
                onClick: () => {
                  setPopUpExportExcel(true);
                },
                text: 'Exportar Excel'
              },
              {
                id: INTERNAL_ITEMS.CONFIGURAR,
                onClick: () => setPopUpSettings(true),
                text: 'Configurar Tela'
              }
            ]}
          />
        </div>
        <Table
          name="Expenses"
          data={expenses}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setExpensesSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/expenses/expenseRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeExpenses}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirmação"
        message="Você tem certeza que deseja excluir o(s) documentos(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={uploadFileConfirm}
        visible={popUpConfirmTaker}
        setVisible={setPopUpConfirmMaker}
        title="Confirmação"
        message="O tomador da NF é diferente da empresa cadastrada no NEXTBusiness. Desejar importar a NF?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpUploadFile
        visible={popUpUploadFile}
        setVisible={setPopUpUploadFile}
        title="Importar XML do documento fiscal"
        allowedExtensions={['xml']}
        uploadFile={validateTake}
      />
      <PopUpFiles
        visible={popUpFile}
        setVisible={setPopUpFile}
        title="Anexos"
        id={idAttach}
        afterAttaching={async () => {
          setIdAttach(null);
          await fetchExpenses();
        }}
        service={expenseService}
      />
      <PopUpInvoice
        onConfirm={afterImportInvoice}
        visible={popUpInvoice}
        setVisible={setPopUpInvoice}
        title="Consultar notas emitidas contra sua empresa"
        invoices={invoices}
        nsu={nsu}
      />
      <PopUpSettings
        onConfirm={(val) => {
          setFilterType(val);
          window.localStorage.setItem('EXPENSE_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Preferências"
        filter={filterType}
      />
      <PopUpUploadFileOrLink
        visible={popUpUploadCoupon}
        setVisible={setPopUpUploadCoupon}
        title="Importar arquivo de imagem do cupom"
        allowedExtensions={['jpg', 'png', 'jpeg']}
        uploadFile={uploadCoupon}
      />
      <PopUpConfirm
        onConfirm={exportExcel}
        visible={popUpExportExcel}
        setVisible={setPopUpExportExcel}
        title="Confirmação"
        message="Você tem certeza que deseja exportar os documentos listados?"
      />
    </>
  );
};

export default Expenses;
