import { useState, useEffect } from 'react';
import Popup from '../layout';
import Table from '../../table';
import { invoiceIssuedStatus } from '../../../utils/status';
import { successNotification, errorNotification } from '../../notification';
import { OrangeButton } from '../../buttonsComponent';
import FormRow from '../../formRow';
import GenericField from '../../formField/genericField';
import LoadScreen from '../../../view/load';
import * as expenseService from '../../../services/expense';
import PopUpError from '../popUpError';

const PopUpInvoice = ({ onConfirm, visible, setVisible, title, invoices, nsu }) => {
  const [_invoices, setInvoices] = useState(invoices);
  const [selectedsIds, setSelectedsIds] = useState([]);
  const [_nsu, setNsu] = useState(nsu);
  const [loading, setLoading] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const columns = [
    {
      field: 'Importada',
      title: 'Rec.',
      width: 60,
      cell: (props) => {
        const td = invoiceIssuedStatus(props.dataItem.Importada);
        return td;
      }
    },
    {
      field: 'Chave',
      title: 'Chave NFe',
      width: 100
    },
    {
      field: 'Nome',
      title: 'Nome',
      width: 300
    },
    {
      field: 'Data',
      title: 'Data',
      width: 110
    },
    {
      field: 'Valor',
      title: 'Valor',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}'
    },
    {
      field: 'NSU',
      title: 'NSU',
      filter: 'numeric',
      format: '{0:c}'
    }
  ];

  const consultInvoice = async () => {
    setLoadingDiv(true);
    const consultInvoiceRequest = expenseService.consultInvoice(_nsu);
    const [response] = await Promise.all([consultInvoiceRequest]);
    if (response.status === 200) {
      const { data } = response;
      const invoices = data.map((item) => ({
        ...item,
        Data: new Date(item.Data)
      }));
      setInvoices(invoices);
    } else {
      const { data } = response;
      let erro = '';
      if (data.MensagemUsuario !== undefined) erro = data.MensagemUsuario;
      else erro = 'Houve um erro ao tentar consultar notas fiscais.';
      setPopUpErrorTitle('Consultar Nota Fiscal');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    setLoadingDiv(false);
  };

  const importInvoice = async () => {
    setLoadingDiv(true);

    if (selectedsIds.length > 0) {
      const importInvoicesRequests = selectedsIds.map((id) => expenseService.importInvoice(id));
      const importInvoicesResponses = await Promise.all(importInvoicesRequests);
      const isAllRequestsRight = importInvoicesResponses.reduce(
        (acc, currentResponse) => acc && currentResponse.status === 200,
        true
      );

      if (isAllRequestsRight) {
        successNotification('Notas(s) fiscai(s) importadas(s) com sucesso!');
        if (onConfirm) onConfirm();
        setVisible(false);
      } else {
        const erro = ['Houve um erro ao tentar importar uma ou mais notas fiscais:'];
        importInvoicesResponses.forEach((element) => {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        });
        setPopUpErrorTitle('Importar Nota Fiscal');
        setPopUpErrorMessage(erro);
        setPopUpError(true);
      }
    } else {
      const erro = 'Nenhuma nota fiscal foi importada!';
      setPopUpErrorTitle('Importar Nota Fiscal');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    setSelectedsIds([]);
    setLoadingDiv(false);
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <Popup
      title={title}
      width={900}
      visible={visible}
      setVisible={setVisible}
      haveConfirm={false}
      cancelText="Cancelar"
      onOthers={[consultInvoice, importInvoice]}
      othersText={['Consultar Notas', 'Importar Notas']}
    >
      <>
        {/* <FormRow withShrink>
          <GenericField
            titleLabel="Último NSU consultado"
            valueInput={_nsu}
            onChangeValue={(e) => {
              const {
                target: { name, valueAsNumber }
              } = e;
              setNsu(valueAsNumber);
            }}
            name="nsu"
            required
            typeNumber
            min="0"
            max="999999999999999"
          />
          <div className="side-button">
            <OrangeButton
              onClick={() => {
                consultInvoice();
              }}
            >
              Consultar Notas
            </OrangeButton>
          </div>
        </FormRow> */}
        {loadingDiv ? (
          <LoadScreen />
        ) : (
          <Table
            data={_invoices}
            columns={columns}
            dataItemKey="Id"
            haveCheckbox
            setItemKeysSelected={setSelectedsIds}
            style={{ minHeight: 400, marginTop: 20, height: 400 }}
            sortable={true}
            onClickInRow={() => {
              // empty
            }}
          />
        )}
        <FormRow>
          <h3 style={{ color: 'red', fontWeight: 'bold' }}>
            ATENÇÃO: Sucessivas consultas de notas podem gerar consumo indevido e bloquear futuras
            consultas por 1 hora.
          </h3>
        </FormRow>
        <PopUpError
          visible={popUpError}
          setVisible={setPopUpError}
          title={popUpErrorTitle}
          message={popUpErrorMessage}
        />
      </>
    </Popup>
  );
};

export default PopUpInvoice;
