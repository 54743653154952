/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { OrangeButton, SimpleGrayButton, WhiteButton } from '../../components/buttonsComponent';
import { FormFieldDate, FormFieldDrop, FormFieldGeneric } from '../../components/formField';
import { useForms } from '../../hooks';
import Photo from '../../components/photo';
import FormRow from '../../components/formRow';
import LoadScreen from '../load';
import { PopupDeleteUser } from '../../components/popUps';
import ToggleInvoice from '../../components/toggleInvoice';
import {
  citiesFromState,
  statesFromCountry,
  getCountries,
  municipioFromCEP
} from '../../services/address';
import {
  recoverCompleteUser,
  resetUserAvatar,
  updateUser,
  updateAvatar
} from '../../services/user';
import { filterEmptyValues } from '../../utils/object';
import { getFirstInitial, isEmptyValue, stringToCnpj } from '../../utils/string';
import {
  defaultErrorNotification,
  errorNotification,
  successNotification,
  warningNotification
} from '../../components/notification';
import { DateNow } from '../../utils/date';

const PERSON = {
  PF: 'Pessoa Física',
  PJ: 'Pessoa Jurídica'
};

const PERSON_ARRAY = Object.values(PERSON);

const initialForm = {
  Email: '',
  Nome: '',
  Telefone: '',
  IdPais: { Descricao: 'Brasil', Id: 'zb' },
  Logradouro: '',
  Numero: '',
  Bairro: '',
  Complemento: '',
  CodigoPostal: '',
  MunicipioNaoCadastrado: '',
  EstadoNaoCadastrado: '',

  CPF: '',
  RG: '',
  DataNascimento: null,

  CNPJ: '',
  InscricaoEstadual: '',
  InscricaoMunicipal: '',

  person: PERSON.PF,

  Municipio: {}
};

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cities, setCities] = useState([]);
  const [disabled, setDisabled] = useState({
    estado: true,
    city: true
  });
  const [photo, setPhoto] = useState();

  const [popupVisibilities, setPopupVisibilities] = useState({
    chancePass: false,
    deleteUser: false
  });

  // TODO alterar isso pra o useForm
  const updatePopupProperty = (key, value) => {
    const newObj = {
      ...popupVisibilities,
      [key]: value
    };

    setPopupVisibilities(newObj);
  };

  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);

  const updateForm = (data) => {
    setForm({
      ...form,
      ...data
    });
  };

  // TODO alterar isso pra o useForm
  const updatePropertie = (key, value) => {
    const newObj = {
      ...form, // pegando todo array de objetos mas sem ler ele, pegar apenas os indices
      [key]: value // adicionando uma key a essse objeto
    };

    setForm(newObj);
  };

  const findCountryById = (id) => (id ? countries.find((country) => country.Id === id) : '');

  const getMunicipio = async (idPais, CEP) => {
    try {
      if (idPais === 'zb' && CEP.length === 8) {
        setCities(['cep']);
        return (await municipioFromCEP(CEP)).data.Municipio;
      }
    } catch (error) {
      warningNotification('CEP não encontrado!');
    }

    return {};
  };

  const loadEstados = async (idPais) => {
    setLoading(true);

    const { data } = await statesFromCountry(idPais);
    setEstados(data);

    if (data.length === 0) {
      setDisabled({ estado: false, city: false });
      setCities([]);
    } else setDisabled({ estado: false, city: true });

    setLoading(false);
  };

  const loadCities = async (idEstado) => {
    setLoading(true);

    const { data } = await citiesFromState(idEstado);
    setCities(data);

    setDisabled({ estado: false, city: false });

    setLoading(false);
  };

  const clearPFProperties = {
    CPF: '',
    RG: '',
    DataNascimento: null
  };

  const clearPJProperties = {
    CNPJ: '',
    InscricaoEstadual: '',
    InscricaoMunicipal: ''
  };

  useEffect(async () => {
    setLoading(true);
    setCountries((await getCountries()).data);
    setLoading(false);
  }, []);

  useEffect(async () => {
    setLoading(true);
    const { data } = await recoverCompleteUser();

    const { Endereco: endereco, PessoaFisica: pessoaFisica, PessoaJuridica: pessoaJuridica } = data;

    const idPais = endereco?.IdPais;

    const Municipio = await getMunicipio(idPais, endereco?.CodigoPostal);
    const DataNascimento = pessoaFisica?.Nascimento ? new Date(pessoaFisica.Nascimento) : DateNow();

    updateForm({
      person: pessoaJuridica === null ? PERSON.PF : PERSON.PJ,
      Nome: data.Nome,
      Email: data.Email,
      Telefone: data.Telefone,
      IdPais: findCountryById(idPais),
      CodigoPostal: endereco?.CodigoPostal || '',
      Numero: endereco?.Numero,
      Municipio,
      Logradouro: endereco?.Logradouro,
      Bairro: endereco?.Bairro,
      Complemento: endereco?.Complemento,
      MunicipioNaoCadastrado: endereco?.MunicipioNaoCadastrado,
      EstadoNaoCadastrado: endereco?.EstadoNaoCadastrado,

      CPF: pessoaFisica?.CPF,
      RG: pessoaFisica?.RG,
      DataNascimento,

      CNPJ: pessoaJuridica?.CNPJ,
      InscricaoEstadual: pessoaJuridica?.InscricaoEstadual,
      InscricaoMunicipal: pessoaJuridica?.InscricaoMunicipal
    });

    if (!isEmptyValue(idPais)) loadEstados(idPais);
    setLoading(false);
  }, [countries]);

  const submitForm = async (event) => {
    event.preventDefault();

    setLoading(true);

    const userBodyForm = filterEmptyValues({
      ...form,
      IdPais: form.IdPais.Id
    });

    const updateUserPromise = updateUser(userBodyForm);
    const updateAvatarPromise = photo
      ? updateAvatar(photo.binary, photo.type)
      : new Promise((resolve) => resolve(null));

    const [updateAvatarResponse, updateUserResponse] = await Promise.all([
      updateAvatarPromise,
      updateUserPromise
    ]);

    if (photo) {
      const { data: UrlAvatar } = updateAvatarResponse;
      setUser({
        ...user,
        UrlAvatar
      });
    }

    if (updateUserResponse.status === 200) successNotification('Usuário atualizado!');
    else defaultErrorNotification();

    setLoading(false);
  };

  const searchCEP = async (id) => {
    setLoading(true);

    try {
      const { data } = await municipioFromCEP(id);

      if (data.Logradouro === null) throw Error('CEP não encontrado');

      setDisabled({ estado: true, city: true });
      setCities(['cep']);

      updateForm({
        Bairro: data.Bairro,
        Logradouro: data.Logradouro,
        Municipio: data.Municipio,
        CodigoPostal: data.CodigoPostal
      });
    } catch (error) {
      errorNotification(error.message);
    }

    setLoading(false);
  };

  const removeAvatarUser = async () => {
    setLoading(true);

    await resetUserAvatar();
    setUser({
      ...user,
      UrlAvatar: null
    });

    setLoading(false);
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <div className="container-page">
      <h1>Perfil</h1>

      <form onSubmit={submitForm}>
        <ToggleInvoice title="Dados" startExpanded>
          <FormRow>
            <Photo
              backgroundColor={user.CorPerfil}
              removeAvatar={removeAvatarUser}
              textAvatar={getFirstInitial(user.Nome)}
              imageSrc={user.UrlAvatar}
              imageAlt="Foto de perfil do usuário"
              setImageData={setPhoto}
            />
          </FormRow>

          <FormRow>
            <FormRow withoutMarginTop halfSize>
              <FormFieldDrop
                titleLabel=""
                onChangeValue={(event) => {
                  let clearProps;

                  if (event.value === PERSON.PF) clearProps = clearPJProperties;
                  else clearProps = clearPFProperties;

                  updateForm({ person: event.value, ...clearProps });
                }}
                infosSelect={PERSON_ARRAY}
                value={form.person}
                className="fill-40-field"
              />

              <FormFieldGeneric
                titleLabel="Nome"
                onChangeValue={(event) => {
                  updatePropertie('Nome', event.value);
                }}
                value={form.Nome}
                classNameWrapper="fill-100-field"
                required
              />
            </FormRow>

            <FormRow withoutMarginTop halfSize>
              <FormFieldGeneric
                titleLabel="Email"
                onChangeValue={(event) => {
                  updatePropertie('Email', event.value);
                }}
                value={form.Email}
                disabled
                classNameWrapper="fill-50-field"
                required
              />

              <FormFieldGeneric
                titleLabel="Telefone"
                onChangeValue={(event) => {
                  updatePropertie('Telefone', event.value);
                }}
                value={form.Telefone}
                classNameWrapper="fill-50-field"
                required
              />
            </FormRow>
          </FormRow>

          <FormRow>
            <FormRow withoutMarginTop halfSize>
              <FormFieldDrop
                titleLabel="Pais"
                onChangeValue={(event) => {
                  updatePropertie('IdPais', event.value);
                  loadEstados(event.value.Id);
                }}
                infosSelect={countries}
                value={form.IdPais}
                textField="Descricao"
                dataItemKey="Id"
                className="fill-50-field"
                required
              />

              <FormFieldGeneric
                titleLabel="CEP"
                onChangeValue={({ value }) => {
                  if (
                    value.length === 8 &&
                    form.IdPais !== null &&
                    form.IdPais.Descricao === 'Brasil'
                  )
                    searchCEP(value);
                  else updatePropertie('CodigoPostal', value);
                }}
                value={form.CodigoPostal}
                classNameWrapper="fill-50-field"
                required
              />
            </FormRow>

            <FormRow withoutMarginTop halfSize withShrink>
              {estados.length === 0 ? (
                <FormFieldGeneric
                  titleLabel="Estado"
                  onChangeValue={(event) => {
                    updatePropertie('EstadoNaoCadastrado', event.value);
                  }}
                  value={form.EstadoNaoCadastrado}
                  classNameWrapper="fill-50-field"
                  required
                />
              ) : (
                <FormFieldDrop
                  titleLabel="Estado"
                  defaultValueSelect="Selecione..."
                  onChangeValue={({ value }) => {
                    loadCities(value.idEstado);
                    updateForm({
                      Municipio: {
                        ...form.Municipio,
                        idEstado: value.idEstado,
                        nomeEstado: value.nomeEstado
                      }
                    });
                  }}
                  infosSelect={estados}
                  textField="nomeEstado"
                  dataItemKey="idEstado"
                  disabled={disabled.estado || form.CodigoPostal.length === 8}
                  value={form.Municipio}
                  className="fill-50-field"
                />
              )}

              {cities.length === 0 ? (
                <FormFieldGeneric
                  titleLabel="Cidade"
                  onChangeValue={({ value }) => updatePropertie('MunicipioNaoCadastrado', value)}
                  disabled={disabled.city}
                  classNameWrapper="fill-50-field"
                  required
                />
              ) : (
                <FormFieldDrop
                  titleLabel="Cidade"
                  defaultValueSelect="Selecione..."
                  onChangeValue={({ value }) => {
                    updatePropertie('Municipio', value);
                  }}
                  infosSelect={cities}
                  value={form.Municipio}
                  textField="nomeMunicipio"
                  dataItemKey="idMunicipio"
                  disabled={disabled.city || form.CodigoPostal.length === 8}
                  className="fill-50-field"
                />
              )}
            </FormRow>
          </FormRow>

          <FormRow>
            <FormRow withoutMarginTop halfSize>
              <FormFieldGeneric
                titleLabel="Rua/Avenida"
                onChangeValue={(event) => {
                  updatePropertie('Logradouro', event.value);
                }}
                value={form.Logradouro}
                classNameWrapper="fill-70-field"
                required
              />

              <FormFieldGeneric
                titleLabel="Número"
                onChangeValue={(event) => {
                  updatePropertie('Numero', event.value);
                }}
                value={form.Numero}
                classNameWrapper="fill-100-field"
                required
              />
            </FormRow>

            <FormRow withoutMarginTop halfSize>
              <FormFieldGeneric
                titleLabel="Complemento"
                onChangeValue={(event) => {
                  updatePropertie('Complemento', event.value);
                }}
                value={form.Complemento}
                classNameWrapper="fill-25-field"
              />

              <FormFieldGeneric
                titleLabel="Bairro"
                onChangeValue={(event) => {
                  updatePropertie('Bairro', event.value);
                }}
                value={form.Bairro}
                classNameWrapper="fill-100-field"
              />
            </FormRow>
          </FormRow>

          <FormRow>
            <FormRow withoutMarginTop halfSize withShrink>
              {form.person === PERSON.PF ? (
                <>
                  <FormFieldGeneric
                    titleLabel="CPF"
                    onChangeValue={(event) => {
                      updatePropertie('CPF', event.value);
                    }}
                    value={form.CPF}
                    classNameWrapper="fill-50-field"
                    required
                  />
                  <FormFieldGeneric
                    titleLabel="RG"
                    onChangeValue={(event) => {
                      updatePropertie('RG', event.value);
                    }}
                    value={form.RG}
                    classNameWrapper="fill-50-field"
                    required
                  />
                </>
              ) : (
                <>
                  <FormFieldGeneric
                    titleLabel="CNPJ"
                    onChangeValue={(event) => {
                      updatePropertie('CNPJ', event.value);
                    }}
                    value={stringToCnpj(form.CNPJ)}
                    classNameWrapper="fill-50-field"
                    mask="00.000.000/0000-00"
                    required
                  />
                  <FormFieldGeneric
                    titleLabel="Inscrição Estadual"
                    onChangeValue={(event) => {
                      updatePropertie('InscricaoEstadual', event.value);
                    }}
                    value={form.InscricaoEstadual}
                    classNameWrapper="fill-50-field"
                  />
                </>
              )}
            </FormRow>

            <FormRow withoutMarginTop halfSize>
              {form.person === PERSON.PF ? (
                <FormFieldDate
                  titleLabel="Nascimento"
                  value={form.DataNascimento}
                  valueOnChange={(val) => updateFormValue('DataNascimento', val)}
                  className="fill-50-field"
                  required
                />
              ) : (
                <FormFieldGeneric
                  titleLabel="Inscrição Municipal"
                  onChangeValue={(event) => {
                    updatePropertie('InscricaoMunicipal', event.value);
                  }}
                  value={form.InscricaoMunicipal}
                  classNameWrapper="fill-50-field"
                />
              )}
            </FormRow>
          </FormRow>

          <FormRow>
            <WhiteButton>Alterar Senha</WhiteButton>
          </FormRow>
        </ToggleInvoice>

        <FormRow spaceBetween marginTop40>
          <SimpleGrayButton
            onClick={() => {
              updatePopupProperty('deleteUser', true);
            }}
          >
            Excluir Usuario
          </SimpleGrayButton>
          <OrangeButton type="submit">Salvar</OrangeButton>
        </FormRow>
      </form>

      <PopupDeleteUser
        visible={popupVisibilities.deleteUser}
        setVisible={(value) => updatePopupProperty('deleteUser', value)}
      />
    </div>
  );
};

export default Profile;
