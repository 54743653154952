import PropTypes from 'prop-types';
import React from 'react';
import Popup from '../layout';

const PopUpViewer = ({ visible, setVisible, title, data, type }) => {
  const html = (
    <object data={data} type={type} width="100%" height="580">
      <p>Não foi possível apresentar o arquivo</p>
    </object>
  );
  return (
    <Popup
      title={title}
      width={900}
      visible={visible}
      setVisible={setVisible}
      cancelText="Fechar"
      haveConfirm={false}
    >
      {html}
    </Popup>
  );
};

PopUpViewer.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpViewer;
