import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import { CalendarWithButtons, DateFilter } from '../../components/dateComponents';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpDocument from '../../components/popUps/popUpDocument';
import PopUpError from '../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../load';
import * as attendanceService from '../../services/attendance';
import { successNotification, warningNotification } from '../../components/notification';
import { FirstDayOfMonth, LastDayOfMonth, DateNow } from '../../utils/date';
import { attendanceStatus, generatedStatus, generatedStatusText } from '../../utils/status';
import PopUpSelectContractType from '../../components/popUps/popUpSelectContractType';
import PopUpViewer from '../../components/popUps/popUpViewer';
import { urlBase64NewTab } from '../../utils/download';
import PopUpSettings from '../../components/popUps/popUpSettings';
import { useQuery } from '../../hooks';

const Attendances = () => {
  const [reload, setReload] = useState(0);
  const { id } = useParams();
  const query = useQuery();
  const byClient = query.get('byClient');
  const clientId = query.get('clientId');
  const addressId = query.get('addressId');
  const editId = window.localStorage.getItem('EDIT_ID');
  const history = useHistory();
  const [attendances, setAttendances] = useState([]);
  const [attendancesSelectedsIds, setAttendancesSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpConfirmSale, setPopUpConfirmSale] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [popUpConfirmContract, setPopUpConfirmContract] = useState(false);
  const [popUpConfirmPDF, setPopUpConfirmPDF] = useState(false);
  const [popUpViewer, setPopUpViewer] = useState(false);
  const [dataViewer] = useState(null);
  const [popUpSettings, setPopUpSettings] = useState(false);
  const filterDateLocal1 = window.localStorage.getItem('ATTENDANCE_FILTER_DATE1');
  const filterDateLocal2 = window.localStorage.getItem('ATTENDANCE_FILTER_DATE2');
  const dateNow = DateNow();
  let filterDate = { date1: FirstDayOfMonth(dateNow), date2: LastDayOfMonth(dateNow) };
  if (filterDateLocal1 && id)
    filterDate = { date1: new Date(filterDateLocal1), date2: new Date(filterDateLocal2) };
  const [date, setDate] = useState(filterDate);
  const filterTypeLocal = window.localStorage.getItem('ATTENDANCE_FILTER_TYPE');
  const [filterType, setFilterType] = useState(filterTypeLocal || 'month');

  window.localStorage.setItem('ATTENDANCE_FILTER_DATE1', date.date1);
  window.localStorage.setItem('ATTENDANCE_FILTER_DATE2', date.date2);
  window.localStorage.setItem('ATTENDANCE_FILTER_TYPE', filterType);

  const columns = [
    {
      field: 'Situacao',
      title: 'Sit.',
      width: 50,
      cell: (props) => {
        const td = attendanceStatus(props.dataItem.Situacao);
        return td;
      },
      columnMenuCheckbox: true
    },
    {
      field: 'DocumentoGerado',
      title: 'Gerado',
      width: 80,
      cell: (props) => {
        const td = generatedStatus(props.dataItem.DocumentoGerado);
        return td;
      },
      columnMenuCheckbox: true
    },
    {
      field: 'Data',
      title: 'Data da Proposta',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      width: 110,
      columnMenu: true
    },
    {
      field: 'Numero',
      title: 'Núm.',
      width: 60,
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 300,
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'Contato',
      title: 'Contato',
      columnMenu: true
    },
    {
      field: 'TipoAtendimentoNome',
      title: 'Tipo',
      columnMenu: true,
      columnMenuCheckbox: true
    },
    {
      field: 'Valor',
      title: 'Valor (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      width: 90,
      columnMenu: true,
      totalCell: true
    }
  ];

  const seekScroll = async (data) => {
    if (editId != null) {
      const index = data.findIndex((item) => item.Id === editId);
      setRowIndex(index);
    } else {
      setRowIndex(data.length - 1);
    }
  };

  const fetchAttendances = async () => {
    const dateInterval = {
      DataInicio: date.date1,
      DataFim: date.date2
    };
    const clientAddress = {
      IdPessoa: clientId,
      IdEndereco: addressId
    };
    let response = null;
    if (byClient) response = await attendanceService.getByClientId(clientAddress);
    else response = await attendanceService.getByDate(dateInterval);
    let { data } = response;

    data = data.map((item) => {
      const data = new Date(item.Data);
      const dataInicio = new Date(item.DataInicio);
      const dataFim = new Date(item.DataFim);
      data.setHours(0, 0, 0, 0);
      dataInicio.setHours(0, 0, 0, 0);
      dataFim.setHours(0, 0, 0, 0);
      return {
        ...item,
        Data: data,
        DataInicio: dataInicio,
        DataFim: dataFim,
        DocumentoGerado: generatedStatusText(item.DocumentoGerado)
      };
    });
    setAttendances(data);
    let flag = true;
    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].ParcelaPeriodo !== data[i + 1].ParcelaPeriodo) {
        flag = false;
        break;
      }
    }
    seekScroll(data);
  };

  const excludeAttendances = async () => {
    setLoading(true);
    const excludeAttendancesRequests = attendancesSelectedsIds.map((attendanceSelected) =>
      attendanceService.exclude(attendanceSelected)
    );

    const excludeAttendancesResponses = await Promise.all(excludeAttendancesRequests);

    const isAllRequestsRight = excludeAttendancesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setAttendancesSelectedsIds([]);
    setLoading(false);
    setPopUpConfirmDelete(false);
    if (isAllRequestsRight) successNotification('Proposta(s) excluída(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais propostas:'];
      excludeAttendancesResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Proposta');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchAttendances();
  };

  const generateSale = async (idDocument) => {
    setLoading(true);
    const generateSalesRequests = attendancesSelectedsIds.map((attendanceSelected) =>
      attendanceService.generateSale(attendanceSelected, idDocument)
    );

    const generateSalesResponses = await Promise.all(generateSalesRequests);

    const isAllRequestsRight = generateSalesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );
    setAttendancesSelectedsIds([]);
    setLoading(false);
    if (isAllRequestsRight) {
      successNotification('Documento gerado com sucesso!');
      generateSalesResponses.forEach((element) => {
        const { data } = element;
        history.push(`/invoices/invoiceRegistration/${data}`);
      });
    } else {
      const erro = ['Houve um erro ao tentar gerar o documento:'];
      generateSalesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar o Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  const generateContract = async (idContractType) => {
    setLoading(true);
    const generateContractsRequests = attendancesSelectedsIds.map((attendanceSelected) =>
      attendanceService.generateContract(attendanceSelected, idContractType)
    );

    const generateContractsResponses = await Promise.all(generateContractsRequests);

    const isAllRequestsRight = generateContractsResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );
    setAttendancesSelectedsIds([]);
    setLoading(false);
    if (isAllRequestsRight) {
      successNotification('Contrato gerado com sucesso!');
      generateContractsResponses.forEach((element) => {
        const { data } = element;
        history.push(`/contracts/contractRegistration/${data}`);
      });
    } else {
      const erro = ['Houve um erro ao tentar gerar o contrato:'];
      generateContractsResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar o Contrato');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  const generatePDF = async () => {
    setLoading(true);
    const generatePDFRequests = attendancesSelectedsIds.map((attendanceSelected) =>
      attendanceService.generatePDF(attendanceSelected)
    );

    const generatePDFResponses = await Promise.all(generatePDFRequests);

    const isAllRequestsRight = generatePDFResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );
    setLoading(false);
    generatePDFResponses.forEach((currentResponse) => {
      if (currentResponse.status === 200) {
        const base64 = currentResponse.data;
        urlBase64NewTab(base64, 'pdf');
      }
    });

    if (!isAllRequestsRight) {
      const erro = ['Houve um erro ao tentar gerar PDF para a proposta selecionada:'];
      generatePDFResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar PDF');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  useEffect(async () => {
    setLoading(true);
    await fetchAttendances();
    setLoading(false);
  }, [date, reload]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="attendance-list">
        <h1>Proposta</h1>
        <div className="buttons-attendance-list">
          {!byClient && filterType === 'month' && (
            <CalendarWithButtons
              date={date.date1}
              setDate={(val) => {
                const date1 = FirstDayOfMonth(val);
                const date2 = LastDayOfMonth(val);
                setDate({ date1, date2 });
                window.localStorage.setItem('ATTENDANCE_FILTER_DATE1', date1);
                window.localStorage.setItem('ATTENDANCE_FILTER_DATE2', date2);
              }}
              service={attendanceService}
            />
          )}
          {!byClient && filterType === 'day' && (
            <DateFilter
              date1={date.date1}
              date2={date.date2}
              setDate={(date1, date2) => {
                setDate({ date1, date2 });
                window.localStorage.setItem('ATTENDANCE_FILTER_DATE1', date1);
                window.localStorage.setItem('ATTENDANCE_FILTER_DATE2', date2);
              }}
            />
          )}
          <div className="config-components-popup-button">
            <FloatingActionButton
              icon={INTERNAL_BUTTON.BUTTON_REFRESH}
              onClick={() => {
                setReload((prev) => prev + 1);
              }}
            />
          </div>
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            url="/attendances/attendanceRegistration/0"
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (attendancesSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhuma proposta selecionada');
                },
                text: 'Excluir Proposta'
              },
              {
                id: INTERNAL_ITEMS.GERAR_PDF,
                onClick: () => {
                  if (attendancesSelectedsIds.length === 1) generatePDF();
                  else if (attendancesSelectedsIds.length > 1)
                    warningNotification('Somente uma proposta deve ser selecionada');
                  else warningNotification('Nenhuma proposta selecionada');
                },
                text: 'Gerar PDF'
              },
              {
                id: INTERNAL_ITEMS.GERAR_CONTRATO,
                onClick: () => {
                  if (attendancesSelectedsIds.length === 1) setPopUpConfirmContract(true);
                  else if (attendancesSelectedsIds.length > 1)
                    warningNotification('Somente uma proposta deve ser selecionada');
                  else warningNotification('Nenhuma proposta selecionada');
                },
                text: 'Gerar Contrato',
                style: { backgroundColor: '#000000' }
              },
              {
                id: INTERNAL_ITEMS.BOLETA,
                onClick: () => {
                  if (attendancesSelectedsIds.length === 1) setPopUpConfirmSale(true);
                  else if (attendancesSelectedsIds.length > 1)
                    warningNotification('Somente uma proposta deve ser selecionada');
                  else warningNotification('Nenhuma proposta selecionada');
                },
                text: 'Gerar Documento',
                style: { backgroundColor: '#000000' }
              },
              {
                id: INTERNAL_ITEMS.CONFIGURAR,
                onClick: () => setPopUpSettings(true),
                text: 'Configurar Tela'
              }
            ]}
          />
        </div>
        <Table
          name="Attendances"
          data={attendances}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setAttendancesSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/attendances/attendanceRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeAttendances}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirmação"
        message="Você tem certeza que deseja excluir a(s) propostas(s) selecionada(s)?"
      />
      <PopUpDocument
        onConfirm={generateSale}
        visible={popUpConfirmSale}
        setVisible={setPopUpConfirmSale}
        title="Confirmação"
        message="Você tem certeza que deseja gerar o documento para a proposta selecionada?"
      />
      <PopUpSelectContractType
        onConfirm={generateContract}
        visible={popUpConfirmContract}
        setVisible={setPopUpConfirmContract}
        title="Confirmação"
        message="Você tem certeza que deseja gerar o contrato para a proposta selecionada?"
      />
      <PopUpConfirm
        onConfirm={generatePDF}
        visible={popUpConfirmPDF}
        setVisible={setPopUpConfirmPDF}
        title="Confirmação"
        message="Você tem certeza que deseja gerar o PDF da proposta selecionada?"
      />
      <PopUpViewer
        visible={popUpViewer}
        setVisible={setPopUpViewer}
        title="Pré-visualização"
        data={dataViewer}
        type="application/pdf"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpSettings
        onConfirm={(val) => {
          setFilterType(val);
          window.localStorage.setItem('ATTENDANCE_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Preferências"
        filter={filterType}
      />
    </>
  );
};

export default Attendances;
