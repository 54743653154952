import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import PopUpButtonAnimationCustom from './buttonAnimationCustom';
import './style.css';

export const INTERNAL_ITEMS = {
  PIX: 0,
  EMAIL: 1,
  BOLETA: 2,
  EXCLUIR_NFSE: 3,
  EDITAR: 4,
  IMPRIMIR: 5,
  CANCELAR: 6,
  CONSULTAR: 7,
  TRANSMITIR: 8,
  GERAR_NFSE: 9,
  CONFIG: 10,
  PESSOA_FISICA: 11,
  PESSOA_JURIDICA: 12,
  EXCLUIR: 13,
  EXPORTAR_EXCEL: 14,
  GERAR_CONTRATO: 15,
  IMPORTAR: 16,
  GERAR_PDF: 17,
  CONECTAR: 18,
  CONFIGURAR: 19,
  IMPORTAR_CUPOM: 20,
  ADICIONAR: 21
};

export const INTERNAL_BUTTON = {
  BUTTON_ADD: 'plus',
  BUTTON_THREEPOINTS: 'more-horizontal',
  BUTTON_FILTER: 'filter',
  BUTTON_TRASH: 'trash',
  BUTTON_ATTACH: 'attachment',
  BUTTON_FILTER_CLEAR: 'filter-clear',
  BUTTON_TABLE: 'table',
  BUTTON_REFRESH: 'refresh'
};

const PopUpButton = ({ internalItems, url, internalButton }) => {
  const makeItem = (icon, themeColor, myId) => ({
    icon,
    themeColor,
    myId
  });

  const items = {
    [INTERNAL_ITEMS.PIX]: makeItem('iconPix', 'pix', INTERNAL_ITEMS.PIX),
    [INTERNAL_ITEMS.EMAIL]: makeItem('envelop', 'inherit', INTERNAL_ITEMS.EMAIL),
    [INTERNAL_ITEMS.BOLETA]: makeItem('file-txt', 'success', INTERNAL_ITEMS.BOLETA),
    [INTERNAL_ITEMS.EXCLUIR_NFSE]: makeItem('trash', 'error', INTERNAL_ITEMS.EXCLUIR_NFSE),
    [INTERNAL_ITEMS.EDITAR]: makeItem('pencil', 'primary', INTERNAL_ITEMS.EDITAR),
    [INTERNAL_ITEMS.IMPRIMIR]: makeItem('print', 'ligth', INTERNAL_ITEMS.IMPRIMIR),
    [INTERNAL_ITEMS.CANCELAR]: makeItem('x', 'secondary', INTERNAL_ITEMS.CANCELAR),
    [INTERNAL_ITEMS.CONSULTAR]: makeItem('search', 'success', INTERNAL_ITEMS.CONSULTAR),
    [INTERNAL_ITEMS.TRANSMITIR]: makeItem('arrow-right', 'tertiary', INTERNAL_ITEMS.TRANSMITIR),
    [INTERNAL_ITEMS.GERAR_NFSE]: makeItem('code', 'info', INTERNAL_ITEMS.GERAR_NFSE),
    [INTERNAL_ITEMS.CONFIG]: makeItem('gear', 'dark', INTERNAL_ITEMS.CONFIG),
    [INTERNAL_ITEMS.PESSOA_FISICA]: makeItem('user', 'ligth', INTERNAL_ITEMS.PESSOA_FISICA),
    [INTERNAL_ITEMS.PESSOA_JURIDICA]: makeItem(
      'myspace',
      'success',
      INTERNAL_ITEMS.PESSOA_JURIDICA
    ),
    [INTERNAL_ITEMS.EXCLUIR]: makeItem('iconExcluir', 'error', INTERNAL_ITEMS.EXCLUIR),
    [INTERNAL_ITEMS.EXPORTAR_EXCEL]: makeItem(
      'file-excel',
      'success',
      INTERNAL_ITEMS.EXPORTAR_EXCEL
    ),
    [INTERNAL_ITEMS.GERAR_CONTRATO]: makeItem('file-txt', 'info', INTERNAL_ITEMS.GERAR_CONTRATO),
    [INTERNAL_ITEMS.IMPORTAR]: makeItem('file-add', 'success', INTERNAL_ITEMS.IMPORTAR),
    [INTERNAL_ITEMS.GERAR_PDF]: makeItem('file-pdf', 'pdf', INTERNAL_ITEMS.GERAR_PDF),
    [INTERNAL_ITEMS.CONECTAR]: makeItem('globe-outline', 'ligth', INTERNAL_ITEMS.CONECTAR),
    [INTERNAL_ITEMS.CONFIGURAR]: makeItem('gear', 'dark', INTERNAL_ITEMS.CONFIGURAR),
    [INTERNAL_ITEMS.IMPORTAR_CUPOM]: makeItem('file-add', 'info', INTERNAL_ITEMS.IMPORTAR),
    [INTERNAL_ITEMS.ADICIONAR]: makeItem('plus', 'bluepurple', INTERNAL_ITEMS.ADICIONAR)
  };

  const convertItems = (itemsToConvert) =>
    itemsToConvert.map((itemToConvert) => ({
      ...items[itemToConvert.id],
      onClick: () => itemToConvert.onClick(),
      text: itemToConvert.text
    }));

  return (
    <div className="config-components-popup-button">
      {url ? (
        <Link to={url} style={{ textDecoration: 'none' }}>
          <FloatingActionButton
            icon={internalButton}
            popupSettings={{
              animate: false
            }}
            positionMode="relative"
          />
        </Link>
      ) : (
        <FloatingActionButton
          icon={internalButton}
          items={convertItems(internalItems)}
          item={PopUpButtonAnimationCustom}
          popupSettings={{
            animate: false
          }}
        />
      )}
    </div>
  );
};

PopUpButton.propTypes = {
  internalItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      onClick: PropTypes.func,
      text: PropTypes.string
    })
  ),
  url: PropTypes.string,
  internalButton: PropTypes.string
};
PopUpButton.defaultProps = {
  internalItems: [],
  url: null
};

export default PopUpButton;
