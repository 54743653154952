import PropTypes from 'prop-types';
import { useState } from 'react';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormFieldAutoComplete from '../../formField/autoComplete';
import FormRow from '../../formRow';
import Popup from '../layout';
import { PayRollItems } from '../../../utils/payRoll';

const PopUpAddPayRoll = ({ addPayRoll, visible, setVisible }) => {
  const [code, setCode] = useState(null);
  const [description, setDescription] = useState(null);
  const [codes, setCodes] = useState(PayRollItems.map((item) => item.Code));
  const [descriptions, setDescriptions] = useState(
    PayRollItems.map((item) => item.Description).sort()
  );
  const [reference, setReference] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState(1);

  const confirmAddService = () => {
    const item = {
      Codigo: code,
      DescricaoServProd: description,
      Referencia: reference,
      Quantidade: 1,
      Valor: value <= 0 ? null : value,
      Desconto: discount <= 0 ? null : discount,
      ValorTotal: value <= 0 ? discount * -1 : value
    };
    addPayRoll(item);
    setCode(null);
    setDescription(null);
    setReference(null);
    setAmount(1);
    setValue(0);
    setDiscount(0);
  };

  return (
    <Popup
      title="Adicionar Item da Folha de Pagamento"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirmAddService}
    >
      <FormRow>
        <FormFieldAutoComplete
          titleLabel="Código"
          classNameWrapper="fill-100-field"
          value={code}
          onChange={(e) => {
            const code = e.value;
            setCode(code);
            const item = PayRollItems.find((item) => item.Code === code);
            if (item) setDescription(item.Description);
          }}
          data={codes}
        />
      </FormRow>
      <FormRow>
        <FormFieldAutoComplete
          titleLabel="Descrição"
          classNameWrapper="fill-100-field"
          value={description}
          onChange={(e) => {
            const description = e.value;
            setDescription(description);
            const item = PayRollItems.find((item) => item.Description === description);
            if (item) setCode(item.Code);
          }}
          data={descriptions}
          required
        />
      </FormRow>
      <FormRow>
        <GenericField
          titleLabel="Referência"
          classNameWrapper="fill-100-field"
          valueInput={reference}
          onChangeValue={(val) => {
            setReference(val.value);
          }}
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Vencimento"
          enableReais
          classNameWrapper="fill-100-field"
          valueInput={value}
          setValue={setValue}
          iconEnabled={INTERNAL_ICON.REAIS_ICON}
          min="0"
        />
      </FormRow>
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Desconto"
          enableReais
          classNameWrapper="fill-100-field"
          valueInput={discount}
          setValue={setDiscount}
          iconEnabled={INTERNAL_ICON.REAIS_ICON}
          min="0"
        />
      </FormRow>
    </Popup>
  );
};

PopUpAddPayRoll.propTypes = {
  addPayRoll: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpAddPayRoll.defaultProps = {};

export default PopUpAddPayRoll;
