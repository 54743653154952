import './style.css';
import React, { useState } from 'react';
import Popup from '../layout';
import FormRow from '../../formRow';
import GenericField from '../../formField/genericField';
import LoadScreen from '../../../view/load';

const PopUpEmail = ({ visible, setVisible, title, message, onConfirm, email, setEmail }) => {
  const [loading, setLoading] = useState(false);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  return (
    <>
      <Popup
        width={600}
        title={title}
        visible={visible}
        setVisible={setVisible}
        cancelText="Cancelar"
        confirmText="Enviar"
        disable={loading}
        onConfirm={async () => {
          setLoading(true);
          if (onConfirm) await onConfirm();
          setLoading(false);
        }}
      >
        {loading ? (
          <LoadScreen />
        ) : (
          <>
            <span style={{ marginTop: '10px' }}>
              <div style={{ whiteSpace: 'pre-wrap' }}>{message}</div>
            </span>
            <FormRow withShrink>
              <GenericField
                titleLabel="Email alternativo (para múltiplos emails utilizar o separador ;)"
                valueInput={email}
                classNameWrapper="fill-100-field"
                onChangeValue={(val) => {
                  setEmail(val.value);
                }}
                name="email"
              />
            </FormRow>
          </>
        )}
      </Popup>
    </>
  );
};

export default PopUpEmail;
