import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import FormFieldDrop from '../../formField/drop';
import FormFieldDate from '../../formField/date';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';
import * as personService from '../../../services/person';
import * as coinService from '../../../services/coin';
import LoadScreen from '../../../view/load';

const PopUpShoppingList = ({ visible, setVisible, add }) => {
  const [code, setCode] = useState(null);
  const [date, setDate] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [coin, setCoin] = useState(null);
  const [coins, setCoins] = useState([]);
  const [value, setValue] = useState(null);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    const item = {
      Codigo: code,
      DataAtualizacao: date,
      PessoaId: supplier?.Id,
      PessoaNome: supplier?.Nome,
      MoedaId: coin?.Id,
      MoedaNome: coin?.Simbolo,
      Valor: value,
      Quantidade: amount,
      Desconto: 0,
      ValorTotal: amount * value
    };
    add(item);
    setCode(null);
    setDate(null);
    setValue(null);
    setSupplier(null);
    setCoin(null);
  };

  const loadSuppliers = async (filter) => {
    const { data } = await personService.getBySupplierName(filter);
    return data;
  };

  const loadCoins = async () => {
    const { data } = await coinService.getAll();
    setCoins(data);
    if (data?.length > 0) setCoin(data[0]);
    return data;
  };

  useEffect(async () => {
    if (visible) {
      setLoading(true);
      await loadCoins();
      setLoading(false);
    }
  }, [visible]);

  return (
    <Popup
      title="Adicionar Lista de Compras"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirm}
    >
      {loading ? (
        <LoadScreen />
      ) : (
        <>
          <FormRow>
            <GenericField
              titleLabel="Código"
              classNameWrapper="fill-100-field"
              valueInput={code}
              onChangeValue={(val) => {
                setCode(val.value);
              }}
              required
            />
          </FormRow>
          <FormRow>
            <FormFieldDate
              titleLabel="Data de Atualização"
              value={date}
              valueOnChange={setDate}
              className="fill-100-field"
            />
          </FormRow>
          <FormRow>
            <FormFieldDrop
              titleLabel="Fornecedor"
              defaultValueSelect="Procure o fornecedor..."
              onChangeValue={(val) => {
                setSupplier(val.value);
              }}
              value={supplier}
              className="fill-100-field"
              loadData={loadSuppliers}
              serverFiltering={true}
              dataItemKey="Id"
              textField="Nome"
            />
          </FormRow>
          <FormRow>
            <FormFieldDrop
              titleLabel="Moeda"
              defaultValueSelect="Selecione..."
              onChangeValue={(val) => {
                setCoin(val.value);
              }}
              value={coin}
              className="fill-100-field"
              infosSelect={coins}
              dataItemKey="Id"
              textField="Simbolo"
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Quantidade"
              classNameWrapper="fill-100-field"
              valueInput={amount}
              onChangeValue={(e) => {
                const {
                  target: { name, valueAsNumber }
                } = e;
                setAmount(valueAsNumber);
              }}
              name="amount"
              required
              typeNumber
              min="0"
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Valor"
              enableReais
              classNameWrapper="fill-100-field"
              valueInput={value}
              setValue={setValue}
              iconEnabled={INTERNAL_ICON.REAIS_ICON}
              required
              min="0"
            />
          </FormRow>
        </>
      )}
    </Popup>
  );
};

PopUpShoppingList.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpShoppingList.defaultProps = {};

export default PopUpShoppingList;
