import { GridColumn } from '@progress/kendo-react-grid';
import { useInternationalization, IntlProvider, load } from '@progress/kendo-react-intl';
import brNumbers from 'cldr-numbers-full/main/br/numbers.json';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AuthContext } from '../../../auth/AuthContext';
import { successNotification, errorNotification } from '../../notification';
import { getById, update } from '../../../services/aliquot';
import FormRow from '../../formRow';
import Popup from '../layout';
import './styles.css';
import CustomInput from '../../dateComponents/CustomInput';
import GridInline from '../../grid';
import { DateNow } from '../../../utils/date';
import NumberCell from '../../grid/numberCell';

load(brNumbers);

const PopUpAliquot = ({ visible, setVisible }) => {
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];
  const [date, setDate] = useState(DateNow());
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const loadAliquots = async (date) => {
    const { data: aliquotsFromApi } = await getById(
      walletSelected.CarteiraBusiness.Id,
      date.getFullYear()
    );
    const data1 = [];
    const data2 = [];
    aliquotsFromApi.forEach((element) => {
      const month = new Date(element.Data);
      const intMonth = month.getMonth();
      element.Mes = monthNames[intMonth];
      if (intMonth <= 5) {
        data1.push(element);
      } else {
        data2.push(element);
      }
    });
    setData1(data1);
    setData2(data2);
  };

  useEffect(async () => {
    if (visible) await loadAliquots(date);
  }, [visible]);

  const updateAliquot = async (body) => {
    try {
      await update(walletSelected.CarteiraBusiness.Id, body);
      successNotification('Alíquotas atualizadas com sucesso!');
    } catch (error) {
      errorNotification('Houve um erro ao tentar atualizar as Alíquotas');
    }
  };

  const submitHandle = () => {
    const data = data1.concat(data2);
    updateAliquot(data);
  };

  return (
    <Popup
      width={600}
      visible={visible}
      setVisible={setVisible}
      title="Alíquotas de ISS"
      confirmText="Salvar"
      cancelText="Cancelar"
      onConfirm={submitHandle}
      className="aliquot"
    >
      <FormRow>
        <DatePicker
          selected={date}
          locale="pt"
          onChange={(val) => {
            setDate(val);
            loadAliquots(val);
          }}
          wrapperClassName="day-calendar-field dateField"
          popperClassName="day-calendar"
          customInput={<CustomInput />}
          dateFormat="yyyy"
          showYearPicker
        />
      </FormRow>
      <FormRow>
        <GridInline data={data1} setData={setData1}>
          <GridColumn field="Mes" title="Mês" editable={false} width={100} />
          <GridColumn
            field="Valor"
            title="Alíquota (%)"
            editable={true}
            editor="numeric"
            cell={NumberCell}
            width={158}
          />
        </GridInline>
        <GridInline data={data2} setData={setData2} style={{ margin: '0' }}>
          <GridColumn field="Mes" title="Mês" editable={false} width={100} />
          <GridColumn
            field="Valor"
            title="Alíquota (%)"
            editable={true}
            editor="numeric"
            cell={NumberCell}
            width={158}
          />
        </GridInline>
      </FormRow>
    </Popup>
  );
};

PopUpAliquot.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpAliquot;
