import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForms } from '../../../../hooks';
import { AuthContext } from '../../../../auth/AuthContext';
import {
  OrangeButton,
  WhiteButton,
  SimpleGrayButton
} from '../../../../components/buttonsComponent';
import ToggleInvoice from '../../../../components/toggleInvoice';
import { FormFieldDrop, FormFieldGeneric } from '../../../../components/formField';
import FormRow from '../../../../components/formRow';
import Logo from '../../../../components/logo';
import PopUpAliquot from '../../../../components/popUps/popUpAliquot';
import PopUpConfirm from '../../../../components/popUps/popUpConfirm';
import PopUpError from '../../../../components/popUps/popUpError';
import './style.css';
import {
  errorNotification,
  successNotification,
  warningNotification
} from '../../../../components/notification';
import * as documentService from '../../../../services/document';
import * as countryService from '../../../../services/country';
import * as coinService from '../../../../services/coin';
import * as specialTributeService from '../../../../services/especialTribute';
import * as addressService from '../../../../services/address';
import * as accountService from '../../../../services/account';
import LoadScreen from '../../../load';
import GenericField, { INTERNAL_ICON } from '../../../../components/formField/genericField';
import { urlBase64 } from '../../../../utils/download';
import FormFieldCheckbox from '../../../../components/formField/checkbox';

const initialForm = {
  Id: null,
  Nome: null,
  URLLogo: null,
  Numero: null,
  OutrasInformacoes: null,
  AliquotaIBPT: null,
  ValorMinIRRF: false,
  NumeroSufixo: null
};

const DocumentRegistration = () => {
  const { id } = useParams();
  const [isNFSe, setIsNFSe] = useState(false);
  const [isRecibo, setIsRecibo] = useState(false);
  const [isNFSeNacional, setIsNFSeNacional] = useState(false);
  const [isNotaDebito, setIsNotaDebito] = useState(false);
  const [invoiceType, setInvoiceType] = useState(null);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [coin, setCoin] = useState(null);
  const [coins, setCoins] = useState([]);
  const [popUpAliquotVisible, setPopUpAliquotVisible] = useState(false);
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [logo, setLogo] = useState(null);
  const [form, updateProperty, setForm] = useForms(initialForm);
  const [popUpConfirmVisibility, setPopUpConfirmVisibility] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [specialTributes, setSpecialTributes] = useState([]);
  const [specialTribute, setSpecialTribute] = useState(null);
  const [citiesInvoice, setCitiesInvoice] = useState([]);
  const [city, setCity] = useState(null);
  const [cityInvoice, setCityInvoice] = useState(null);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [urlLogo, setUrlLogo] = useState(null);
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const loadInvoiceTypes = async () => {
    const { data } = await documentService.getInvoiceTypes();
    setInvoiceTypes(data);
    return data;
  };

  const loadCoinByCountry = async (idCountry) => {
    const { data } = await coinService.getCoinByCountry(idCountry);
    setCoins(data);
    setCoin(data[0]);
    return data;
  };

  const loadCountries = async () => {
    const { data } = await countryService.getAll();
    setCountries(data);
    const brazil = data.filter((p) => p.Descricao === 'Brasil')[0];
    setCountry(brazil);
    loadCoinByCountry(brazil?.Id);
    return data;
  };

  const loadSpecialTributes = async (idCity, nacional) => {
    if (idCity) {
      const { data: specialTributesFromApi } = await specialTributeService.getTributesByCity(
        idCity,
        nacional
      );
      setSpecialTributes(specialTributesFromApi);
      return specialTributesFromApi;
    }
    return [];
  };

  const loadCitiesInvoice = async () => {
    const { data: citiesInvoiceFromApi } = await addressService.getCitiesInvoice();
    setCitiesInvoice(citiesInvoiceFromApi);
    return citiesInvoiceFromApi;
  };

  const loadStates = async (id) => {
    const { data } = await addressService.statesFromCountry(id);
    setStates(data);
    return data;
  };

  const loadCities = async (id) => {
    const { data } = await addressService.citiesFromState(id);
    setCities(data);
    return data;
  };

  const loadAccounts = async () => {
    const data = await accountService.getReceivingLocalAndReceivable();
    setAccounts(data);
    return data;
  };

  useEffect(async () => {
    setLoading(true);

    const invoiceTypes = await loadInvoiceTypes();
    const countries = await loadCountries();
    const brazil = countries.filter((p) => p.Descricao === 'Brasil')[0];
    const states = await loadStates(brazil?.Id);
    const citiesInvoice = await loadCitiesInvoice();
    const accounts = await loadAccounts();
    if (id) {
      const response = await documentService.getById(id);
      const document = response.data;
      setForm({
        Id: document.Id,
        Nome: document.Nome,
        URLLogo: document.URLLogo,
        Numero: document.Numero,
        OutrasInformacoes: document.OutrasInformacoes,
        AliquotaIBPT: document.AliquotaIBPT,
        ValorMinIRRF: document.ValorMinIRRF,
        NumeroSufixo: document.NumeroSufixo
      });
      const itemInvoiceType = invoiceTypes.find(
        (element) => element.Id === document.TipoDocumentoId
      );
      setInvoiceType(itemInvoiceType);
      const itemCountry = countries.find((element) => element.Id === document.PaisId);
      setCountry(itemCountry);
      if (itemCountry) {
        const coins = await loadCoinByCountry(itemCountry?.Id);
        const itemCoin = coins.find((element) => element.Id === document.MoedaId);
        setCoin(itemCoin);
      }
      const specialTributes = await loadSpecialTributes(
        document.MunicipioId,
        itemInvoiceType?.Id === 3
      );
      const specialTribute = specialTributes.find(
        (element) => element.Id === document.RegimeEspecialTributacaoId
      );
      setSpecialTribute(specialTribute);
      const city = await addressService.getCityById(document.MunicipioId);
      if (city.data) {
        const { data } = city;
        await loadCities(data.idEstado);
        setCity(data);
        const state = states.find((item) => item.idEstado === data.idEstado);
        setState(state);
      }
      const cityInvoice = citiesInvoice.find(
        (element) => element.idMunicipio === document.MunicipioId
      );
      if (cityInvoice) setCityInvoice(cityInvoice);
      if (itemInvoiceType?.Id === 1) setIsNFSe(true);
      else if (itemInvoiceType?.Id === 2) setIsRecibo(true);
      else if (itemInvoiceType?.Id === 3) setIsNFSeNacional(true);
      else if (itemInvoiceType?.Id === 4) setIsNotaDebito(true);
      const logo = await documentService.recoveryLogo(id);
      if (logo.data && document.TipoLogo) {
        const url = urlBase64(logo.data, document.TipoLogo);
        setUrlLogo(url);
      } else {
        setUrlLogo(null);
      }
      const account = accounts.find((item) => item.Id === document.ContaId);
      setAccount(account);
    } else {
      const data = await accountService.getDefaultReceivable();
      setAccount(data);
    }
    setLoading(false);
  }, []);

  const history = useHistory();
  const redirectToDocuments = () => history.push('/settings/documents');
  const excludeLogo = async () => {
    setLoading(true);

    await documentService.excludeLogo(id);
    setForm({
      ...form,
      URLLogo: null
    });

    setLogo(null);
    setUrlLogo(null);
    setLoading(false);
  };

  const excludeDocument = async () => {
    if (id) {
      const response = await documentService.exclude(id);
      if (response.status === 200) {
        successNotification('Documento excluído com sucesso!');
        redirectToDocuments();
      } else {
        const { data } = response;
        if (data.MensagemUsuario) setPopUpErrorMessage(data.MensagemUsuario);
        else setPopUpErrorMessage(data);
        setPopUpError(true);
      }
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    const documentBodyForm = {
      Id: form.Id,
      Nome: form.Nome,
      URLLogo: form.URLLogo,
      TipoDocumentoId: invoiceType?.Id,
      PaisId: country?.Id,
      MoedaId: coin?.Id,
      Numero: form.Numero,
      OutrasInformacoes: form.OutrasInformacoes,
      AliquotaIBPT: form.AliquotaIBPT,
      RegimeEspecialTributacaoId: specialTribute?.Id,
      MunicipioId: isNFSeNacional ? city?.idMunicipio : cityInvoice?.idMunicipio,
      ValorMinIRRF: form.ValorMinIRRF,
      NumeroSufixo: form.NumeroSufixo,
      ContaId: account?.Id
    };

    const documentPromise = id
      ? documentService.update(form.Id, documentBodyForm)
      : documentService.create(documentBodyForm);

    const [response] = await Promise.all([documentPromise]);

    if (response.status === 200) {
      let documentId = null;
      if (id) documentId = id;
      else {
        const { data } = response;
        documentId = data;
      }
      if (logo) await documentService.updateLogo(logo.binary, logo.type, documentId);
      successNotification('Documento atualizado com sucesso!');
      redirectToDocuments();
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar excluir um tipo de proposta.');
    }
    setLoading(false);
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      {id ? <h1>Atualizar Documento</h1> : <h1>Criar Documento</h1>}
      <form onSubmit={submitForm}>
        <FormRow>
          <GenericField
            titleLabel="Nome do Documento"
            classNameWrapper="fill-50-field"
            valueInput={form.Nome}
            onChangeValue={updateProperty}
            name="Nome"
            required
          />
        </FormRow>
        <FormRow>
          <FormFieldDrop
            titleLabel="Tipo de Documento"
            defaultValueSelect="Selecione..."
            onChangeValue={(val) => {
              if (val.value) {
                const invoice = val.value;
                setInvoiceType(invoice);
                if (invoice.Nome === 'NFSe') {
                  setIsNFSe(true);
                  setIsRecibo(false);
                  setIsNFSeNacional(false);
                  setIsNotaDebito(false);
                } else if (invoice.Nome === 'NFSe Nacional') {
                  setIsNFSe(false);
                  setIsRecibo(false);
                  setIsNFSeNacional(true);
                  setIsNotaDebito(false);
                } else if (invoice.Nome === 'Recibo') {
                  setIsNFSe(false);
                  setIsRecibo(true);
                  setIsNFSeNacional(false);
                  setIsNotaDebito(false);
                } else if (invoice.Nome === 'Nota de Débito') {
                  setIsNFSe(false);
                  setIsRecibo(false);
                  setIsNFSeNacional(false);
                  setIsNotaDebito(true);
                }
                setCity(null);
                setCityInvoice(null);
                setSpecialTributes([]);
                setSpecialTribute(null);
              }
            }}
            value={invoiceType}
            className="fill-50-field"
            infosSelect={invoiceTypes}
            dataItemKey="Id"
            textField="Nome"
            required
            disabled={id}
          />
        </FormRow>
        {isNFSeNacional && (
          <ToggleInvoice title="NFSe" startExpanded>
            <FormRow>
              <Logo
                backgroundColor={walletSelected.CorPerfil}
                removeAvatar={excludeLogo}
                textAvatar="Logo para a NFSe (270x100px)"
                imageSrc={urlLogo}
                imageAlt="Logo para a NFSe"
                className="fill-50-field"
                setImageData={setLogo}
                messageRemoveAvatar="Você tem certeza que deseja excluir o logo para a NFSe"
              />
            </FormRow>
            <FormRow>
              <GenericField
                titleLabel="Número Inicial"
                enableInfo
                onChangeValue={(val) => {
                  const {
                    target: { name, valueAsNumber }
                  } = val;
                  setForm({
                    ...form,
                    Numero: valueAsNumber
                  });
                }}
                msg="Número inicial de emissão de nota fiscal"
                valueInput={form.Numero}
                classNameWrapper="fill-33-field"
                required
                name="Numero"
                typeNumber
              />
            </FormRow>
            {loadingDiv ? (
              <LoadScreen />
            ) : (
              <FormRow>
                <FormFieldDrop
                  titleLabel="Estado"
                  defaultValueSelect="Selecione..."
                  infosSelect={states}
                  className="fill-33-field"
                  name="state"
                  onChangeValue={async (val) => {
                    setState(val.value);
                    if (val.value) {
                      setLoadingDiv(true);
                      const cities = await loadCities(val.value.idEstado);
                      setCity(null);
                      setLoadingDiv(false);
                    }
                  }}
                  value={state}
                  dataItemKey="idEstado"
                  textField="nomeEstado"
                  required
                />
                <FormFieldDrop
                  titleLabel="Município da Nota Fiscal"
                  defaultValueSelect="Selecione..."
                  infosSelect={cities}
                  className="fill-33-field"
                  name="city"
                  onChangeValue={async (val) => {
                    setCity(val.value);
                    if (val.value) {
                      setLoadingDiv(true);
                      const specialTributes = await loadSpecialTributes(
                        val.value.idMunicipio,
                        isNFSeNacional
                      );
                      setSpecialTribute(specialTributes[0]);
                      setLoadingDiv(false);
                    }
                  }}
                  value={city}
                  dataItemKey="idMunicipio"
                  textField="nomeMunicipio"
                  required
                />
                <FormFieldDrop
                  titleLabel="Regime de Tributação"
                  defaultValueSelect="Selecione..."
                  infosSelect={specialTributes}
                  className="fill-33-field"
                  name="EspecialTribute"
                  onChangeValue={(val) => {
                    setSpecialTribute(val.value);
                  }}
                  value={specialTribute}
                  dataItemKey="Id"
                  textField="Nome"
                  required
                />
              </FormRow>
            )}
            <FormRow>
              <FormFieldGeneric
                titleLabel="Informações para impressão no documento fiscal"
                isTextArea
                rows={4}
                classNameWrapper="fill-100-field"
                onChangeValue={(val) => {
                  setForm({
                    ...form,
                    OutrasInformacoes: val.value
                  });
                }}
                valueInput={form.OutrasInformacoes}
              />
            </FormRow>
            <FormRow>
              <GenericField
                titleLabel="Valor dos tributos totais (Lei 12.471/12) Fonte: IBPT"
                iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
                classNameWrapper="fill-50-field"
                name="AliquotaIBPT"
                onChangeValue={(val) => {
                  const {
                    target: { name, valueAsNumber }
                  } = val;
                  setForm({
                    ...form,
                    AliquotaIBPT: valueAsNumber
                  });
                }}
                valueInput={form.AliquotaIBPT}
              />
            </FormRow>
            <FormRow>
              <FormFieldCheckbox
                label="Discriminar o valor do IRRF na nota mesmo se inferior ou igual a R$10,00 (dez reais)"
                classNameWrapper="fill-50-field"
                value={form.ValorMinIRRF}
                onChange={(val) => {
                  setForm({ ...form, ValorMinIRRF: val.value });
                }}
              />
            </FormRow>
          </ToggleInvoice>
        )}

        {isNFSe && (
          <ToggleInvoice title="NFSe" startExpanded>
            <FormRow>
              <Logo
                backgroundColor={walletSelected.CorPerfil}
                removeAvatar={excludeLogo}
                textAvatar="Logo para a NFSe (270x100px)"
                imageSrc={urlLogo}
                imageAlt="Logo para a NFSe"
                className="fill-50-field"
                setImageData={setLogo}
                messageRemoveAvatar="Você tem certeza que deseja excluir o logo para a NFSe"
              />
            </FormRow>
            <FormRow>
              <GenericField
                titleLabel="Número Inicial"
                enableInfo
                onChangeValue={(val) => {
                  const {
                    target: { name, valueAsNumber }
                  } = val;
                  setForm({
                    ...form,
                    Numero: valueAsNumber
                  });
                }}
                msg="Número inicial de emissão de nota fiscal"
                valueInput={form.Numero}
                classNameWrapper="fill-33-field"
                required
                name="Numero"
                typeNumber
              />
            </FormRow>
            {loadingDiv ? (
              <LoadScreen />
            ) : (
              <FormRow>
                <FormFieldDrop
                  titleLabel="Município da Nota Fiscal"
                  defaultValueSelect="Selecione..."
                  infosSelect={citiesInvoice}
                  className="fill-50-field"
                  name="cityInvoice"
                  onChangeValue={async (val) => {
                    setCityInvoice(val.value);
                    if (val.value) {
                      setLoadingDiv(true);
                      const specialTributes = await loadSpecialTributes(
                        val.value.idMunicipio,
                        isNFSeNacional
                      );
                      setSpecialTribute(specialTributes[0]);
                      setLoadingDiv(false);
                    }
                  }}
                  value={cityInvoice}
                  dataItemKey="idMunicipio"
                  textField="nomeMunicipio"
                  required
                />
                <FormFieldDrop
                  titleLabel="Regime de Tributação"
                  defaultValueSelect="Selecione..."
                  infosSelect={specialTributes}
                  className="fill-50-field"
                  name="EspecialTribute"
                  onChangeValue={(val) => {
                    setSpecialTribute(val.value);
                  }}
                  value={specialTribute}
                  dataItemKey="Id"
                  textField="Nome"
                  required
                />
              </FormRow>
            )}
            <FormRow>
              <FormFieldGeneric
                titleLabel="Informações para impressão no documento fiscal"
                isTextArea
                rows={4}
                classNameWrapper="fill-100-field"
                onChangeValue={(val) => {
                  setForm({
                    ...form,
                    OutrasInformacoes: val.value
                  });
                }}
                valueInput={form.OutrasInformacoes}
              />
            </FormRow>
            <FormRow>
              <GenericField
                titleLabel="Valor dos tributos totais (Lei 12.471/12) Fonte: IBPT"
                iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
                classNameWrapper="fill-50-field"
                name="AliquotaIBPT"
                onChangeValue={(val) => {
                  const {
                    target: { name, valueAsNumber }
                  } = val;
                  setForm({
                    ...form,
                    AliquotaIBPT: valueAsNumber
                  });
                }}
                valueInput={form.AliquotaIBPT}
              />
            </FormRow>
            <FormRow>
              <FormFieldCheckbox
                label="Discriminar o valor do IRRF na nota mesmo se inferior ou igual a R$10,00 (dez reais)"
                classNameWrapper="fill-50-field"
                value={form.ValorMinIRRF}
                onChange={(val) => {
                  setForm({ ...form, ValorMinIRRF: val.value });
                }}
              />
            </FormRow>
          </ToggleInvoice>
        )}

        {isRecibo && (
          <ToggleInvoice title="Recibo" startExpanded>
            <FormRow>
              <Logo
                backgroundColor={walletSelected.CorPerfil}
                removeAvatar={excludeLogo}
                textAvatar="Logo para o recibo (270x100px)"
                imageSrc={urlLogo}
                imageAlt="Logo para o recibo"
                className="fill-50-field"
                setImageData={setLogo}
                messageRemoveAvatar="Você tem certeza que deseja excluir o logo para o recibo"
              />
            </FormRow>
            <FormRow>
              <GenericField
                titleLabel="Número Inicial"
                enableInfo
                onChangeValue={(val) => {
                  const {
                    target: { name, valueAsNumber }
                  } = val;
                  setForm({
                    ...form,
                    Numero: valueAsNumber
                  });
                }}
                msg="Número inicial de emissão do recibo"
                valueInput={form.Numero}
                classNameWrapper="fill-33-field"
                required
                name="Numero"
                typeNumber
              />
            </FormRow>
            <FormRow>
              <FormFieldGeneric
                titleLabel="Informações para impressão no recibo"
                isTextArea
                rows={4}
                classNameWrapper="fill-100-field"
                onChangeValue={(val) => {
                  setForm({
                    ...form,
                    OutrasInformacoes: val.value
                  });
                }}
                valueInput={form.OutrasInformacoes}
              />
            </FormRow>
            <FormRow>
              <FormFieldDrop
                titleLabel="País"
                defaultValueSelect="Selecione..."
                onChangeValue={async (val) => {
                  setCountry(val.value);
                  const coins = await loadCoinByCountry(val.value?.Id);
                  setCoin(coins[0]);
                }}
                value={country}
                className="fill-50-field"
                infosSelect={countries}
                dataItemKey="Id"
                textField="Descricao"
                required
                disabled
              />
              <FormFieldDrop
                titleLabel="Moeda"
                defaultValueSelect="Selecione..."
                onChangeValue={(val) => {
                  setCoin(val.value);
                }}
                value={coin}
                className="fill-50-field"
                infosSelect={coins}
                dataItemKey="Id"
                textField="Nome"
                required
                disabled
              />
            </FormRow>
          </ToggleInvoice>
        )}

        {isNotaDebito && (
          <ToggleInvoice title="Nota de Débito" startExpanded>
            <FormRow>
              <Logo
                backgroundColor={walletSelected.CorPerfil}
                removeAvatar={excludeLogo}
                textAvatar="Logo para a nota de débito (270x100px)"
                imageSrc={urlLogo}
                imageAlt="Logo para a nota de débito"
                className="fill-50-field"
                setImageData={setLogo}
                messageRemoveAvatar="Você tem certeza que deseja excluir o logo para a nota de débito"
              />
            </FormRow>
            <FormRow>
              <GenericField
                titleLabel="Número Inicial"
                enableInfo
                onChangeValue={(val) => {
                  const {
                    target: { name, valueAsNumber }
                  } = val;
                  setForm({
                    ...form,
                    Numero: valueAsNumber
                  });
                }}
                msg="Número inicial de emissão da nota de débito"
                valueInput={form.Numero}
                classNameWrapper="fill-50-field"
                required
                name="Numero"
                typeNumber
              />
              <GenericField
                titleLabel="Sufixo do Número"
                enableInfo
                valueInput={form.NumeroSufixo}
                onChangeValue={(val) => {
                  setForm({ ...form, NumeroSufixo: val.value });
                }}
                msg="Sufixo para Número inicial. Texto que será adicionado após o número sequencial do documento."
                classNameWrapper="fill-50-field"
              />
            </FormRow>
            <FormRow>
              <FormFieldDrop
                titleLabel="Conta Padrão para Pré-fatura"
                defaultValueSelect="Selecione..."
                onChangeValue={(val) => {
                  setAccount(val.value);
                }}
                value={account}
                className="fill-50-field"
                infosSelect={accounts}
                dataItemKey="Id"
                textField="Nome"
              />
            </FormRow>
            <FormRow>
              <FormFieldGeneric
                titleLabel="Informações para impressão na nota de débito"
                isTextArea
                rows={4}
                classNameWrapper="fill-100-field"
                onChangeValue={(val) => {
                  setForm({
                    ...form,
                    OutrasInformacoes: val.value
                  });
                }}
                valueInput={form.OutrasInformacoes}
              />
            </FormRow>
            <FormRow>
              <FormFieldDrop
                titleLabel="País"
                defaultValueSelect="Selecione..."
                onChangeValue={async (val) => {
                  setCountry(val.value);
                  const coins = await loadCoinByCountry(val.value?.Id);
                  setCoin(coins[0]);
                }}
                value={country}
                className="fill-50-field"
                infosSelect={countries}
                dataItemKey="Id"
                textField="Descricao"
                required
                disabled
              />
              <FormFieldDrop
                titleLabel="Moeda"
                defaultValueSelect="Selecione..."
                onChangeValue={(val) => {
                  setCoin(val.value);
                }}
                value={coin}
                className="fill-50-field"
                infosSelect={coins}
                dataItemKey="Id"
                textField="Nome"
                required
                disabled
              />
            </FormRow>
          </ToggleInvoice>
        )}

        <FormRow spaceBetween alignCenter marginTop40>
          {id && (
            <SimpleGrayButton
              onClick={() => {
                setPopUpConfirmVisibility(true);
              }}
            >
              Excluir
            </SimpleGrayButton>
          )}
          <FormRow justifyEnd space15 withoutMarginTop>
            <OrangeButton type="submit">Salvar</OrangeButton>
            <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
          </FormRow>
        </FormRow>
      </form>
      <PopUpAliquot visible={popUpAliquotVisible} setVisible={setPopUpAliquotVisible} />
      <PopUpConfirm
        onConfirm={excludeDocument}
        visible={popUpConfirmVisibility}
        setVisible={setPopUpConfirmVisibility}
        title="Confirmação"
        message="Você tem certeza que deseja excluir o documento?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title="Erro ao excluir um documento"
        message={popUpErrorMessage}
      />
    </>
  );
};

export default DocumentRegistration;
