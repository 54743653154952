import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import FormFieldDrop from '../../formField/drop';
import FormFieldDate from '../../formField/date';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';
import { DateNow } from '../../../utils/date';
import * as serviceService from '../../../services/service';
import LoadScreen from '../../../view/load';

const PopUpEditService = ({
  addService,
  visible,
  setVisible,
  isAdditional = false,
  showAdditionalDescription = false,
  selectedService = null
}) => {
  const [service, setService] = useState(null);
  const [selectValue, setSelectValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [additionalDescription, setAdditionalDescription] = useState(null);
  const [referenceDate, setReferenceDate] = useState(DateNow());
  const [unit, setUnit] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeServiceHandler = ({ target: { value } }) => {
    setService(value);
    setSelectValue(value.Valor);
    setAmount(1);
    setAdditionalDescription(value.DescricaoAdicional);
    setUnit(value.UnidadeMedida_Nome);
  };

  const confirmAddService = () => {
    if (service == null) {
      throw Error('O campo nome do serviço é obrigatório.');
    } else if (amount <= 0) {
      throw Error('O campo quantidade deve ser maior 0.');
    } else {
      let item = {
        ...service,
        Quantidade: amount,
        Desconto: 0,
        ValorServico: selectValue,
        ValorTotal: amount * selectValue
      };
      if (isAdditional) {
        item = {
          ...item,
          DataReferencia: referenceDate
        };
      }
      if (showAdditionalDescription) {
        item = {
          ...item,
          DescricaoAdicional: additionalDescription
        };
      }
      addService(item);
      setService(null);
      setSelectValue(0);
      setAmount(0);
      setAdditionalDescription(null);
    }
  };

  const loadServices = async () => {
    const { data } = await serviceService.getAll();
    setServices(data);
    return data;
  };

  useEffect(async () => {
    if (visible) {
      setLoading(true);
      const services = await loadServices();
      if (selectedService) {
        const service = services.find((item) => item.Id === selectedService.ServicoId);
        setService(service);
        setSelectValue(selectedService.Valor);
        setAmount(selectedService.Quantidade);
        setAdditionalDescription(selectedService.Descricao);
      } else {
        setService(null);
        setSelectValue(0);
        setAmount(0);
        setAdditionalDescription(null);
      }
      setLoading(false);
    }
  }, [selectedService, visible]);

  return (
    <Popup
      title={selectedService ? 'Atualizar Serviço' : 'Adicionar Serviço'}
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText={selectedService ? 'Atualizar' : 'Adicionar'}
      cancelText="Cancelar"
      onConfirm={confirmAddService}
    >
      {loading ? (
        <LoadScreen />
      ) : (
        <>
          {isAdditional && (
            <FormRow>
              <FormFieldDate
                titleLabel="Data de Referência"
                value={referenceDate}
                valueOnChange={setReferenceDate}
                className="fill-100-field"
                calendarIsMonth
                required
              />
            </FormRow>
          )}
          <FormRow>
            <FormFieldDrop
              titleLabel="Código do serviço"
              defaultValueSelect="Selecione..."
              infosSelect={services}
              className="fill-100-field"
              value={service}
              onChangeValue={changeServiceHandler}
              dataItemKey="Id"
              textField="Codigo"
              required
            />
          </FormRow>
          <FormRow>
            <FormFieldDrop
              titleLabel="Nome do serviço"
              defaultValueSelect="Selecione..."
              infosSelect={services}
              className="fill-100-field"
              value={service}
              onChangeValue={changeServiceHandler}
              dataItemKey="Id"
              textField="Descricao"
              required
            />
          </FormRow>
          {showAdditionalDescription && (
            <FormRow>
              <GenericField
                titleLabel="Descrição Adicional do Serviço"
                classNameWrapper="fill-100-field"
                isTextArea
                valueInput={additionalDescription}
                onChangeValue={(val) => {
                  setAdditionalDescription(val.value);
                }}
                rows={8}
              />
            </FormRow>
          )}
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Quantidade"
              classNameWrapper="fill-100-field"
              valueInput={amount}
              onChangeValue={(e) => {
                const {
                  target: { name, valueAsNumber }
                } = e;
                setAmount(valueAsNumber);
              }}
              name="amount"
              required
              typeNumber
              min="0"
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Unidade de Medida"
              classNameWrapper="fill-100-field"
              classNameInput="align-right"
              valueInput={unit}
              disabled
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Valor"
              enableReais
              classNameWrapper="fill-100-field"
              valueInput={selectValue}
              setValue={setSelectValue}
              iconEnabled={INTERNAL_ICON.REAIS_ICON}
              required
              min="0"
            />
          </FormRow>
        </>
      )}
    </Popup>
  );
};

PopUpEditService.propTypes = {
  addService: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpEditService.defaultProps = {};

export default PopUpEditService;
